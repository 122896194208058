import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'

import { FlexV2, Loader, Grid, Item, Separator, Text } from '@/primitives'

import ZoneCard from './ZoneCard'
import ZoneFilter from './ZoneFilter'

import useOrgLabel from '@/hooks/useOrgLabel'
import { cleanZone, fetchZonesList } from '@/slices/management/zone'

import { getCurrentUser, getZonesListLoading } from '@/reducers/selectors'

export default function ZoneList() {
  const dispatch = useDispatch()

  const isLoading = getZonesListLoading()
  const currentUser = getCurrentUser()

  const [zonesList, setZonesList] = useState([])
  const [searchResults, setSearchResults] = useState(zonesList)
  const [searchText, setSearchText] = useState('')
  const { site } = useOrgLabel(['site'])

  useEffect(() => {
    if (currentUser?.userName) {
      dispatch(fetchZonesList())
      dispatch(cleanZone())
    }
  }, [dispatch, currentUser?.userName])
  const onUpdateSearchText = text => {
    setSearchText(text)
  }

  useEffect(() => {
    setSearchResults(
      zonesList.filter(
        zone =>
          zone?.status === 'active' &&
          zone?.name?.toLowerCase().includes(searchText.toLowerCase())
      )
    )
  }, [searchText, zonesList])

  return (
    <Loader
      text={`${I18n.get('Loading')} ${site.textPlural}`}
      isLoading={isLoading}
    >
      <FlexV2 direction='column' axisGap={400}>
        <FlexV2 direction='column' axisGap={200}>
          <Text variant={'page'} tone={800} size={300} fontWeight={700}>
            {site.textPlural}
          </Text>
          <Text variant={'page'} tone={700} size={200}>
            {I18n.get(
              'Select a {siteType} below to view its dashboard'
            ).replace('{siteType}', site.text)}
          </Text>
        </FlexV2>
        <Separator />
        <ZoneFilter
          setZonesList={setZonesList}
          isLoading={isLoading}
          onUpdateSearchText={onUpdateSearchText}
          placeholderText={`${I18n.get('Filter')} ${site.textPlural}`}
        />
        <Separator />
        {!isLoading && searchResults?.length > 0 && (
          <Grid gap={400} className={'ZoneCards'}>
            {searchResults.map(zone => (
              <Item key={`zonelist__${zone.id}`}>
                <Link
                  to={`/zones${zone.parentPath}`}
                  className={'ZoneCards__CardLink'}
                >
                  <ZoneCard zone={zone} />
                </Link>
              </Item>
            ))}
          </Grid>
        )}
      </FlexV2>
    </Loader>
  )
}
