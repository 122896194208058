import React from 'react'

import './index.scss'

import { useCropData } from './hooks/useCropData'
import Loader from '@/primitives/Loader'
import Text from '@/primitives/Text'
import FlexV2 from '@/primitives/FlexV2'
import Box from '@/primitives/Box'
import SectionCard from './SectionCard'
import CropDetails from './CropDetails'
import InfoRow from './InfoRow'
import Strings from '../../Strings'

const TrialSections = ({ roomA, roomB, isSmallScreen }) => {
  const strings = Strings()
  const { sectionA, sectionB, crop, isLoading, error } = useCropData(
    roomA,
    roomB
  )

  if (isLoading) return <Loader isLoading />
  if (error)
    return (
      <Text variant='error' tone={900}>
        {error}
      </Text>
    )

  const shouldShowLogo = section =>
    section?.roofCover.includes('secondsky') && !isSmallScreen
  const renderSectionInfo = section =>
    [
      { label: strings.roofCover, value: section?.roofCover },
      { label: strings.secondskyVariant, value: section?.secondskyVariant },
      { label: strings.blockingRatio, value: section?.blockingRatio },
      { label: strings.netType, value: section?.netType },
      {
        label: strings.secondskyInformation,
        value: section?.secondskyInformation
      }
    ]
      .filter(({ value }) => value != null && value !== '')
      .map(({ label, value }) => (
        <InfoRow key={label} label={label} value={value} />
      ))

  return (
    <Box className='trial-sections-container'>
      <FlexV2
        alignMainAxis='space-between'
        axisGap={400}
        direction={isSmallScreen ? 'column' : 'row'}
      >
        <SectionCard
          section={sectionA?.name}
          color='blue'
          showLogo={shouldShowLogo(sectionA)}
          showAsCollapsed={isSmallScreen}
        >
          <FlexV2 direction='column' axisGap={200}>
            {renderSectionInfo(sectionA)}
          </FlexV2>
        </SectionCard>
        <SectionCard
          section={sectionB?.name}
          color='red'
          showLogo={shouldShowLogo(sectionB)}
          showAsCollapsed={isSmallScreen}
        >
          <FlexV2 direction='column' axisGap={200}>
            {renderSectionInfo(sectionB)}
          </FlexV2>
        </SectionCard>
        <CropDetails crop={crop} showAsCollapsed={isSmallScreen} />
      </FlexV2>
    </Box>
  )
}

export default TrialSections
