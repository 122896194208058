import { I18n } from 'aws-amplify'
import { TotalProduceWidget } from './Harvest/TotalProduce'
import {
  DATASET_QUERY,
  LAST_3_MONTHS,
  PIE_CHART
} from '../Shared/Dynamic/config'
import { TopTenHarvestVarietyWidget } from './Harvest/TopTenHarvestVariety'

import { CropsViewWidget } from './Crops'

import { harvestChartQueryOptionsList } from './utils'

export function operationTypes() {
  return {
    totalProduce: {
      widgetId: 'totalProduce',
      description: I18n.get('Total Harvested Produce'),
      title: I18n.get('Total Produce'),
      thresholds: [0, 1000000],
      icon: 'yard',
      component: TotalProduceWidget,
      config: {
        query: DATASET_QUERY,
        chart: PIE_CHART,
        range: LAST_3_MONTHS,
        interval: null
      },
      queryOptionsList: harvestChartQueryOptionsList
    },
    topTenHarvestVariety: {
      widgetId: 'topTenHarvestVariety',
      description: I18n.get('Top 10 Harvested Varieties'),
      title: I18n.get('Top 10 Varieties'),
      thresholds: [0, 1000000],
      icon: 'leaderboard',
      component: TopTenHarvestVarietyWidget,
      queryOptionsList: harvestChartQueryOptionsList,
      config: {
        query: DATASET_QUERY,
        chart: PIE_CHART,
        range: LAST_3_MONTHS,
        interval: null
      }
    },
    planVsHarvest: {
      widgetId: 'planVsHarvest',
      description: I18n.get('Planned vs harvested varieties'),
      title: I18n.get('Planned vs Harvested'),
      thresholds: [0, 1000000],
      icon: 'grass',
      queryOptionsList: harvestChartQueryOptionsList
    },
    crop: {
      widgetId: 'crops',
      description: I18n.get('Current Crops'),
      title: I18n.get('Crops'),
      icon: 'potted_plant',
      component: CropsViewWidget,
      config: {
        overflow: 'scroll'
      }
    }
  }
}
