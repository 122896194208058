import FlexV2 from '@/primitives/FlexV2'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import Button from '@/primitives/Button'
import Strings from '../../Strings'
import SensorFilterDialog from './SensorFilterDialog'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEnvSensors, setSoilSensors } from '@/slices/management/trial'
import { getTrialState } from '../../utils'
import DeviceFilterButton from './DeviceFilterButton'

const MetricsHeader = ({
  type,
  viewMode,
  showDelta,
  onDeltaToggle,
  onViewModeChange,
  sectionAId,
  sectionBId,
  isSmallScreen
}) => {
  const strings = Strings()
  const dispatch = useDispatch()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { envSensorsA, envSensorsB, soilSensorsA, soilSensorsB } =
    useSelector(getTrialState)

  const hasActiveFilters =
    type === 'envirosense'
      ? envSensorsA.length > 0 || envSensorsB.length > 0
      : soilSensorsA.length > 0 || soilSensorsB.length > 0

  const handleClearFilters = () => {
    if (type === 'envirosense') {
      dispatch(
        setEnvSensors({
          sectionA: [],
          sectionB: []
        })
      )
    } else {
      dispatch(
        setSoilSensors({
          sectionA: [],
          sectionB: []
        })
      )
    }
  }

  const title =
    strings[`${type === 'envirosense' ? 'environmental' : 'soil'}MetricsTitle`]

  const titleSize = isSmallScreen ? 300 : 400

  return (
    <>
      <FlexV2 alignCrossAxis='center' axisGap={200}>
        <Icon
          name={type === 'envirosense' ? 'thermostat' : 'water_drop'}
          size={titleSize}
          variant='primary'
          tone={600}
        />
        <Text size={titleSize} fontWeight={600}>
          {title}
        </Text>
        {isSmallScreen && (
          <Text size={100} as='span' variant='neutral'>
            {strings.liveData}
          </Text>
        )}
      </FlexV2>
      {!isSmallScreen && (
        <FlexV2 alignCrossAxis='center' alignMainAxis='flex-end' axisGap={400}>
          <DeviceFilterButton
            hasActiveFilters={hasActiveFilters}
            onClick={() => setIsDialogOpen(true)}
            onClearFilters={handleClearFilters}
            sensorType={type}
          />
          {viewMode === 'historical' && (
            <>
              <Button
                variant='outline'
                iconBefore='compare_arrows'
                onClick={onDeltaToggle}
              >
                {showDelta ? strings.showSections : strings.showDelta}
              </Button>
            </>
          )}

          <FlexV2>
            <Button
              variant={viewMode === 'live' ? 'primary' : 'outline'}
              onClick={() => onViewModeChange('live')}
            >
              {strings.liveData}
            </Button>
            <Button
              variant={viewMode === 'historical' ? 'primary' : 'outline'}
              onClick={() => onViewModeChange('historical')}
            >
              {strings.historical}
            </Button>
          </FlexV2>
          <SensorFilterDialog
            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            sectionAId={sectionAId}
            sectionBId={sectionBId}
            dialogMode={type}
          />
        </FlexV2>
      )}
    </>
  )
}

export default MetricsHeader
