import React from 'react'
import { Link } from 'react-router-dom'

import { Flex, Logo, Text } from '@/primitives'

import ProgressIndicator from './Utils/ProgressIndicator'

import useMediaQuery from '@/hooks/useMediaQuery'

import './index.scss'
import Strings from './Strings'

const AuthContainer = ({ children, progressStep = null }) => {
  const isLargeLaptop = useMediaQuery('min-width: 1366px')
  const strings = Strings()
  return (
    <Flex
      direction='column'
      alignCrossAxis='center'
      alignMainAxis='center'
      axisGap={400}
      className='AuthPage'
      wrap='nowrap'
    >
      <Flex alignCrossAxis='center' axisGap={300}>
        <Logo size={isLargeLaptop ? 900 : 700} useDefault={true} />
      </Flex>
      <Flex
        direction='column'
        alignCrossAxis='center'
        alignMainAxis='center'
        style={{ width: '100%', maxWidth: '450px' }}
      >
        {progressStep && <ProgressIndicator currentStep={progressStep} />}
        {children}
        <Text
          as='p'
          size={100}
          style={{ marginTop: '2em', textAlign: 'center' }}
        >
          {strings.agreePolicy}
          <Link to='privacy-policy' target='_blank'>
            {strings.privacyPolicy}
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

export default AuthContainer
