import React, { useState, useRef, useEffect, memo, useCallback } from 'react'
import Card from '@/primitives/Card'
import Slot from '@/primitives/Slot'
import FlexV2 from '@/primitives/FlexV2'
import Box from '@/primitives/Box'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import Button from '@/primitives/Button'
import Collapse from '@/primitives/Collapse'
import './VirtualAssistant.scss'

// Constants
const PREDEFINED_QUESTIONS = [
  { id: 1, text: 'What are the key differences between greenhouse A and B?' },
  { id: 2, text: 'When was the first harvest date?' },
  { id: 3, text: 'What is the current growth status?' },
  { id: 4, text: 'Compare Iyris-treated plants to control group.' }
]

const ANSWERS = {
  1: 'Greenhouse A uses SecondSky polycarbonate with low blocking, while Greenhouse B uses standard plastic with polycarbonate. The main difference is in light transmission characteristics.',
  2: 'The initial harvest began on February 12, 2025. The plants in Greenhouse A started producing slightly earlier than those in Greenhouse B.',
  3: 'Plants are currently in production phase. All plants have developed 3-4 fruit bunches with the first bunch fully ripened and harvested.',
  4: 'Iyris-treated plants demonstrated earlier flowering (by approximately 4 days), thicker stems (increased diameter of 12%), and shorter internodes compared to the control group, as observed on February 10, 2025.'
}

const AUDIO_PATH = '/trial-mode/audio/trial-notebook-exec-summary-audio.wav'

// Reusable components
const ExperimentalBadge = memo(() => (
  <Box className='experimental-badge'>
    <Icon name='science' size={200} variant='warning' />
    <Text size={200}>Experimental Feature</Text>
  </Box>
))

const AnimatedMessage = memo(({ message, status }) => (
  <Box className={`animated-message ${status}`}>
    <FlexV2 alignCrossAxis='center' axisGap={200}>
      <Icon name='smart_toy' size={200} variant='primary' />
      <Text>{message}</Text>
    </FlexV2>
  </Box>
))

const SectionHeader = memo(({ icon, title }) => (
  <FlexV2 alignCrossAxis='center' axisGap={200}>
    <Icon name={icon} size={200} variant='primary' />
    <Text size={200} fontWeight={500}>
      {title}
    </Text>
  </FlexV2>
))

// Executive Summary component
const ExecutiveSummary = memo(() => {
  const [generating, setGenerating] = useState(false)
  const [summary, setSummary] = useState('')

  const handleGenerateSummary = useCallback(() => {
    setGenerating(true)
    setSummary('')
    setTimeout(() => {
      setSummary('Executive Summary: Red Cherry Tomato (Aida) Trial')
      setGenerating(false)
    }, 2000)
  }, [])

  const FormattedSummary = () => (
    <Box className='summary-content'>
      <Text size={300} fontWeight={600} className='summary-title'>
        🌱 Executive Summary: Red Cherry Tomato (Aida) Trial 🌱
      </Text>
      <Box className='summary-paragraph'>
        <Text>
          The iyris SecondSky roof vs. standard plastic trial for tomatoes is
          showing promising results
        </Text>
      </Box>
      <Box className='summary-section'>
        <Box className='milestone-list'>
          <Box className='milestone-item'>
            <Text>
              📅 Early growth saw thriving seedlings with low mortality by{' '}
              <Text as='span' fontWeight={600}>
                January 18, 2025
              </Text>
              .
            </Text>
          </Box>
          <Box className='milestone-item'>
            <Text>
              🍅 By{' '}
              <Text as='span' fontWeight={600}>
                February 10, 2025
              </Text>
              , iyris plants had shorter internodes, earlier flowering, and
              thicker stems.
            </Text>
          </Box>
          <Box className='milestone-item'>
            <Text>
              📏 Plant growth was{' '}
              <Text as='span' fontWeight={600}>
                5-10%
              </Text>{' '}
              more condensed under iyris by{' '}
              <Text as='span' fontWeight={600}>
                February 16, 2025
              </Text>
              .
            </Text>
          </Box>
          <Box className='milestone-item'>
            <Text>
              🥇 First tomato harvest from the iyris side on{' '}
              <Text as='span' fontWeight={600}>
                February 24, 2025
              </Text>
              !
            </Text>
          </Box>
          <Box className='milestone-item'>
            <Text>
              ☀️ Interestingly, iyris needed no extra shading to date.
            </Text>
          </Box>
          <Box className='milestone-item'>
            <Text>
              💧 Nutrient analysis prompted altered fertigation under iyris by{' '}
              <Text as='span' fontWeight={600}>
                February 25, 2025
              </Text>
              .
            </Text>
          </Box>
          <Box className='milestone-item'>
            <Text>
              📊 The first full week of yielding was completed in both by{' '}
              <Text as='span' fontWeight={600}>
                February 28, 2025
              </Text>
              .
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className='summary-conclusion'>
        <Text>
          Early signs suggest the iyris SecondSky roof is impacting tomato
          development and environmental management favorably
        </Text>
      </Box>
    </Box>
  )

  return (
    <Box className='executive-summary'>
      <FlexV2 direction='column' axisGap={200}>
        <SectionHeader icon='summarize' title='Executive Summary' />

        {!summary && !generating && (
          <Button
            variant='primary'
            iconBefore='auto_awesome'
            onClick={handleGenerateSummary}
          >
            Generate Executive Summary
          </Button>
        )}

        {generating && (
          <AnimatedMessage
            message='Analyzing trial data and generating summary...'
            status='processing'
          />
        )}

        {summary && (
          <>
            <FormattedSummary />
            <Button
              variant='outline'
              iconBefore='refresh'
              onClick={handleGenerateSummary}
              className='regenerate-button'
              tone={600}
            >
              Regenerate Summary
            </Button>
          </>
        )}
      </FlexV2>
    </Box>
  )
})

// Audio Overview component
const AudioOverview = memo(() => {
  const [generating, setGenerating] = useState(false)
  const [audioReady, setAudioReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [error, setError] = useState(null)

  const audioRef = useRef(null)
  const progressRef = useRef(null)

  const formatTime = useCallback(timeInSeconds => {
    if (isNaN(timeInSeconds)) return '0:00'
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }, [])

  const updateProgressBar = useCallback(() => {
    if (audioRef.current && progressRef.current && audioRef.current.duration) {
      const percent =
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      progressRef.current.style.width = `${percent}%`
    }
  }, [])

  const handleLoadedData = useCallback(() => {
    if (
      audioRef.current &&
      audioRef.current.duration &&
      !isNaN(audioRef.current.duration)
    ) {
      setDuration(audioRef.current.duration)
    }
  }, [])

  const handleDurationChange = useCallback(() => {
    if (
      audioRef.current &&
      audioRef.current.duration &&
      !isNaN(audioRef.current.duration)
    ) {
      setDuration(audioRef.current.duration)
    }
  }, [])

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime)
      updateProgressBar()
    }
  }, [updateProgressBar])

  const handleEnded = useCallback(() => {
    setIsPlaying(false)
    updateProgressBar()
  }, [updateProgressBar])

  const handleAudioError = useCallback(e => {
    setError('Failed to load audio. Please try again.')
    setGenerating(false)
    setAudioReady(false)
  }, [])

  const setupAudioListeners = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('loadeddata', handleLoadedData)
      audioRef.current.addEventListener('durationchange', handleDurationChange)
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate)
      audioRef.current.addEventListener('ended', handleEnded)
      audioRef.current.addEventListener('error', handleAudioError)
    }
  }, [
    handleLoadedData,
    handleDurationChange,
    handleTimeUpdate,
    handleEnded,
    handleAudioError
  ])

  const cleanupAudioListeners = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.removeEventListener('loadeddata', handleLoadedData)
      audioRef.current.removeEventListener(
        'durationchange',
        handleDurationChange
      )
      audioRef.current.removeEventListener('timeupdate', handleTimeUpdate)
      audioRef.current.removeEventListener('ended', handleEnded)
      audioRef.current.removeEventListener('error', handleAudioError)
    }
  }, [
    handleLoadedData,
    handleDurationChange,
    handleTimeUpdate,
    handleEnded,
    handleAudioError
  ])

  // Set up and clean up audio handling
  useEffect(() => {
    // Initialize audio element if it's ready
    if (audioReady && audioRef.current) {
      setupAudioListeners()

      // Return cleanup function
      return () => {
        cleanupAudioListeners()
        if (audioRef.current) {
          audioRef.current.pause()
          audioRef.current.src = '' // Clear source to cancel any pending operations
        }
      }
    }

    // Clean up only without initialization
    return () => {
      cleanupAudioListeners()
    }
  }, [audioReady, setupAudioListeners, cleanupAudioListeners])

  const handleGenerateAudio = useCallback(() => {
    // Reset current state
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.src = '' // Clear source to cancel any pending operations
      cleanupAudioListeners() // Remove existing listeners to prevent duplicates
      setIsPlaying(false)
    }

    setGenerating(true)
    setError(null)
    setAudioReady(false)
    setCurrentTime(0)
    setDuration(0)

    // Store timer references
    let generationTimer
    let durationCheckTimer

    // Simulate generation delay
    generationTimer = setTimeout(() => {
      setGenerating(false)
      setAudioReady(true)

      // Initialize audio only after generation is complete
      if (audioRef.current) {
        // Set source and prepare listeners
        audioRef.current.src = AUDIO_PATH
        setupAudioListeners()

        // Explicitly load and set up duration
        audioRef.current.load()

        // Set a timeout to handle cases where loadeddata or durationchange events don't fire
        durationCheckTimer = setTimeout(() => {
          if (audioRef.current && (!duration || isNaN(duration))) {
            const tempAudio = new Audio(AUDIO_PATH)
            const onMetadata = () => {
              if (tempAudio.duration && !isNaN(tempAudio.duration)) {
                setDuration(tempAudio.duration)
                tempAudio.removeEventListener('loadedmetadata', onMetadata)
                tempAudio.removeEventListener('error', onError)
              }
            }
            const onError = () => {
              handleAudioError(new Error('Failed to determine audio duration'))
              tempAudio.removeEventListener('loadedmetadata', onMetadata)
              tempAudio.removeEventListener('error', onError)
            }

            tempAudio.addEventListener('loadedmetadata', onMetadata)
            tempAudio.addEventListener('error', onError)
            tempAudio.load()
          }
        }, 1000)
      }
    }, 3500)

    // Return a proper cleanup function from the main component
    return () => {
      clearTimeout(generationTimer)
      clearTimeout(durationCheckTimer)
    }
  }, [handleAudioError, setupAudioListeners, cleanupAudioListeners, duration])

  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      try {
        if (isPlaying) {
          audioRef.current.pause()
        } else {
          const playPromise = audioRef.current.play()
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              setError('Playback failed. Please try again.')
            })
          }
        }
        setIsPlaying(!isPlaying)
      } catch (err) {
        setError('Playback control failed. Please try again.')
      }
    }
  }, [isPlaying])

  const handleProgressClick = useCallback(e => {
    if (
      audioRef.current &&
      progressRef.current &&
      progressRef.current.parentElement
    ) {
      try {
        const progressBar = progressRef.current.parentElement
        const clickPosition =
          (e.clientX - progressBar.getBoundingClientRect().left) /
          progressBar.offsetWidth
        audioRef.current.currentTime = clickPosition * audioRef.current.duration
      } catch (err) {
        setError('Failed to update playback position. Please try again.')
      }
    }
  }, [])

  const handleRetry = useCallback(() => {
    // Reset all states
    setError(null)
    setAudioReady(false)
    setIsPlaying(false)
    setCurrentTime(0)
    setDuration(0)

    // Clean up audio element
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.src = ''
      cleanupAudioListeners()
    }
  }, [cleanupAudioListeners])

  return (
    <Box className='audio-overview'>
      <FlexV2 direction='column' axisGap={200}>
        <SectionHeader icon='volume_up' title='Audio Overview' />

        {!audioReady && !generating && !error && (
          <Button
            variant='primary'
            iconBefore='mic'
            onClick={handleGenerateAudio}
          >
            Generate Audio Overview
          </Button>
        )}

        {generating && (
          <AnimatedMessage
            message='Processing voice synthesis from trial data...'
            status='processing'
          />
        )}

        {error && (
          <Box className='error-message'>
            <FlexV2 direction='column' axisGap={100}>
              <FlexV2 alignCrossAxis='center' axisGap={100}>
                <Icon name='error' size={200} variant='danger' />
                <Text color='danger'>{error}</Text>
              </FlexV2>
              <Button
                variant='outline'
                onClick={handleRetry}
                iconBefore='refresh'
              >
                Try Again
              </Button>
            </FlexV2>
          </Box>
        )}

        {audioReady && !error && (
          <>
            <Box className='audio-player'>
              <audio
                ref={audioRef}
                src={AUDIO_PATH}
                preload='metadata'
                onLoadedMetadata={e => {
                  if (e.target.duration && !isNaN(e.target.duration)) {
                    setDuration(e.target.duration)
                    setTimeout(updateProgressBar, 100)
                  }
                }}
              />

              <FlexV2 alignCrossAxis='center' alignMainAxis='space-between'>
                <Box className='play-button' onClick={togglePlayPause}>
                  <Icon
                    name={isPlaying ? 'pause_circle' : 'play_circle'}
                    size={300}
                    variant='primary'
                  />
                </Box>

                <Box className='audio-progress' onClick={handleProgressClick}>
                  <Box
                    className='progress-bar'
                    ref={progressRef}
                    style={{
                      width: `${(currentTime / (duration || 1)) * 100}%`
                    }}
                  ></Box>
                </Box>

                <Box className='time-display'>
                  <Text>
                    {formatTime(currentTime)} /{' '}
                    {isNaN(duration) ? '--:--' : formatTime(duration)}
                  </Text>
                </Box>
              </FlexV2>
            </Box>

            <Button
              variant='outline'
              iconBefore='refresh'
              onClick={handleGenerateAudio}
              className='regenerate-button'
            >
              Regenerate Audio
            </Button>
          </>
        )}
      </FlexV2>
    </Box>
  )
})

// AI Chat component
const AIChat = memo(() => {
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [generating, setGenerating] = useState(false)
  const [answer, setAnswer] = useState('')

  const handleSelectQuestion = useCallback(id => {
    setSelectedQuestion(id)
    setGenerating(true)
    setAnswer('')

    setTimeout(() => {
      setGenerating(false)
      setAnswer(ANSWERS[id])
    }, 1500)
  }, [])

  return (
    <Box className='ai-chat'>
      <FlexV2 direction='column' axisGap={200}>
        <SectionHeader icon='forum' title='Ask the Assistant' />

        <Box className='questions-container'>
          <Text size={100} fontWeight={500}>
            Select a question:
          </Text>
          <FlexV2 direction='column' axisGap={100} className='question-list'>
            {PREDEFINED_QUESTIONS.map(question => (
              <Button
                key={question.id}
                variant={
                  selectedQuestion === question.id ? 'primary' : 'outline'
                }
                onClick={() => handleSelectQuestion(question.id)}
                className='question-list-item'
              >
                {question.text}
              </Button>
            ))}
          </FlexV2>
        </Box>

        {generating && (
          <AnimatedMessage
            message='Analyzing trial data to provide accurate information...'
            status='processing'
          />
        )}

        {answer && (
          <Box className='answer-container'>
            <Text size={100} fontWeight={500}>
              Answer:
            </Text>
            <Box className='answer-content'>
              <Text>{answer}</Text>
            </Box>
          </Box>
        )}
      </FlexV2>
    </Box>
  )
})

// Main component
const VirtualAssistant = memo(({ isSmallScreen }) => {
  const titleSize = isSmallScreen ? 300 : 400

  const header = (
    <>
      <FlexV2 alignCrossAxis='center' alignMainAxis='space-between'>
        <FlexV2 alignCrossAxis='center' axisGap={200}>
          <Icon
            name='smart_toy'
            size={titleSize}
            variant='primary'
            tone={600}
          />
          <Text size={titleSize} fontWeight={600}>
            Trial Assistant
          </Text>
        </FlexV2>
      </FlexV2>
      <ExperimentalBadge />
    </>
  )

  const assistantPanels = (
    <FlexV2 direction='column' axisGap={300}>
      <ExecutiveSummary />
      <AudioOverview />
      <AIChat />
    </FlexV2>
  )

  if (isSmallScreen) {
    return (
      <Card>
        <Slot name='body'>
          <Collapse defaultOpen={false}>
            <Slot name='trigger'>{header}</Slot>
            <Slot name='content'>{assistantPanels}</Slot>
          </Collapse>
        </Slot>
      </Card>
    )
  }

  return (
    <Card>
      <Slot name='header'>{header}</Slot>
      <Slot name='body'>{assistantPanels}</Slot>
    </Card>
  )
})

export default VirtualAssistant
