import { I18n } from 'aws-amplify'
import { object, string, array } from 'yup'
import { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  fetchProduceThresholdMessage,
  sendCreateProduceThresholdMessage,
  sendUpdateProduceThresholdMessage
} from '@/slices/operations/produceThresholdMessage'

import {
  getOperationsProduceThresholdMessage,
  getOperationsProduceThresholdMessageLoading,
  getOperationsProduceThresholdMessageError
} from '@/reducers/selectors'

import {
  Flex,
  Form,
  Input,
  Label,
  Text,
  Loader,
  Separator,
  Button,
  Select
} from '@/primitives'

import InputError from '@/elements/InputError'

import HeaderV2 from '@/components/Operations/Shared/HeaderV2'

import { showBanner } from '@/slices/util'
import { getValidationErrorMap } from '@/Util/GeneralUtils'

import history from '../../../../../history'

import { getAvailablePriorities } from '../utils'

import { UPDATE, SET_STATE, formReducer, formInitalState } from './state'

import Config from './Config'

import Strings from '../Strings'

const SCHEMA = object({
  name: string().required(I18n.get('A name is required.')),
  priority: string()
    .oneOf(['high', 'medium', 'low'], I18n.get('Priority is invalid.'))
    .required(I18n.get('A name is required.')),
  config: array()
    .min(1, I18n.get('At least one condition is required.'))
    .required(I18n.get('At least one condition is required.'))
    .test(
      'valid-config',
      I18n.get('All condition fields are required.'),
      config => {
        for (let c of config) {
          if (
            c.measurement === null ||
            c.expression === null ||
            c.bound === null
          ) {
            return false
          }
        }
        return true
      }
    ),
  message: string().required(I18n.get('A message is required.'))
})

function ProduceThresholdMessageForm({ state, modulePath }) {
  const dispatch = useDispatch()
  const { itemId } = useParams()

  const strings = Strings()
  const AVAILABLE_PRIORITIES = getAvailablePriorities()

  const produceThresholdMessage = getOperationsProduceThresholdMessage()
  const loading = getOperationsProduceThresholdMessageLoading()
  const error = getOperationsProduceThresholdMessageError()

  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitalState
  )

  const [errors, setErrors] = useState({})
  const [waiting, setWaiting] = useState(false)

  useEffect(() => {
    if (itemId && produceThresholdMessage.id !== itemId) {
      dispatch(
        fetchProduceThresholdMessage({ produceThresholdMessageId: itemId })
      )
    }
  }, [dispatch, produceThresholdMessage?.id, itemId])

  useEffect(() => {
    if (produceThresholdMessage?.id && produceThresholdMessage.id === itemId) {
      dispatchFormState({ type: SET_STATE, state: produceThresholdMessage })
    }
  }, [produceThresholdMessage, itemId])

  useEffect(() => {
    if (waiting && !loading && !error) {
      const message = itemId
        ? strings.produceThresholdMessageUpdated
        : strings.produceThresholdMessageCreated
      dispatch(
        showBanner({
          type: 'success',
          show: true,
          message: message
        })
      )
      history.replace(modulePath)
    }
  }, [waiting, loading, error, modulePath])

  const onSubmit = async e => {
    e?.preventDefault()
    try {
      const payload = JSON.parse(JSON.stringify(formState))
      await SCHEMA.validate(payload, { abortEarly: false })

      setWaiting(true)

      if (itemId) {
        payload.produceThresholdMessageId = itemId
        dispatch(sendUpdateProduceThresholdMessage(payload))
      } else {
        dispatch(sendCreateProduceThresholdMessage(payload))
      }
    } catch (err) {
      setErrors(getValidationErrorMap(err))
    }
  }

  const onChange = e => {
    const { name, value } = e.currentTarget
    dispatchFormState({ type: UPDATE, name, value })
  }

  const filterPriorities = () => {
    return AVAILABLE_PRIORITIES.map(priority => ({
      name: priority[1],
      key: priority[0]
    }))
  }

  const addCondition = () => {
    const newConfig = [...formState.config]
    newConfig.push({
      measurement: null,
      expression: null,
      bound: null
    })
    dispatchFormState({
      type: UPDATE,
      name: 'config',
      value: newConfig
    })
  }

  const removeCondition = index => {
    const newConfig = [...formState.config]
    newConfig.splice(index, 1)
    dispatchFormState({
      type: UPDATE,
      name: 'config',
      value: newConfig
    })
  }

  return (
    <Form className='Operations__Form ProduceThresholdMessages'>
      <HeaderV2
        title={I18n.get('Manage Produce Threshold Message')}
        buttonIcon={'save'}
        buttonText={I18n.get('Save Produce Threshold Message')}
        buttonCallback={onSubmit}
        backPath={modulePath}
      />
      <Loader isLoading={loading}>
        <Flex
          axisGap={300}
          direction='column'
          className='Operations__Form__Fields'
        >
          <Flex direction='row' style={{ flex: 1 }}>
            <Flex direction='column' style={{ flex: 1 }}>
              <Label>
                <Text variant='page' tone={700}>
                  {I18n.get('Name')}
                </Text>
              </Label>
              <Input value={formState.name} name='name' onChange={onChange} />
              <InputError error={errors?.name} />
            </Flex>
            <Flex
              direction='column'
              style={{ flex: 0, marginLeft: '10px', minWidth: '175px' }}
            >
              <Label>
                <Text variant='page' tone={700}>
                  {I18n.get('Priority')}
                </Text>
              </Label>
              <Flex axisGap={300} direction='column'>
                <Select
                  className='Operations__Form__Select'
                  name='priority'
                  value={formState.priority}
                  onChange={onChange}
                >
                  <option default value=''>
                    {strings.pleaseSelect}
                  </option>
                  {filterPriorities().map(({ key, name }) => (
                    <option key={key} value={key}>
                      {name}
                    </option>
                  ))}
                </Select>
              </Flex>
              <InputError error={errors?.priority} />
            </Flex>
          </Flex>
          <Flex direction='column' style={{ flex: 1 }}>
            <Label>
              <Text variant='page' tone={700}>
                {I18n.get('Message')}
              </Text>
            </Label>
            <Input
              as='textarea'
              value={formState.message}
              name='message'
              onChange={onChange}
            />
            <InputError error={errors?.message} />
          </Flex>
          <Separator className='Separator__Line' />
          <Flex direction='column' style={{ flex: 1 }}>
            <Text size={400} fontWeight={500} tone={600}>
              {strings.fieldsetConfiguration}
            </Text>
            <InputError error={errors?.config} />
            <Flex axisGap={300} direction='column' style={{ marginTop: '1em' }}>
              {formState?.config?.length > 0 && (
                <Config
                  produceThresholdMessageId={itemId}
                  formState={formState}
                  errors={errors}
                  dispatchFormState={dispatchFormState}
                  removeCondition={removeCondition}
                />
              )}
              {formState?.config?.length === 0 && (
                <Flex className={'Operations__Table__Empty'}>
                  <Text>{strings.fieldConditionsNoEntries}</Text>
                </Flex>
              )}
              <Flex alignMainAxis={'flex-end'}>
                <Button
                  onClick={addCondition}
                  size='small'
                  name='add'
                  variant='primary'
                >
                  {strings.buttonAddCondition}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Loader>
    </Form>
  )
}

export default ProduceThresholdMessageForm
