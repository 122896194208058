import Box from '@/primitives/Box'
import Button from '@/primitives/Button'
import FlexV2 from '@/primitives/FlexV2'
import RichEditor from '@/components/Shared/RichEditor'
import Strings from '@/components/TrialModeView/Strings'

const initialTemplate = `<p><strong>📅 DD/MM/YYYY</strong></p>
<p><strong>👨‍🌾 Observer:</strong>&nbsp</p>
<p><strong>📝 General Observation:</strong>&nbsp</p>
<p><strong>🔍 Key Development:</strong>&nbsp</p>
<p><strong>🌿 Plant Health:</strong>&nbsp</p>
<p><strong>✅ Actions Taken:</strong>&nbsp</p>
`

export const NoteEditor = ({
  value,
  onChange,
  isSubmitting,
  onSubmit,
  trialId
}) => {
  const strings = Strings()

  return (
    <Box>
      <RichEditor
        value={value}
        onChange={onChange}
        disabled={isSubmitting}
        placeholder={strings.notePlaceholder}
        initialData={initialTemplate}
        parentId={trialId}
      />
      <Box style={{ marginTop: 20 }}>
        <FlexV2 alignMainAxis='flex-end'>
          <Button
            variant='primary'
            disabled={!value.trim() || isSubmitting}
            onClick={onSubmit}
          >
            {isSubmitting ? strings.addingNote : strings.addNoteLabel}
          </Button>
        </FlexV2>
      </Box>
    </Box>
  )
}
