import { useState, useEffect } from 'react'
import { getCrops } from '@/api/operations/crop'
import Strings from '../../../Strings'

const formatCropData = cropData => ({
  produce: cropData.produce.name,
  variety: cropData.variety.name,
  status: cropData.status,
  hydrationMethod: cropData.hydrationMethod,
  seedQuantity: cropData.seedQuantity,
  plantingArea: cropData.plantingArea,
  estimatedSowDate: cropData.estimatedSowDate,
  sowDate: cropData.sowDate,
  estimatedTransplantDate: cropData.estimatedTransplantDate,
  transplantDate: cropData.transplantDate,
  estimatedHarvestStartDate: cropData.estimatedHarvestStartDate,
  harvestStartDate: cropData.harvestStartDate,
  estimatedHarvestEndDate: cropData.estimatedHarvestEndDate,
  harvestEndDate: cropData.harvestEndDate
})

export const useCropData = (roomA, roomB) => {
  const strings = Strings()
  const [sectionA, setSectionA] = useState(null)
  const [sectionB, setSectionB] = useState(null)
  const [crop, setCrop] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!roomA?.id || !roomB?.id) return

      setIsLoading(true)
      setError(null)

      try {
        const { data } = await getCrops({
          filter: { zoneId: [roomA.id, roomB.id] }
        })

        if (!data?.crops?.length) {
          setError(strings.noCropInformationFound)
          return
        }

        const crops = data.crops
        const getRoomData = (roomId, roomName) => {
          const crop = crops.find(c => c.zoneId === roomId)
          return crop
            ? {
                id: roomId,
                name: roomName,
                roofCover: crop.roofCover,
                secondskyVariant: crop.secondskyVariant,
                blockingRatio: crop.blockingRatio,
                netType: crop.netType,
                secondskyInformation: crop.secondskyInformation
              }
            : null
        }

        setSectionA(getRoomData(roomA.id, roomA.name))
        setSectionB(getRoomData(roomB.id, roomB.name))
        setCrop(formatCropData(crops[0]))
      } catch (err) {
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [roomA, roomB])

  return { sectionA, sectionB, crop, isLoading, error }
}
