import { I18n } from 'aws-amplify'
import { TRIAL_STATUS } from './shared/trial-status'

const Strings = () => ({
    trialNotebookTitle: I18n.get('Trial Notebook'),
    notesCount: I18n.get('notes'),
    addNoteLabel: I18n.get('Add Note'),
    addingNote: I18n.get('Adding...'),
    notePlaceholder: I18n.get('Add a new observation or note...'),
    noNotesMessage: I18n.get('No notes have been added yet. Add your first observation above.'),
    deleteNoteLabel: I18n.get('Delete note'),
    errorLoadingNotes: I18n.get('Error loading notes'),
    errorAddingNote: I18n.get('Error adding note'),
    errorDeletingNote: I18n.get('Error deleting note'),
    advanceView: I18n.get('Advanced View'),
    basicView: I18n.get('Basic View'),
    editTrialInfo: I18n.get('Edit Trial Info'),
    generatePDFReport: I18n.get('Generate PDF Report'),
    days: I18n.get('days'),
    noCropInformationFound: I18n.get('No crops data available'),
    cropDetails: I18n.get('Crop details'),
    [TRIAL_STATUS.PLANNED]: I18n.get('Planned'),
    [TRIAL_STATUS.IN_PROGRESS]: I18n.get('In Progress'),
    [TRIAL_STATUS.COMPLETED]: I18n.get('Completed'),
    [TRIAL_STATUS.CANCELLED]: I18n.get('Cancelled'),
    started: I18n.get('Started'),
    duration: I18n.get('Duration'),
    status: I18n.get('Status'),
    produce: I18n.get('Produce'),
    variety: I18n.get('Variety'),
    plantingArea: I18n.get('Planting Area'),
    hydrationMethod: I18n.get('Hydration Method'),
    seedQuantity: I18n.get('Seed Quantity'),
    estimatedSowDate: I18n.get('Estimated Sow Date'),
    sowDate: I18n.get('Sow Date'),
    estimatedTransplantDate: I18n.get('Estimated Transplant Date'),
    transplantDate: I18n.get('Transplant Date'),
    estimatedHarvestStartDate: I18n.get('Estimated Harvest Start Date'),
    harvestStartDate: I18n.get('Harvest Start Date'),
    estimatedHarvestEndDate: I18n.get('Estimated Harvest End Date'),
    harvestEndDate: I18n.get('Harvest End Date'),
    dateAndTime: I18n.get('Date & Time'),
    roofCover: I18n.get('Roof Cover'),
    secondskyVariant: I18n.get('SecondSky Variant'),
    blockingRatio: I18n.get('Blocking Ratio'),
    netType: I18n.get('Net Type'),
    secondskyInformation: I18n.get('Additional Information'),
    environmental: I18n.get('Environmental'),
    soil: I18n.get('Soil'),
    metrics: I18n.get('Metrics'),
    liveData: I18n.get('Live Data'),
    historical: I18n.get('Historical'),
    showSections: I18n.get('Show Sections'),
    showDelta: I18n.get('Show Delta'),
    sectionA: I18n.get('Section A'),
    sectionB: I18n.get('Section B'),
    delta: I18n.get('Delta'),
    devices: I18n.get('Devices'),
    clearFilter: I18n.get('Clear Filter'),
    envirosense: I18n.get('Envirosense'),
    soilsense: I18n.get('Soilsense'),
    sensorFilter: I18n.get('Sensor Filter'),
    zoneSensors: I18n.get('Sensors'),
    selectSensors: I18n.get('Select sensors...'),
    cancel: I18n.get('Cancel'),
    apply: I18n.get('Apply'),
    businessInsights: I18n.get('Business Insights'),
    experimentalFeature: I18n.get('Experimental Feature'),
    energy: I18n.get('Energy'),
    water: I18n.get('Water'),
    yield: I18n.get('Yield'),
    fertigation: I18n.get('Fertigation'),
    bussisnessInsightInfo: I18n.get('Cumulative values since the start of the trial for the entire site '),
    totalCostSavings: I18n.get('Total Cost Savings'),
    iyris: I18n.get('Iyris'),
    control: I18n.get('Control'),
    savingsLabel: I18n.get('Savings (B-A)'),
    environmentalMetricsTitle: I18n.get('Enviromental Metrics'),
    soilMetricsTitle: I18n.get('Soil Metrics')
})

export default Strings