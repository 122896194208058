import { I18n } from 'aws-amplify'
import { object, string } from 'yup'

export const TRANSLATION_LANGUAGES = [
  {
    label: I18n.get('English'),
    value: 'en'
  },
  {
    label: I18n.get('Spanish'),
    value: 'es'
  },
  {
    label: I18n.get('Portuguese'),
    value: 'pt'
  },
  {
    label: I18n.get('Arabic'),
    value: 'ar'
  }
]

export const TRANSLATION_LANGUAGES_MAP = TRANSLATION_LANGUAGES.reduce(
  (acc, item) => {
    acc[item.value] = item.label
    return acc
  },
  {}
)

export const labelSchema = (type = 'create') => {
  let schema = {
    organizationId: string().required(I18n.get('Organization ID is required')),
    label: string().required(I18n.get('Label is a required field.')),
    replacementLabel: string().required(
      I18n.get('Replacement label is a required field.')
    ),
    replacementLabelLang: string().required(
      I18n.get('Select the language of the replacement label')
    ),
    replacementLabelPlural: string().required(
      I18n.get('Replacement label plural is a required field.')
    )
  }

  if (type === 'update') {
    schema['labelId'] = string().required(I18n.get('Label ID is required'))
  }

  return object().shape(schema)
}
