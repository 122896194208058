import { useEffect, useState } from 'react'
import {
  requiredVectorMeasurements,
  conversionVectorFunctions
} from '@/Util/MeasurementUtils'

const getDateString = timestamp => {
  if (!timestamp || isNaN(new Date(timestamp).getTime())) {
    return null
  }
  return new Date(timestamp).toISOString().split('T')[0]
}

const processDliData = parEntries => {
  if (!parEntries || !Array.isArray(parEntries) || parEntries.length === 0) {
    return []
  }

  return parEntries.reduce(
    (acc, item, index) => {
      if (!item || typeof item !== 'object' || !item.x) {
        return acc
      }

      const date = getDateString(item.x)

      if (!date) {
        return acc
      }

      const dliValue =
        item?.y != null
          ? conversionVectorFunctions.daily_light_integral({
              par: item.y,
              interval: 'hourly',
              useNew: true
            })
          : null

      if (dliValue === null) {
        return acc
      }

      let newItem = { ...item, y: dliValue }

      if (acc.date === date && acc.calculatedData.length > 0) {
        newItem.y =
          newItem.y + acc.calculatedData[acc.calculatedData.length - 1].y
      }

      acc.calculatedData.push(newItem)
      acc.date = date

      return acc
    },
    { calculatedData: [], date: null }
  ).calculatedData
}

const computeVectorValue = (metric, sectionData, timestamp) => {
  if (!requiredVectorMeasurements[metric]) {
    return null
  }

  if (!timestamp || isNaN(new Date(timestamp).getTime())) {
    return null
  }

  if (metric === 'daily_light_integral') {
    if (!sectionData || !sectionData['par'] || !sectionData['par'].dataset) {
      return null
    }

    const timestampDate = getDateString(timestamp)
    if (!timestampDate) {
      return null
    }

    const parEntries = Object.values(sectionData?.['par']?.dataset || {})
      .filter(entry => {
        const entryDate = getDateString(entry.x)
        return entryDate && entryDate === timestampDate
      })
      .sort((a, b) => new Date(a.x) - new Date(b.x))

    if (parEntries.length === 0) return null

    const processedData = processDliData(parEntries)
    const matchingEntry = processedData.find(entry => entry.x === timestamp)
    return matchingEntry?.y ?? null
  }

  const requiredMeasurements = requiredVectorMeasurements[metric]
  const params = {}
  for (const measurement of requiredMeasurements) {
    if (
      !sectionData ||
      !sectionData[measurement] ||
      !sectionData[measurement].dataset
    ) {
      return null
    }

    const entry = Object.values(sectionData[measurement].dataset || {}).find(
      entry => entry.x === timestamp
    )
    if (!entry) {
      return null
    }
    params[measurement] = entry.y
  }

  const conversionFn = conversionVectorFunctions[metric]
  if (!conversionFn) {
    return null
  }

  return conversionFn(params)
}

export const useDashboardMetrics = (
  dashboardData,
  sectionAId,
  sectionBId,
  metrics
) => {
  const [transformedData, setTransformedData] = useState([])

  useEffect(() => {
    if (
      !dashboardData ||
      !sectionAId ||
      !sectionBId ||
      !metrics ||
      !Array.isArray(metrics)
    ) {
      setTransformedData([])
      return
    }

    const sectionA = dashboardData[sectionAId]
    const sectionB = dashboardData[sectionBId]

    if (!sectionA || !sectionB) {
      setTransformedData([])
      return
    }

    const timestampSet = new Set()
    metrics.forEach(metric => {
      const metricId = metric.id

      if (requiredVectorMeasurements[metricId]) {
        const requiredMeasurements = requiredVectorMeasurements[metricId]
        requiredMeasurements.forEach(measurement => {
          if (sectionA[measurement]?.dataset) {
            Object.values(sectionA[measurement].dataset).forEach(entry => {
              if (entry && entry.x && !isNaN(new Date(entry.x).getTime())) {
                timestampSet.add(entry.x)
              }
            })
          }

          if (sectionB[measurement]?.dataset) {
            Object.values(sectionB[measurement].dataset).forEach(entry => {
              if (entry && entry.x && !isNaN(new Date(entry.x).getTime())) {
                timestampSet.add(entry.x)
              }
            })
          }
        })
      } else {
        if (sectionA[metricId]?.dataset) {
          Object.values(sectionA[metricId].dataset).forEach(entry => {
            if (entry && entry.x && !isNaN(new Date(entry.x).getTime())) {
              timestampSet.add(entry.x)
            }
          })
        }

        if (sectionB[metricId]?.dataset) {
          Object.values(sectionB[metricId].dataset).forEach(entry => {
            if (entry && entry.x && !isNaN(new Date(entry.x).getTime())) {
              timestampSet.add(entry.x)
            }
          })
        }
      }
    })

    const sortedTimestamps = Array.from(timestampSet).sort()

    const transformed = sortedTimestamps.map(timestamp => {
      const data = { timestamp }

      metrics.forEach(metric => {
        const metricId = metric.id

        if (requiredVectorMeasurements[metricId]) {
          const sectionAValue = computeVectorValue(
            metricId,
            sectionA,
            timestamp
          )
          const sectionBValue = computeVectorValue(
            metricId,
            sectionB,
            timestamp
          )

          data[`sectionA_${metricId}`] = sectionAValue ?? 0
          data[`sectionB_${metricId}`] = sectionBValue ?? 0
        } else {
          let sectionAValue = 0
          let sectionBValue = 0

          if (sectionA[metricId]?.dataset) {
            const sectionAEntry = Object.values(
              sectionA[metricId].dataset
            ).find(entry => entry.x === timestamp)
            sectionAValue = sectionAEntry?.y || 0
          }

          if (sectionB[metricId]?.dataset) {
            const sectionBEntry = Object.values(
              sectionB[metricId].dataset
            ).find(entry => entry.x === timestamp)
            sectionBValue = sectionBEntry?.y || 0
          }

          data[`sectionA_${metricId}`] = sectionAValue
          data[`sectionB_${metricId}`] = sectionBValue
        }
      })

      return data
    })

    setTransformedData(transformed)
  }, [dashboardData, sectionAId, sectionBId, metrics])

  return transformedData
}
