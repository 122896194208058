import React, { memo } from 'react'
import Box from '@/primitives/Box'
import Text from '@/primitives/Text'

const InsightMetricValue = memo(({ label, value, unit, price }) => {
  const formattedValue = typeof value === 'number' ? value.toFixed(0) : '—'

  return (
    <Box>
      <Text
        size={100}
        variant='page'
        tone={600}
        style={{ whiteSpace: 'nowrap' }}
      >
        {label}
      </Text>
      <Text
        as='div'
        size={400}
        fontWeight={700}
        style={{ whiteSpace: 'nowrap' }}
      >
        {formattedValue}
        {unit && (
          <Text as='span' size={100} variant='page' tone={500}>
            {unit}
          </Text>
        )}
      </Text>
      {price && (
        <Text size={100} variant='page' tone={500}>
          {price}
        </Text>
      )}
    </Box>
  )
})
export default InsightMetricValue
