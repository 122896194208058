import { Fragment, useReducer, useEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'

import Container from './Container'

import {
  Button,
  Input,
  Form,
  Text,
  Flex,
  Icon,
  Loader,
  Label
} from '@/primitives'

import { LineSeparator } from '@/elements'

import { fetchIsDeviceVerified } from '@/slices/supplier/enrollment'
import { showBanner } from '@/slices/util'

import {
  getDeviceVerified,
  getDeviceEnrolled,
  getDeviceFetched
} from '@/reducers/selectors'

import { useAuth } from '@/contexts/auth-context'

import { signInSchema } from '../../validations'

import Strings from './Strings'

import './SignIn.scss'

const initialState = {
  username: '',
  password: '',
  loading: false,
  warningText: '',
  notificationText: '',
  errors: {}
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    if (
      action.name !== 'errors' &&
      state?.errors?.hasOwnProperty(action.name)
    ) {
      const errors = { ...state.errors }
      delete errors[action.name]
      return { ...state, errors, [action.name]: action.value }
    }

    return { ...state, [action.name]: action.value }
  }

  return state
}

function SignIn({ hubData, setHubData }) {
  const verified = getDeviceVerified()
  const enrolled = getDeviceEnrolled()
  const fetched = getDeviceFetched()
  const strings = Strings()

  const pathname = window.location.pathname

  const auth = useAuth()
  const [showSignIn, setShowSignIn] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()

  if (hubData?.username?.length > 0 && state.username?.length === 0) {
    dispatch({ type: 'update', name: 'username', value: hubData.username })
  }

  useEffect(() => {
    if (pathname.includes('/enroll-device') && !fetched) {
      const deviceId = pathname.split('/')[2]
      if (deviceId) {
        reduxDispatch(fetchIsDeviceVerified(deviceId))
      }
    } else {
      setShowSignIn(true)
    }
  }, [reduxDispatch, pathname, fetched])

  useEffect(() => {
    if (pathname.includes('/enroll-device') && fetched) {
      let warningText = ''
      let notificationText = ''

      if (!verified) {
        warningText = I18n.get('The provided device ID is invalid.')
        notificationText = I18n.get(
          'You need a valid device ID to create an account. Try scanning the QR code on your device again, or contact your supplier.'
        )
        setShowSignIn(false)
      }

      if (verified && enrolled) {
        warningText = I18n.get('Device is already enrolled.')
        notificationText = I18n.get(
          'This device is already enrolled on SecondSky Data. You can sign in to see enrolled devices. If you do not have an account, ask a user from your organization to invite you to the platform.'
        )
        setShowSignIn(true)
      }

      if (verified && !enrolled) {
        notificationText = I18n.get(
          'Sign in or create a new account to enroll your device.'
        )
        setShowSignIn(true)
      }

      dispatch({ type: 'update', name: 'warningText', value: warningText })
      dispatch({
        type: 'update',
        name: 'notificationText',
        value: notificationText
      })
    }
  }, [fetched, pathname, enrolled, verified])

  const onSubmit = async e => {
    e.preventDefault()
    setErrors()
    setLoading(true)
    await onSignIn()
  }

  const onSignIn = async () => {
    try {
      await signInSchema.validate(state, { abortEarly: false })
      await auth.signIn(state.username, state.password)
    } catch (err) {
      let errors = {}

      if (err?.inner) {
        err.inner.forEach(innerErr => {
          errors[innerErr.path] = innerErr.message
        })
      }

      if (!err.inner && err.message) {
        reduxDispatch(
          showBanner({
            type: 'error',
            message: err.message,
            show: true
          })
        )
      }
      setErrors(errors)
      setLoading(false)
    }
  }

  const setErrors = (errors = {}) => {
    dispatch({ type: 'update', name: 'errors', value: errors })
  }

  const setLoading = loading => {
    dispatch({ type: 'update', name: 'loading', value: loading })
  }

  const onChangeInput = e => {
    const { name, value } = e.currentTarget
    dispatch({ type: 'update', name, value })
  }

  const goToForgotPassword = async e => {
    e.preventDefault()
    setHubData({ username: state.username, component: 'forgotPassword' })
  }

  const goToSignUp = async e => {
    e.preventDefault()
    setHubData({ component: 'signUp' })
  }

  const getInputClassName = fieldName => {
    const baseClass = 'SignIn__Form__Input'
    if (state.errors.hasOwnProperty(fieldName)) {
      return `${baseClass}__Error`
    }

    return baseClass
  }

  return (
    <Loader isLoading={state.loading}>
      <Container title={strings.signInTitle}>
        <Fragment>
          {state.warningText && (
            <Text
              size={200}
              variant='danger'
              tone={600}
              fontWeight={600}
              style={{ width: '100%', marginBottom: '1rem' }}
            >
              <Icon
                size={400}
                name='warning'
                style={{ verticalAlign: 'bottom', marginRight: '0.5rem' }}
              />
              {state.warningText}
            </Text>
          )}
          <Text
            size={100}
            variant='page'
            tone={900}
            style={{ marginBottom: '2rem' }}
          >
            {state.notificationText}
          </Text>
        </Fragment>
        {showSignIn && (
          <Fragment>
            <Form className='SignIn__Form' onSubmit={onSubmit}>
              <Flex direction='column'>
                <Label className='SignIn__Form__Label'>
                  {strings.username}
                </Label>
                <Input
                  className={getInputClassName('username')}
                  name='username'
                  value={state.username}
                  onChange={onChangeInput}
                />
                {state?.errors?.hasOwnProperty('username') && (
                  <Fragment>
                    <Icon
                      className='SignIn__Form__InputErrorIcon'
                      name='error'
                      variant='error'
                      tone={500}
                    />
                    <Text className='SignIn__Form__InputError' variant='error'>
                      {state.errors.username}
                    </Text>
                  </Fragment>
                )}
              </Flex>
              <Flex direction='column'>
                <Label className='SignIn__Form__Label'>
                  {strings.passwordPlaceholder}
                </Label>
                <Input
                  type='password'
                  className={getInputClassName('password')}
                  name='password'
                  value={state.password}
                  onChange={onChangeInput}
                />
                {state?.errors?.hasOwnProperty('password') && (
                  <Fragment>
                    <Icon
                      className='SignIn__Form__InputErrorIcon'
                      name='error'
                      variant='error'
                      tone={500}
                    />
                    <Text className='SignIn__Form__InputError' variant='error'>
                      {state.errors.password}
                    </Text>
                  </Fragment>
                )}
              </Flex>
              <Button
                loading={state.loading}
                variant='primary'
                type='submit'
                disabled={state.loading}
                className='SignIn__Form__SignInButton'
              >
                <Text textTransform='capitalize'>
                  {strings.signInBtn.toLocaleUpperCase()}
                </Text>
              </Button>
            </Form>
            <Flex
              alignMainAxis='center'
              style={{ marginTop: '1rem', maxWidth: '100%' }}
            >
              <Button
                variant='text'
                className='SignIn__Form__ForgotPasswordButton'
                onClick={goToForgotPassword}
              >
                <Text as='p' size={100} fontWeight={600}>
                  {strings.forgotPasswordLink}
                </Text>
              </Button>
              {pathname.includes('/enroll-device') && verified && !enrolled && (
                <Fragment>
                  <LineSeparator />
                  <Button
                    variant='text'
                    className='SignIn__Form__SignUpLinkButton'
                    onClick={goToSignUp}
                  >
                    <Text fontFamily='mono' size={100} fontWeight={600}>
                      {`${strings.signUpIntro} `}
                    </Text>
                    <Text
                      fontFamily='mono'
                      size={100}
                      fontWeight={600}
                      variant='primary'
                      tone={600}
                    >
                      {strings.signUpLink}
                    </Text>
                  </Button>
                </Fragment>
              )}
            </Flex>
          </Fragment>
        )}
      </Container>
    </Loader>
  )
}

export default SignIn
