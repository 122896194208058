const words = {
pt: {
  'Only lowercase letters are allowed': 'Apenas letras minúsculas são permitidas',
  'Email is a required field': 'Email é um campo obrigatório',
  'First name is a required field': 'Nome é um campo obrigatório',
  'Last name is a required field': 'Sobrenome é um campo obrigatório',
  'Username is a required field': 'Nome de usuário é um campo obrigatório',
  'Please select a role': 'Selecione um papel',
  'Please enter a role name': 'Digite um nome de papel',
  'Role name must be unique': 'O nome do papel deve ser único',
  'Spaces and special characters other than hyphen (-) and underscore (_) are not supported': 'Espaços e caracteres especiais além de hífen (-) e sublinhado (_) não são suportados',
  'Please enter a location name': 'Por favor, digite um nome de localização',
  'Hyphens (-) are the only special characters allowed in the name': 'Hífens (-) são os únicos caracteres especiais permitidos no nome',
  'Please select a timezone': 'Selecione um fuso horário',
  'Longitude can not be blank': 'A longitude não pode estar em branco',
  'Latitude can not be blank': 'A latitude não pode estar em branco',
  'Organization ID is required': 'ID da organização é obrigatório',
  'Organization is required': 'Organização é obrigatória',
  'Longitude must be between -180 and 180': 'A longitude deve estar entre -180 e 180',
  'Latitude must be between -90 and 90': 'A latitude deve estar entre -90 e 90',
  'Please enter a number': 'Por favor, digite um número',
  'Please enter the vendor name': 'Digite o nome do fornecedor',
  'A vendor with this name already exists': 'Já existe um fornecedor com este nome',
  'Please enter an ID': 'Digite um ID',
  'Only letters and underscores are allowed for ID': 'Apenas letras e sublinhados são permitidos para o ID',
  'Please select a type': 'Selecione um tipo',
  'A measurement with this ID already exists': 'Já existe uma medição com este ID',
  'Please enter a description': 'Digite uma descrição',
  'Please enter a short name': 'Digite um nome abreviado',
  'Please enter a unit': 'Digite uma unidade',
  'Lower threshold can not be greater than upper threshold': 'O limite inferior não pode ser maior que o limite superior',
  'Please add a lower threshold': 'Adicione um limite inferior',
  'Please add an upper threshold': 'Adicione um limite superior',
  'Please select a vendor': 'Selecione um fornecedor',
  'Please enter a name': 'Digite um nome',
  'Type input must have a name': 'A entrada de tipo deve ter um nome',
  'Type input must have a measurement': 'A entrada de tipo deve ter uma medição',
  'Type inputs can not use the same measurement': 'As entradas de tipo não podem usar a mesma medição',
  'Please select an organization': 'Selecione uma organização',
  'Port Address is a required field': 'Endereço da porta é um campo obrigatório',
  'Slave Address is a required field': 'Endereço do escravo é um campo obrigatório',
  'Baud Rate is a required field': 'Taxa de transmissão é um campo obrigatório',
  'Bytesize is a required field': 'Tamanho do byte é um campo obrigatório',
  'Stop Bits is a required field': 'Bits de parada é um campo obrigatório',
  'Timeout is a required field': 'Tempo limite é um campo obrigatório',
  'Read Register count is a required field': 'Contagem de registros de leitura é um campo obrigatório',
  'Read Register is a required field': 'Registro de leitura é um campo obrigatório',
  'Read Measurement Type is a required field': 'Tipo de medição de leitura é um campo obrigatório',
  'Read Measurement Unit is a required field': 'Unidade de medição de leitura é um campo obrigatório',
  'The selected ID is already in use': 'O ID selecionado já está em uso',
  'Please enter a version': 'Por favor, digite uma versão',
  'The flash script version already exists': 'A versão do script flash já existe',
  'Home': 'Início',
  'Environment': 'Ambiente',
  'Temperature': 'Temperatura',
  'Absolute Humidity': 'Umidade absoluta',
  'Air Pressure': 'Pressão do ar',
  'SVP': 'SVP',
  'Fan Speed': 'Velocidade do ventilador',
  'Airflow': 'Fluxo de ar',
  'Carbon Dioxide': 'Dióxido de carbono',
  'UV Index': 'Índice UV',
  'DLI': 'DLI',
  'Solar Irradiance': 'Irradiação solar',
  'VPD': 'VPD',
  'Relative Humidity': 'Umidade relativa',
  'Fertigation': 'Fertirrigação',
  'Water Temperature': 'Temperatura da água',
  'Conductivity': 'Condutividade',
  'pH': 'pH',
  'Dissolved Oxygen': 'Oxigênio dissolvido',
  'Dissolved Oxygen Percentage': 'Percentual de oxigênio dissolvido',
  'Salinity': 'Salinidade',
  'Liquid Flow': 'Fluxo de líquido',
  'Soil Moisture': 'Umidade do solo',
  'Soil Temperature': 'Temperatura do solo',
  'Soil pH': 'pH do solo',
  'Soil Conductivity': 'Condutividade do solo',
  'Soil P Content': 'Conteúdo P do solo',
  'Soil K Content': 'Conteúdo K do solo',
  'Soil N Content': 'Conteúdo N do solo',
  'Plant Science': 'Ciência das plantas',
  'Wet Bulb Temperature': 'Temperatura de bulbo úmido',
  'Resources': 'Recursos',
  'Energy': 'Energia',
  'Photosynthetically Active Radiation': 'Radiação fotossinteticamente ativa',
  'Power Consumed': 'Energia consumida',
  'Actuator State': 'Estado do atuador',
  'Ion Concentration': 'Concentração de íons',
  'Power Generation': 'Geração de energia',
  'Motor Speed': 'Velocidade do motor',
  'Power Consumption': 'Consumo de energia',
  'Line Voltage': 'Tensão de linha',
  'Vapour Pressure Deficit': 'Déficit de pressão de vapor',
  'Wind Speed': 'Velocidade do vento',
  'Energy (kW': 'Energia (kW',
  'RTR': 'RTR',
  'CO2 Flow Rate': 'Taxa de fluxo de CO2',
  'TDS': 'TDS',
  'VFD': 'VFD',
  'UV-C Intensity': 'Intensidade UV-C',
  'Liquid Level': 'Nível de líquido',
  'Growing Degree Hours': 'Horas de grau de crescimento',
  'Site': 'Locais',
  'Sites': 'Locais',
  'Facility': 'Instalação',
  'Facilities': 'Instalações',
  'Room': 'Sala',
  'Rooms': 'Salas',
  'Zone': 'Zona',
  'Zones': 'Zonas',
  'Subzone': 'Subzona',
  'Subzones': 'Subzonas',
  'Current (instantaneous': 'Atual (instantâneo',
  'GDH': 'GDH',
  'Line Frequency': 'Frequência de linha',
  'Liquid Flow (L/m': 'Fluxo de líquido (L/m',
  'PAR': 'PAR',
  'PPFD': 'PPFD',
  'Power Consumption (instantaneous': 'Consumo de energia (instantâneo',
  'Power Generation (instantaneous': 'Geração de energia (instantânea',
  'Power Generated': 'Energia gerada',
  'Pump Speed': 'Velocidade da bomba',
  'Soil N content': 'Conteúdo N do solo',
  'Soil K content': 'Conteúdo K do solo',
  'Soil P content': 'Conteúdo P do solo',
  'Wind Direction': 'Direção do vento',
  'Actor': 'Ator',
  'Actor Type': 'Tipo de ator',
  'Date': 'Data',
  'Event': 'Evento',
  'Service': 'Serviço',
  'Context': 'Contexto',
  'Action': 'Ação',
  'Details': 'Detalhes',
  'Filters': 'Filtros',
  'General': 'Geral',
  'Organization': 'Organização',
  'Inspect': 'Inspecionar',
  'N/A': 'N/A',
  'Inspect Logs': 'Inspecionar registros',
  'View details of the selected log': 'Ver detalhes do registro selecionado',
  'No audit logs found': 'Nenhum registro de auditoria encontrado',
  'Updated Attributes': 'Atributos atualizados',
  'Audit Logs': 'Registros de auditoria',
  'View audit logs': 'Ver registros de auditoria',
  'Filter by actor type': 'Filtrar por tipo de ator',
  'Filter by organization': 'Filtrar por organização',
  'Filter by action': 'Filtrar por ação',
  'Filter by service': 'Filtrar por serviço',
  'Search context': 'Pesquisar contexto',
  'Filter by actor': 'Filtrar por ator',
  'Filter by': 'Filtrar por',
  'Reset all fields': 'Redefinir todos os campos',
  'Refresh data': 'Atualizar dados',
  'Location & Service': 'Localização e serviço',
  'Operations': 'Operações',
  'Management': 'Gerenciamento',
  'Thresholds': 'Limites',
  'Timestream Data': 'Dados Timestream',
  'Device': 'Dispositivo',
  'Calibration': 'Calibração',
  'Video': 'Vídeo',
  'Weather': 'Clima',
  'Manager': 'Gerente',
  'Device Management': 'Gerenciamento de dispositivos',
  'Create': 'Criar',
  'Update': 'Atualizar',
  'Delete': 'Excluir',
  'User': 'Usuário',
  'System': 'Sistema',
  'This cannot be changed later.': 'Isso não pode ser alterado posteriormente.',
  'Characters left: ': 'Caracteres restantes: ',
  'Hardware': 'Hardware',
  'Calculation': 'Cálculo',
  'Software': 'Software',
  'Sensor': 'Sensor',
  'Camera': 'Câmera',
  'Motor': 'Motor',
  'Pump': 'Bomba',
  'Valve': 'Válvula',
  'Switch': 'Interruptor',
  'Core': 'Núcleo',
  'Pyranometer': 'Piranômetro',
  'Energy Monitor': 'Monitor de energia',
  'Weather Station': 'Estação meteorológica',
  'Envirosense': 'Envirosense',
  'Soilsense': 'Soilsense',
  'Envirosense Modbus': 'Envirosense Modbus',
  'Water Flow Meter': 'Medidor de fluxo de água',
  'Watersense': 'Watersense',
  'Airflow Meter': 'Medidor de fluxo de ar',
  'Title is required': 'Título é obrigatório',
  'Help text is required': 'Texto de ajuda é obrigatório',
  'Upload logo as PNG': 'Carregar logo como PNG',
  'Label': 'Etiqueta',
  'Replacement Label': 'Etiqueta substituta',
  'Replacement Label Plural': 'Plural da etiqueta substituta',
  'Replacement Label Language': 'Idioma da etiqueta substituta',
  'Edit': 'Editar',
  'Actions': 'Ações',
  'Translations': 'Traduções',
  'Language': 'Idioma',
  'Singular': 'Singular',
  'Plural': 'Plural',
  'Redo Translations': 'Refazer traduções',
  'Edit Label': 'Editar etiqueta',
  'Select a label to replace': 'Selecione uma etiqueta para substituir',
  'City, Section, Row, Seat, etc.': 'Cidade, Seção, Fileira, Assento, etc.',
  'Cities, Sections, Rows, Seats, etc.': 'Cidades, Seções, Fileiras, Assentos, etc.',
  'After creating the label, we will automatically translate it into the other languages.': 'Após criar a etiqueta, nós a traduziremos automaticamente para os outros idiomas.',
  'Cancel': 'Cancelar',
  'Update Label': 'Atualizar etiqueta',
  'Create Label': 'Criar etiqueta',
  'English': 'Inglês',
  'Spanish': 'Espanhol',
  'Portuguese': 'Português',
  'Arabic': 'Árabe',
  'Label is a required field.': 'Etiqueta é um campo obrigatório.',
  'Replacement label is a required field.': 'Etiqueta substituta é um campo obrigatório.',
  'Select the language of the replacement label': 'Selecione o idioma da etiqueta substituta',
  'Replacement label plural is a required field.': 'Plural da etiqueta substituta é um campo obrigatório.',
  'Label ID is required': 'ID da etiqueta é obrigatório',
  'Manage': 'Gerenciar',
  'Oganization': 'Organização',
  'Labels': 'Etiquetas',
  'Add Label': 'Adicionar etiqueta',
  'Options': 'Opções',
  'Please enter an organization name': 'Digite um nome para a organização',
  'value': 'valor',
  'Add': 'Adicionar',
  'Add new': 'Adicionar novo',
  'Enter a unique name and the relevant details for the': 'Digite um nome único e os detalhes relevantes para o',
  'name': 'nome',
  'ID': 'ID',
  'Enter new details for': 'Digite novos detalhes para',
  'with ID': 'com ID',
  'No': 'Não',
  'The following': 'O seguinte',
  'These are the details associated with the': 'Estes são os detalhes associados ao',
  'Data API': 'API de dados',
  'User Management': 'Gerenciamento de usuários',
  'User Roles': 'Papéis de usuário',
  'System Thresholds': 'Limites do sistema',
  'Control Range Management': 'Gerenciamento de faixa de controle',
  'No users were found': 'Nenhum usuário foi encontrado',
  'Add User': 'Adicionar usuário',
  'Role': 'Papel',
  'Username': 'Nome de usuário',
  'Registration Date': 'Data de registro',
  'Status': 'Status',
  'Last Seen': 'Visto pela última vez',
  'Add Role': 'Adicionar papel',
  'Organizations': 'Organizações',
  'Camera Access': 'Acesso à câmera',
  'Sensor Control': 'Controle de sensor',
  'Threshold Admin': 'Administrador de limite',
  'View': 'Visualizar',
  'Remove User': 'Remover usuário',
  'Loading organizations': 'Carregando organizações',
  'Loading users': 'Carregando usuários',
  'Loading roles': 'Carregando papéis',
  'Loading thresholds': 'Carregando limites',
  'Search by user name': 'Pesquisar por nome de usuário',
  'Search by role name': 'Pesquisar por nome de papel',
  'Search Devices': 'Pesquisar dispositivos',
  'Search by name': 'Pesquisar por nome',
  'Vendors': 'Fornecedores',
  'View and create device vendors': 'Visualizar e criar fornecedores de dispositivos',
  'Add vendor': 'Adicionar fornecedor',
  'Create a new device vendor': 'Criar um novo fornecedor de dispositivo',
  'Save vendor': 'Salvar fornecedor',
  'Vendor name': 'Nome do fornecedor',
  'Edit vendor': 'Editar fornecedor',
  'Update vendor details': 'Atualizar detalhes do fornecedor',
  'Loading vendors': 'Carregando fornecedores',
  'Vendor updated': 'Fornecedor atualizado',
  'Vendor created': 'Fornecedor criado',
  'Types': 'Tipos',
  'View and create device types': 'Visualizar e criar tipos de dispositivos',
  'Add type': 'Adicionar tipo',
  'Create a new device type': 'Criar um novo tipo de dispositivo',
  'Save type': 'Salvar tipo',
  'Vendor': 'Fornecedor',
  'Name': 'Nome',
  'Model': 'Modelo',
  'Model version': 'Versão do modelo',
  'Firmware version': 'Versão do firmware',
  'Communications protocol (separate entries by comma': 'Protocolo de comunicações (separe entradas por vírgula',
  'Edit type': 'Editar tipo',
  'Update type details': 'Atualizar detalhes do tipo',
  'Type inputs': 'Entradas de tipo',
  'Lower threshold in': 'Limite inferior em',
  'Upper threshold in': 'Limite superior em',
  'Remove': 'Remover',
  'Accept': 'Aceitar',
  'Select a measurement type': 'Selecione um tipo de medição',
  'Loading types': 'Carregando tipos',
  'Type updated': 'Tipo atualizado',
  'Type created': 'Tipo criado',
  'Measurements': 'Medições',
  'View and create measurement types with custom threshold ranges': 'Visualizar e criar tipos de medição com faixas de limites personalizadas',
  'Add measurement': 'Adicionar medição',
  'Create a new device measurement': 'Criar uma nova medição de dispositivo',
  'Save measurement': 'Salvar medição',
  'Type': 'Tipo',
  'Select a type': 'Selecione um tipo',
  'Description': 'Descrição',
  'Short name': 'Nome curto',
  'Unit of measurement': 'Unidade de medida',
  'Specify threshold range': 'Especifique a faixa de limite',
  'Upper threshold': 'Limite superior',
  'Lower threshold': 'Limite inferior',
  'Edit measurement': 'Editar medição',
  'Update measurement details': 'Atualizar detalhes da medição',
  'Measurement updated': 'Medição atualizada',
  'Measurement created': 'Medição criada',
  'Filter by type': 'Filtrar por tipo',
  'Flash Scripts': 'Scripts flash',
  'View and create flash scripts': 'Visualizar e criar scripts flash',
  'No available flash scripts': 'Sem scripts flash disponíveis',
  'Add flash script': 'Adicionar script flash',
  'Edit flash script': 'Editar script flash',
  'Create new flash script': 'Criar novo script flash',
  'Update flash script details': 'Atualizar detalhes do script flash',
  'Device Type': 'Tipo de dispositivo',
  'Version': 'Versão',
  'Flash script debug file': 'Arquivo de depuração do script flash',
  'Flash script release file': 'Arquivo de versão do script flash',
  'Flash script release debug file': 'Arquivo de depuração da versão do script flash',
  'Flash script created successfully.': 'Script flash criado com sucesso.',
  'Flash script updated successfully.': 'Script flash atualizado com sucesso.',
  'Device Types': 'Tipo(s) de dispositivo',
  'Device has firmware': 'O dispositivo tem firmware',
  'Device supports Greengrass': 'O dispositivo suporta Greengrass',
  'Add device type': 'Adicionar tipo de dispositivo',
  'Edit device type': 'Editar tipo de dispositivo',
  'No available device types': 'Sem tipos de dispositivos disponíveis',
  'Hardware Version': 'Versão de hardware',
  'Firmware Versions': 'Versões de firmware',
  'Flash Script': 'Script flash',
  'Select versions': 'Selecionar versões',
  'Select version': 'Selecionar versão',
  'Enter version': 'Digite a versão',
  'Add hardware version': 'Adicionar versão de hardware',
  'No available firmware versions': 'Sem versões de firmware disponíveis',
  'Create new device type': 'Criar novo tipo de dispositivo',
  'Update device type details': 'Atualizar detalhes do tipo de dispositivo',
  'Device type created successfully.': 'Tipo de dispositivo criado com sucesso.',
  'Device type updated successfully.': 'Tipo de dispositivo atualizado com sucesso.',
  'Add New User': 'Adicionar novo usuário',
  'Create a new user and add them to the default zones.': 'Criar um novo usuário e adicioná-lo às zonas padrão.',
  'Edit User Profile': 'Editar perfil de usuário',
  'Edit user settings.': 'Editar configurações de usuário.',
  'View User Profile': 'Visualizar perfil de usuário',
  'View user settings.': 'Visualizar configurações de usuário.',
  'User Details': 'Detalhes do usuário',
  'Assign Role': 'Atribuir papel',
  'User Role': 'Papel do usuário',
  'First name': 'Nome',
  'Last name': 'Sobrenome',
  'Email': 'Email',
  'Phone Number': 'Número de telefone',
  'Assign Defaults': 'Atribuir padrões',
  'Save User Details': 'Salvar detalhes do usuário',
  'Reset Fields': 'Redefinir campos',
  'Remove user': 'Remover usuário',
  'You are about to permanently remove a user.': 'Você está prestes a remover permanentemente um usuário.',
  'Delete user': 'Excluir usuário',
  'All roles': 'Todos os papéis',
  'All organizations': 'Todas as organizações',
  'Please complete all fields in the form.': 'Preencha todos os campos no formulário.',
  'Please provide a valid phone number.': 'Forneça um número de telefone válido.',
  'New user created.': 'Novo usuário criado.',
  'New user could not be created.': 'Não foi possível criar o novo usuário.',
  'User details updated.': 'Detalhes do usuário atualizados.',
  'User could not updated.': 'Não foi possível atualizar o usuário.',
  'User deleted.': 'Usuário excluído.',
  'User could not be deleted.': 'Não foi possível excluir o usuário.',
  'New role created.': 'Novo papel criado.',
  'New role could not be created.': 'Não foi possível criar o novo papel.',
  'Role deleted.': 'Papel excluído.',
  'Role could not be deleted.': 'Não foi possível excluir o papel.',
  'Role permission added.': 'Permissão de papel adicionada.',
  'Role permission not updated.': 'Permissão de papel não atualizada.',
  'Role permission removed.': 'Permissão de papel removida.',
  'Role permission could not be removed.': 'Não foi possível remover a permissão de papel.',
  'Role not fetched.': 'Papel não obtido.',
  'Roles not fetched.': 'Papéis não obtidos.',
  'Role permissions not fetched.': 'Permissões de papel não obtidas.',
  'Role users not fetched.': 'Usuários do papel não obtidos.',
  'Invitation sent.': 'Convite enviado.',
  'Invitation could not be sent.': 'Não foi possível enviar o convite.',
  'Permission Settings': 'Configurações de permissão',
  'Manage the permissions granted to users with this role': 'Gerenciar as permissões concedidas aos usuários com este papel',
  'Reset to previous state': 'Redefinir para o estado anterior',
  'Loading permissions': 'Carregando permissões',
  'Use the main toggle to enable viewing the': 'Use a alternância principal para ativar a visualização do/da',
  'Use the main toggle to enable editing the': 'Use a alternância principal para ativar a edição do/da',
  'for all zones.': 'para todas as zonas.',
  'No zones found for organization': 'Nenhuma zona encontrada para a organização',
  'The page will refresh to get the latest zones per organization.': 'A página será atualizada para obter as zonas mais recentes por organização.',
  'Add new role': 'Adicionar novo papel',
  'Enter a unique name for the role, and select a base role to use as a template.': 'Digite um nome único para o papel e selecione um papel base para usar como modelo.',
  'Role name': 'Nome do papel',
  'Base role (inherit permissions from existing role': 'Papel base (herdar permissões do papel existente',
  'Close': 'Fechar',
  'Create new role': 'Criar novo papel',
  'Select a base role': 'Selecione um papel base',
  'Latitude': 'Latitude',
  'Longitude': 'Longitude',
  'Timezone region': 'Região do fuso horário',
  'Code': 'Código',
  'Data Ingestion Frequency': 'Frequência de ingestão de dados',
  'Enable AI Forecast': 'Habilitar previsão de IA',
  'Enable Maintenance Mode': 'Habilitar modo de manutenção',
  'Enable Trial Mode View': 'Habilitar visualização do modo de ensaio',
  'Coordinates:': 'Coordenadas:',
  'Coordinates are undefined.': 'Coordenadas não definidas.',
  'Select a timezone': 'Selecione um fuso horário',
  'Select a status': 'Selecione um status',
  'Save': 'Salvar',
  'Current location': 'Localização atual',
  'Select a new location': 'Selecione uma nova localização',
  'Show provisioning config': 'Mostrar configuração de provisionamento',
  'Download Provision Installer': 'Baixar instalador de provisionamento',
  'Copy to clipboard': 'Copiar para a área de transferência',
  'Generate': 'Gerar',
  '1 - Download the Zip file and transfer it into the intended Greengrass device.': '1 - Baixe o arquivo Zip e transfira-o para o dispositivo Greengrass pretendido.',
  '2 - On a terminal window in the device, navigate to the downloaded Zip file. Normally this is located at /home/user/downloads': '2 - Em uma janela de terminal no dispositivo, navegue até o arquivo Zip baixado. Normalmente está localizado em /home/user/downloads',
  '3 - Unzip the Zip file using an unzip tool for Linux on the command line.': '3 - Descompacte o arquivo Zip usando uma ferramenta de descompactação para Linux na linha de comando.',
  '4 - Navigate to the Zip file and then execute the setup.sh script.': '4 - Navegue até o arquivo Zip e execute o script setup.sh.',
  '5 - Execute the following command: sudo /bin/bash setup.sh': '5 - Execute o seguinte comando: sudo /bin/bash setup.sh',
  'NOTE: The setup.sh script will install all required third party tools and set up the Greengrass nucleus as a system service.': 'NOTA: O script setup.sh instalará todas as ferramentas de terceiros necessárias e configurará o núcleo Greengrass como um serviço do sistema.',
  'Greengrass Core Device': 'Dispositivo central Greengrass',
  'No core device set up yet.': 'Nenhum dispositivo central configurado ainda.',
  'No deployments for this core device.': 'Não há implantações para este dispositivo central.',
  'Create default deployment': 'Criar implantação padrão',
  'Show deployments': 'Mostrar implantações',
  'Deployments': 'Implantações',
  'Reason': 'Motivo',
  'Refetch core device': 'Reobter dispositivo central',
  'Provisioning Config': 'Configuração de provisionamento',
  'Timezone': 'Fuso horário',
  'The following thresholds are part of': 'Os seguintes limites fazem parte de',
  'Managers': 'Gerentes',
  'The following users can manage the location': 'Os seguintes usuários podem gerenciar a localização',
  'Fleet Management': 'Gerenciamento de frota',
  'Core devices and sensor management for': 'Dispositivos centrais e gerenciamento de sensores para',
  'OTA update by sensor': 'Atualização OTA por sensor',
  'OTA update by core device': 'Atualização OTA por dispositivo central',
  'Core device update': 'Atualização do dispositivo central',
  'Query NVS data': 'Consultar dados NVS',
  'Query device and sensor status': 'Consultar status do dispositivo e sensor',
  'Request SIM diagnostic data': 'Solicitar dados de diagnóstico do SIM',
  'Request SIM diagnostic ping': 'Solicitar ping de diagnóstico do SIM',
  'Select one more devices and/or sensors from the list below, followed by the action.': 'Selecione mais dispositivos e/ou sensores da lista abaixo, seguido pela ação.',
  'Select one more core devices from the list below, followed by the action.': 'Selecione mais dispositivos centrais da lista abaixo, seguido pela ação.',
  'Select one more sensors from the list below, followed by the action.': 'Selecione mais sensores da lista abaixo, seguido pela ação.',
  'Command & Control': 'Comando e controle',
  'Control pumps and valves with Greengrass in': 'Controle bombas e válvulas com Greengrass em',
  'Edit settings': 'Editar configurações',
  'Ingestion frequency updated': 'Frequência de ingestão atualizada',
  'The selected values are already applied.': 'Os valores selecionados já estão aplicados.',
  'Reset selection': 'Redefinir seleção',
  'Devices Selected': 'Dispositivos selecionados',
  'Sensors Selected': 'Sensores selecionados',
  'Devices Active': 'Dispositivos ativos',
  'Sensors Active': 'Sensores ativos',
  'Perform Action': 'Realizar ação',
  'Running': 'Em execução',
  'Unknown': 'Desconhecido',
  'Add control device configuration': 'Adicionar configuração de dispositivo de controle',
  'Edit control device configuration': 'Editar configuração de dispositivo de controle',
  'Specify control device specification': 'Especificar especificação de dispositivo de controle',
  'Add configuration': 'Adicionar configuração',
  'Edit configuration': 'Editar configuração',
  'Delete configuration': 'Excluir configuração',
  'View specification': 'Ver especificação',
  'No control devices configured.': 'Nenhum dispositivo de controle configurado.',
  'Address': 'Endereço',
  'Slave address': 'Endereço do escravo',
  'Baudrate': 'Taxa de transmissão',
  'Relay configuration': 'Configuração do relé',
  'Relay ID': 'ID do relé',
  'Register number': 'Número do registro',
  'Select sensor': 'Selecionar sensor',
  'Select relay by ID': 'Selecionar relé por ID',
  'Measurement': 'Medição',
  'Select measurement': 'Selecionar medição',
  'Configure conditions': 'Configurar condições',
  'Minimum': 'Mínimo',
  'Enter min value': 'Digite o valor mínimo',
  'Maximum': 'Máximo',
  'Enter max value': 'Digite o valor máximo',
  'Add specification': 'Adicionar especificação',
  'Remove configuration from Greengrass core device': 'Remover configuração do dispositivo central Greengrass',
  '\'Are you sure you want to remove this configuration? It will remove any specification attached to it. This action cant be undone.\'': '\'Tem certeza de que deseja remover esta configuração? Isso removerá qualquer especificação anexada a ela. Esta ação não pode ser desfeita.\'',
  'Remove relay from device configuration': 'Remover relé da configuração do dispositivo',
  '\'Are you sure you want to remove this relay? It currently has a specification attached to it. This action cant be undone.\'': '\'Tem certeza de que deseja remover este relé? Ele atualmente tem uma especificação anexada. Esta ação não pode ser desfeita.\'',
  'Remove the': 'Remover o/a',
  'role': 'papel',
  'You are about to remove a role.': 'Você está prestes a remover um papel.',
  'Select a fallback role for affected users.': 'Selecione um papel alternativo para os usuários afetados.',
  'Select a fallback role': 'Selecione um papel alternativo',
  'Affected users': 'Usuários afetados',
  'You can not delete a role that has users. Please assign a new role to each of the following users to continue.': 'Você não pode excluir um papel que tenha usuários. Atribua um novo papel a cada um dos seguintes usuários para continuar.',
  'Delete role': 'Excluir papel',
  'No users are currently assigned this role. This role can be safely deleted.': 'Nenhum usuário está atualmente atribuído a este papel. Este papel pode ser excluído com segurança.',
  'No available devices.': 'Nenhum dispositivo disponível.',
  'Location': 'Localização',
  'Tag': 'Etiqueta',
  'Created': 'Criado',
  'Firmware': 'Firmware',
  'In use': 'Em uso',
  'Status last updated': 'Status atualizado pela última vez',
  'Select an action': 'Selecione uma ação',
  'Active': 'Ativo',
  'Unreachable': 'Inacessível',
  'Pending': 'Pendente',
  'Modbus': 'Modbus',
  'IoT': 'IoT',
  'Release': 'Versão',
  'Release Debug': 'Versão debug',
  'Debug': 'Debug',
  'Greengrass': 'Greengrass',
  'Direct Connect': 'Conexão direta',
  'Enroll device': 'Registrar dispositivo',
  'Update type': 'Tipo de atualização',
  'Regenerate firmware': 'Regenerar firmware',
  'Download firmware': 'Baixar firmware',
  'Configure Device': 'Configurar dispositivo',
  'Ionsense': 'Ionsense',
  'Solarsense': 'Solarsense',
  'Devices': 'Dispositivos',
  'The following devices are part of': 'Os seguintes dispositivos fazem parte de',
  'Enroll new device': 'Registrar novo dispositivo',
  'Add new device': 'Adicionar novo dispositivo',
  'Add device': 'Adicionar dispositivo',
  'Edit device': 'Editar dispositivo',
  'Refresh': 'Atualizar',
  'Fetch supplier device': 'Buscar dispositivo do fornecedor',
  'Create new device': 'Criar novo dispositivo',
  'Update device': 'Atualizar dispositivo',
  'Device Version': 'Versão do dispositivo',
  'Connection Method': 'Método de conexão',
  'Connection Type': 'Tipo de conexão',
  'Is IoT Sensor?': 'É Sensor IoT?',
  'Hardware Version Name': 'Nome da versão de hardware',
  'Please select a version': 'Selecione uma versão',
  'Create a new device in': 'Criar um novo dispositivo em',
  'Edit existing device in': 'Editar dispositivo existente em',
  'Device created successfully.': 'Dispositivo criado com sucesso.',
  'Device updated successfully.': 'Dispositivo atualizado com sucesso.',
  'Device deleted successfully.': 'Dispositivo excluído com sucesso.',
  'Unenroll': 'Cancelar registro',
  'Select a type of device': 'Selecione um tipo de dispositivo',
  'Display Configuration': 'Configuração de exibição',
  'Remove Device': 'Remover dispositivo',
  'Are you sure you want to remove this device?': 'Tem certeza de que deseja remover este dispositivo?',
  'Unenroll Device': 'Cancelar registro do dispositivo',
  'Are you sure you want to unenroll this device? You will need to start the enrollment process again.': 'Tem certeza de que deseja cancelar o registro deste dispositivo? Você precisará iniciar o processo de registro novamente.',
  'Are you sure you want to update the type of this device?': 'Tem certeza de que deseja atualizar o tipo deste dispositivo?',
  'Request NVS dump': 'Solicitar dump NVS',
  'No timestamp': 'Sem marca de tempo',
  'No tag set': 'Sem conjunto de etiquetas',
  'Get current coordinates': 'Obter coordenadas atuais',
  'These coordinates are based on your current location. Stand as close to the device as possible to get the most accurate location coordinates.': 'Estas coordenadas são baseadas em sua localização atual. Fique o mais próximo possível do dispositivo para obter as coordenadas de localização mais precisas.',
  'Refetch location': 'Reobter localização',
  'Use coordinates': 'Usar coordenadas',
  'Unavailable': 'Indisponível',
  'SecondSky Data does not have permission to access to your location. Update your browser settings to enable this feature.': 'O SecondSky Data não tem permissão para acessar sua localização. Atualize as configurações do seu navegador para habilitar este recurso.',
  'The device location has changed. Please update the coordinates to match the new location.': 'A localização do dispositivo mudou. Atualize as coordenadas para corresponder à nova localização.',
  'Filter by tags': 'Filtrar por etiquetas',
  'Port Address': 'Endereço da porta',
  'Slave Address': 'Endereço do escravo',
  'Baud Rate': 'Taxa de transmissão',
  'Byte Size': 'Tamanho do byte',
  'Stop Bits': 'Bits de parada',
  'Timeout': 'Tempo limite',
  'Multiple register reads': 'Múltiplas leituras de registro',
  'Select Type': 'Selecionar tipo',
  'Read Registers': 'Ler registros',
  'Read Measurement Type': 'Ler tipo de medição',
  'Read Measurement Unit': 'Ler unidade de medição',
  'Register': 'Registro',
  'Count': 'Contagem',
  'Unit': 'Unidade',
  'Add new manager': 'Adicionar novo gerente',
  'Select a user and notification channels for a location': 'Selecione um usuário e canais de notificação para uma localização',
  'Edit manager': 'Editar gerente',
  'Edit notification channels for user in a location': 'Editar canais de notificação para usuário em uma localização',
  'Add manager': 'Adicionar gerente',
  'Settings': 'Configurações',
  'No available managers.': 'Nenhum gerente disponível.',
  'An error occurred': 'Ocorreu um erro',
  'Push Notification': 'Notificação push',
  'SMS': 'SMS',
  'Web Push': 'Push Web',
  'WhatsApp': 'WhatsApp',
  'Please select a user': 'Selecione um usuário',
  'Create new manager': 'Criar novo gerente',
  'Update manager': 'Atualizar gerente',
  'Manager created successfully.': 'Gerente criado com sucesso.',
  'Manager updated successfully.': 'Gerente atualizado com sucesso.',
  'Manager deleted successfully.': 'Gerente excluído com sucesso.',
  'You are about to remove a manager.': 'Você está prestes a remover um gerente.',
  'Delete manager': 'Excluir gerente',
  'The selected settings are already applied.': 'As configurações selecionadas já estão aplicadas.',
  'Add threshold': 'Adicionar limite',
  'Message': 'Mensagem',
  'No available thresholds.': 'Nenhum limite disponível.',
  'Edit Threshold': 'Editar limite',
  'Add New Threshold': 'Adicionar novo limite',
  'Save Threshold': 'Salvar limite',
  'New threshold created.': 'Novo limite criado.',
  'Threshold details updated.': 'Detalhes do limite atualizados.',
  'Threshold deleted.': 'Limite excluído.',
  'threshold from': 'limite de',
  'You are about to remove a threshold.': 'Você está prestes a remover um limite.',
  'Delete threshold': 'Excluir limite',
  'When value is above': 'Quando o valor está acima',
  'When value is below': 'Quando o valor está abaixo',
  'Upper Bound Conditions': 'Condições de limite superior',
  'Lower Bound Conditions': 'Condições de limite inferior',
  'Upper bound': 'Limite superior',
  'Lower bound': 'Limite inferior',
  'Conditions fall outside of the optimal range. You should keep an eye on this measurement and take action if it does not stabilise.': 'As condições estão fora do intervalo ideal. Você deve ficar atento a esta medição e tomar medidas se ela não se estabilizar.',
  'Conditions fall outside of the acceptable range. You should take action to prevent reaching a stage where damage occurs.': 'As condições estão fora do intervalo aceitável. Você deve tomar medidas para evitar chegar a um estágio onde ocorram danos.',
  'Conditions are adverse. You should take action as soon as possible to prevent irreversible damage.': 'As condições são adversas. Você deve tomar medidas o mais rápido possível para evitar danos irreversíveis.',
  'Cameras': 'Câmeras',
  'Permissions': 'Permissões',
  'Data': 'Dados',
  'Users': 'Usuários',
  'Groups': 'Grupos',
  'Docs': 'Documentos',
  'Roles': 'Papéis',
  'Systems': 'Sistemas',
  'Enroll Device': 'Registrar dispositivo',
  'Search Device': 'Pesquisar dispositivo',
  'Download Firmware': 'Baixar firmware',
  'Firmware Downloaded': 'Firmware baixado',
  'Download Device Firmware': 'Baixar firmware do dispositivo',
  'Device Firmware Downloaded': 'Firmware do dispositivo baixado',
  'Enroll Ethernet Device': 'Registrar dispositivo Ethernet',
  'Enroll Wifi Device': 'Registrar dispositivo Wifi',
  'Pair': 'Parear',
  'Paired': 'Pareado',
  'Pair with Device': 'Parear com dispositivo',
  'Paired with Device': 'Pareado com dispositivo',
  'Device Name Prefix': 'Prefixo do nome do dispositivo',
  'Connect': 'Conectar',
  'Connected': 'Conectado',
  'Establish Secure Connection': 'Estabelecer conexão segura',
  'Established Secure Connection': 'Conexão segura estabelecida',
  'SSID': 'SSID',
  'Password': 'Senha',
  'Configure': 'Configurar',
  'Configured': 'Configurado',
  'Send Wifi Config': 'Enviar configuração wifi',
  'Wifi Config Sent': 'Configuração wifi enviada',
  'Connect Device': 'Conectar dispositivo',
  'Device Connected': 'Dispositivo conectado',
  'Connect Device to Wifi': 'Conectar dispositivo ao Wifi',
  'Device Connected to Wifi': 'Dispositivo conectado ao Wifi',
  'Set Location': 'Definir localização',
  'Location Set': 'Localização definida',
  'Set Device Location': 'Definir localização do dispositivo',
  'Device Location Set': 'Localização do dispositivo definida',
  'Set Tag': 'Definir etiqueta',
  'Tag Set': 'Etiqueta definida',
  'Set Device Tag': 'Definir etiqueta do dispositivo',
  'Device Tag Set': 'Etiqueta do dispositivo definida',
  'Max. 255 characters': 'Máx. 255 caracteres',
  'Disable device': 'Desativar dispositivo',
  'Successfully enrolled device': 'Dispositivo registrado com sucesso',
  'View Device': 'Visualizar dispositivo',
  'Enroll Another Device': 'Registrar outro dispositivo',
  'An Error Occurred': 'Ocorreu um erro',
  'All': 'Todos',
  'No organizations found': 'Nenhuma organização encontrada',
  'Create new organization': 'Criar nova organização',
  'Add organization': 'Adicionar organização',
  'Save organization': 'Salvar organização',
  'New organization created.': 'Nova organização criada.',
  'Edit organization': 'Editar organização',
  'Organization updated.': 'Organização atualizada.',
  'Search by organization name': 'Pesquisar por nome da organização',
  'Select an organization': 'Selecione uma organização',
  'Role organizations': 'Organizações de papel',
  'To enable further customization please navigate to the edit view once it\\s created.': 'Para permitir mais personalizações por favor navegue até a visualização de edição depois de criá-la.',
  'Logs': 'Logs',
  'View activity logs for': 'Ver logs de atividade para',
  'Time': 'Tempo',
  'Successful logins': 'Logins bem-sucedidos',
  'Loading logs': 'Carregando logs',
  'Unsuccessful logins': 'Logins malsucedidos',
  'Active sessions': 'Sessões ativas',
  'No logs to display': 'Nenhum log para exibir',
  'Fetch logs': 'Buscar logs',
  'Start date': 'Data de início',
  'End date': 'Data de término',
  'Usage plan': 'Plano de uso',
  'There is no usage plan for this organization.': 'Não há plano de uso para esta organização.',
  'Throttle': 'Limite',
  'Burst limit': 'Limite de pico',
  'Rate limit': 'Limite de taxa',
  'Quota': 'Cota',
  'Limit': 'Limite',
  'Offset': 'Deslocamento',
  'Period': 'Período',
  'Add usage plan': 'Adicionar plano de uso',
  'View usage plan': 'Ver plano de uso',
  'Confirm new usage plan': 'Confirmar novo plano de uso',
  'Are you sure you want to add a usage plan for this organization?': 'Tem certeza de que deseja adicionar um plano de uso para esta organização?',
  'Confirm': 'Confirmar',
  'Save changes': 'Salvar alterações',
  'Edit usage plan': 'Editar plano de uso',
  'is required': 'é obrigatório',
  'must be a number': 'deve ser um número',
  'No values have been changed': 'Nenhum valor foi alterado',
  'Feature Flags': 'Flags de recursos',
  'Suppliers': 'Fornecedores',
  'Go back': 'Voltar',
  'FYI': 'Para sua informação',
  'Help text': 'Texto de ajuda',
  'Manage help text shown in tooltips': 'Gerenciar texto de ajuda mostrado em dicas de ferramentas',
  'Release notes': 'Notas de lançamento',
  'Tooltip ID': 'ID da dica de ferramenta',
  'Default text (English': 'Texto padrão (inglês',
  'Add new entry': 'Adicionar nova entrada',
  'Add Help Text': 'Adicionar texto de ajuda',
  'Edit Help Text': 'Editar texto de ajuda',
  'Create a new help text entry.': 'Criar uma nova entrada de texto de ajuda.',
  'Update existing help text': 'Atualizar texto de ajuda existente',
  'Release note text': 'Texto da nota de lançamento',
  'Generate from prompt': 'Gerar a partir de prompt',
  'Tags': 'Etiquetas',
  'Generate translations': 'Gerar traduções',
  'Generate Help Text': 'Gerar texto de ajuda',
  'Use a prompt to generate suggested help text': 'Use um prompt para gerar texto de ajuda sugerido',
  'Prompt': 'Prompt',
  'Result': 'Resultado',
  'Generate text': 'Gerar texto',
  'Use suggested text': 'Usar texto sugerido',
  'Select tags': 'Selecionar etiquetas',
  'View and manage translations': 'Visualizar e gerenciar traduções',
  'The following translations will be updated:': 'As seguintes traduções serão atualizadas:',
  'Title': 'Título',
  'No translation available': 'Nenhuma tradução disponível',
  'Sections': 'Seções',
  'Section': 'Seção',
  'Manage release notes sections': 'Gerenciar seções de notas de lançamento',
  'Add section': 'Adicionar seção',
  'Manage release notes': 'Gerenciar notas de lançamento',
  'Create a new release note content item': 'Criar um novo item de conteúdo de nota de lançamento',
  'Update the selected release note content item': 'Atualizar o item de conteúdo de nota de lançamento selecionado',
  'Text': 'Texto',
  'Select a section': 'Selecione uma seção',
  'Add release note': 'Adicionar nota de lançamento',
  'Create a new release note': 'Criar uma nova nota de lançamento',
  'Content': 'Conteúdo',
  'No content added yet': 'Nenhum conteúdo adicionado ainda',
  'Manage sections': 'Gerenciar seções',
  'Add content item': 'Adicionar item de conteúdo',
  'Edit content item': 'Editar item de conteúdo',
  'Publish date': 'Data de publicação',
  'Published': 'Publicado',
  'Visible to users': 'Visível para os usuários',
  'Edit release note': 'Editar nota de lançamento',
  'Update the release note details': 'Atualizar os detalhes da nota de lançamento',
  'Manage tags for help text and release notes': 'Gerenciar etiquetas para texto de ajuda e notas de lançamento',
  'Add tag': 'Adicionar etiqueta',
  'Create a new FYI tag': 'Criar uma nova etiqueta FYI',
  'Edit tag': 'Editar etiqueta',
  'Update the tag name': 'Atualizar o nome da etiqueta',
  'Manage labels': 'Gerenciar etiquetas',
  'Inactive': 'Inativo',
  'Edit user': 'Editar usuário',
  'View logs': 'Ver registros',
  'Send invitation': 'Enviar convite',
  'Feature flags': 'Flags de recursos',
  'Unconfirmed': 'Não confirmado',
  'Confirmed': 'Confirmado',
  'Archived': 'Arquivado',
  'Compromised': 'Comprometido',
  'Reset required': 'Redefinição necessária',
  'Invitation not used': 'Convite não utilizado',
  'No invitation sent': 'Nenhum convite enviado',
  'docs': 'documentos',
  'organization': 'organização',
  'Configuration': 'Configuração',
  'Unique ID': 'ID único',
  'Relays': 'Relés',
  'Register number is required.': 'Número do registro é obrigatório.',
  'Address is required': 'Endereço é obrigatório',
  'Slave address is required': 'Endereço do escravo é obrigatório',
  'Baudrate is required': 'Taxa de transmissão é obrigatória',
  'You must include at least one relay configuration.': 'Você deve incluir pelo menos uma configuração de relé.',
  'Please select a relay': 'Selecione um relé',
  'Please select a sensor': 'Selecione um sensor',
  'Please select a measurement': 'Selecione uma medição',
  'Mininum value is required': 'Valor mínimo é obrigatório',
  'Maximum value is required': 'Valor máximo é obrigatório',
  'You must include at least one specification.': 'Você deve incluir pelo menos uma especificação.',
  'Please provide a value': 'Por favor, forneça um valor',
  'Only numbers are supported': 'Apenas números são suportados',
  'Frequency can not be less than one': 'A frequência não pode ser menor que um',
  'Sleep Cycle (minutes': 'Ciclo de Sono (minutos',
  'Edit Modbus Configuration': 'Editar configuração Modbus',
  'Configure SIM': 'Configurar SIM',
  'Device is being moved': 'O dispositivo está sendo movido',
  'The device': 'O dispositivo',
  'is being moved to another location and will take a few minutes to complete.': 'está sendo movido para outro local e levará alguns minutos para concluir.',
  'You will be unable to move the device again for the next 15 minutes.': 'Você não poderá mover o dispositivo novamente pelos próximos 15 minutos.',
  'Make sure to physically move the device to the new location.': 'Certifique-se de mover fisicamente o dispositivo para o novo local.',
  'Move device': 'Mover dispositivo',
  'Move device will be unlocked': 'Mover dispositivo será desbloqueado',
  'Select a new location to move the selected device': 'Selecione um novo local para mover o dispositivo selecionado',
  'in': 'em',
  'is being moved to': 'está sendo movido para',
  'Move Device': 'Mover dispositivo',
  'Confirm move': 'Confirmar movimento',
  'Function Code': 'Código de Função',
  'Select the measurements to read from the device.': 'Selecione as medições para ler do dispositivo.',
  'Select a measurement': 'Selecione uma medição',
  'The register value must be between 0 and 247.': 'O valor do registro deve estar entre 0 e 247.',
  'Edit the Modbus configuration for this device.': 'Editar a configuração Modbus para este dispositivo.',
  'Modbus Configuration JSON': 'JSON de configuração Modbus',
  'Save Changes': 'Salvar alterações',
  'Suspended': 'Suspenso',
  'Soracom': 'Soracom',
  'SIM Configuration': 'Configuração do SIM',
  'View and edit the SIM configuration for this cellular device.': 'Visualize e edite a configuração do SIM para este dispositivo celular.',
  'Error fetching SIM details. Our team has been notified.': 'Erro ao buscar detalhes do SIM. Nossa equipe foi notificada.',
  'Provider': 'Provedor',
  'Sim ID': 'ID do SIM',
  'Select Status': 'Selecionar status',
  'Suspending a SIM is irreversible. Proceed with caution.': 'Suspender um SIM é irreversível. Prossiga com cuidado.',
  'Go Back': 'Voltar',
  'There seems to be an issue with the device configuration and it cannot be updated anymore. Please contact support.': 'Parece haver um problema com a configuração do dispositivo e ela não pode mais ser atualizada. Entre em contato com o suporte.',
  'is a required field.': 'é um campo obrigatório.',
  'Latitude must be a number between -90 and 90.': 'A latitude deve ser um número entre -90 e 90.',
  'Longitude must be a number between -180 and 180.': 'A longitude deve ser um número entre -180 e 180.',
  'Bytesize': 'Tamanho do byte',
  'Stopbits': 'Bits de parada',
  'Function Code must be 3 or 4': 'O Código de Função deve ser 3 ou 4',
  'Ping Sensor SIM': 'Ping do Sensor SIM',
  'Success': 'Sucesso',
  'RTT': 'RTT',
  'Yes': 'Sim',
  '\'Ping Sensors SIM\'': '',
  'No Greengrass Devices': 'Sem dispositivos Greengrass',
  'No Direct Connect Sensors': 'Sem sensores de conexão direta',
  'OTA update started.': 'Atualização OTA iniciada.',
  'OTA Device Update': 'Atualização OTA do dispositivo',
  'Core Device Update': 'Atualização do dispositivo central',
  'Destination': 'Destino',
  'Devices to be updated:': 'Dispositivos a serem atualizados:',
  'This action will update all Greengrass sensors within the device(s': 'Esta ação atualizará todos os sensores Greengrass dentro do(s) dispositivo(s',
  'Sensors to be updated:': 'Sensores a serem atualizados:',
  'Core Devices for update:': 'Dispositivos centrais para atualização:',
  'Submit': 'Enviar',
  'OTA Update by Sensor': 'Atualização OTA por sensor',
  'OTA Update by Core Device': 'Atualização OTA por dispositivo central',
  'Request NVS Dump': 'Solicitar dump NVS',
  'Request Device Status': 'Solicitar status do dispositivo',
  'Request SIM Diagnostic Data': 'Solicitar Dados de Diagnóstico do SIM',
  'Request SIM Diagnostic Ping': 'Solicitar Ping de Diagnóstico do SIM',
  'Request Sensor Diagnostic Data': 'Solicitar Dados de Diagnóstico do Sensor',
  'Restart Sensor': 'Reiniciar Sensor',
  'Communications Board': 'Placa de comunicações',
  'Select all sensors': 'Selecionar todos os sensores',
  'Select only the device': 'Selecionar apenas o dispositivo',
  'Select all sensors and device': 'Selecionar todos os sensores e dispositivo',
  'User is required': 'Usuário é obrigatório',
  'Selected zone to move': 'Zona selecionada para mover',
  'Select a new location to move the selected location and their children to.': 'Selecione um novo local para mover a localização selecionada e seus filhos.',
  'This': 'Este',
  'Move location': 'Mover localização',
  'Loading': 'Carregando',
  'Search': 'Pesquisar',
  'Check your mailbox': 'Verifique sua caixa de entrada',
  'A verification code was sent to the email address you provided. Enter the code into the field below to activate your account. Do not leave this page until you have completed this step.': 'Um código de verificação foi enviado para o endereço de email que você forneceu. Digite o código no campo abaixo para ativar sua conta. Não saia desta página até completar esta etapa.',
  'Verification code': 'Código de verificação',
  'Continue': 'Continuar',
  'The provided device ID is invalid.': 'O ID do dispositivo fornecido é inválido.',
  'You need a valid device ID to create an account. Try scanning the QR code on your device again, or contact your supplier.': 'Você precisa de um ID de dispositivo válido para criar uma conta. Tente escanear o código QR em seu dispositivo novamente, ou entre em contato com seu fornecedor.',
  'Device is already enrolled.': 'O dispositivo já está registrado.',
  'This device is already enrolled on SecondSky Data. You can sign in to see enrolled devices. If you do not have an account, ask a user from your organization to invite you to the platform.': 'Este dispositivo já está cadastrado no SecondSky Data. Você pode fazer login para ver os dispositivos cadastrados. Se você não possui uma conta, peça a um usuário da sua organização para convidá-lo para a plataforma.',
  'Sign in or create a new account to enroll your device.': 'Faça login ou crie uma nova conta para registrar seu dispositivo.',
  'Confirm password': 'Confirmar senha',
  'Phone number': 'Número de telefone',
  'This will create a new organization. To join an existing organization, you must be invited by a user in that organization.': 'Isso criará uma nova organização. Para se juntar a uma organização existente, você deve ser convidado por um usuário dessa organização.',
  'Sign in to SecondSky Data': 'Entrar no SecondSky Data',
  'Username or email': 'Nome de usuário ou email',
  'Sign In': 'Entrar',
  'An error ocurred': 'Ocorreu um erro',
  'Forgot Password?': 'Esqueceu a senha?',
  'Forgot password': 'Esqueci a senha',
  'New password': 'Nova senha',
  'Re-enter new password': 'Digite novamente a nova senha',
  'Your password must contain at least eight characters including one symbol, one capital letter and one number.': 'Sua senha deve conter pelo menos oito caracteres, incluindo um símbolo, uma letra maiúscula e um número.',
  'Verify': 'Verificar',
  'Change password': 'Alterar senha',
  'The email address associated with your account is not yet verified:': 'O endereço de email associado à sua conta ainda não foi verificado:',
  'A verification code has been sent to your email address.': 'Um código de verificação foi enviado para seu endereço de email.',
  'Password reset complete.': 'Redefinição de senha concluída.',
  'Back to Sign In': 'Voltar para entrar',
  'Skip': 'Pular',
  'Verify your account': 'Verifique sua conta',
  'Multi-factor authentication': 'Autenticação multifator',
  'Open your authenticator app to view the authentication code, then enter the code into the field below.': 'Abra seu aplicativo autenticador para ver o código de autenticação, depois digite o código no campo abaixo.',
  'Passwords do not match': 'As senhas não coincidem',
  'User not found': 'Usuário não encontrado',
  'Please first use the temporary password you received email.': 'Por favor, primeiro use a senha temporária que você recebeu por email.',
  'You have entered an invalid username/email or password': 'Você inseriu um nome de usuário/email ou senha inválidos',
  'Don\\t have an account?': 'Não tem uma conta?',
  'Register here.': 'Registre-se aqui.',
  'Create an account': 'Criar uma conta',
  'Privacy Policy': 'Política de Privacidade do Coretex',
  'By continuing you agree to SecondSky Data': 'Ao continuar você concorda com os Dados da SecondSky',
  'Enter user details': 'Digite os detalhes do usuário',
  'Verify email address': 'Verificar endereço de email',
  'Calibration History': 'Histórico de calibração',
  'History': 'Histórico',
  'No Data': 'Sem dados',
  'Select columns': 'Selecionar colunas',
  'Columns': 'Colunas',
  'Show Graph': 'Mostrar gráfico',
  'Show Table': 'Mostrar tabela',
  'Reset': 'Redefinir',
  'Search...': 'Pesquisar...',
  'Calibrate': 'Calibrar',
  'Successfully calibrated': 'Calibrado com sucesso',
  'Enter': 'Digite',
  'Using the reference': 'Usando a referência',
  'Enter the value into the text box in the adjacent form.': 'Digite o valor na caixa de texto do formulário adjacente.',
  'Press Calibrate button.': 'Pressione o botão Calibrar.',
  'Calibration Success': 'Calibração bem-sucedida',
  'Calibration Procedure': 'Procedimento de calibração',
  'Back': 'Voltar',
  'Min:': 'Mín:',
  'Max:': 'Máx:',
  'Humidity': 'Umidade',
  'EC': 'CE',
  'factor': 'fator',
  'reference value': 'valor de referência',
  'Calibrate by factor': 'Calibrar por fator',
  'Calibrate by reference value': 'Calibrar por valor de referência',
  'Refetch history': 'Rebuscar histórico',
  'No calibration data': 'Sem dados de calibração',
  'Cap Coefficients': 'Coeficientes Cap',
  'User Coefficients': 'Coeficientes do usuário',
  'Enter Salinity value': 'Digite o valor de salinidade',
  'Pressure': 'Pressão',
  'Enter Pressure value': 'Digite o valor de pressão',
  'Enter coefficient': 'Digite o coeficiente',
  '2 point calibration': 'Calibração de 2 pontos',
  'low point': 'ponto baixo',
  'high point': 'ponto alto',
  'Calibrate ph1': 'Calibrar ph1',
  'Calibrate ph2': 'Calibrar ph2',
  '2 point source calibration': 'Calibração de fonte de 2 pontos',
  'Factor source calibration': 'Calibração de fonte de fator',
  'Use Previous Generation': 'Usar geração anterior',
  'Download Datasets': 'Baixar conjuntos de dados',
  'Download all dashboard line chart datasets as CSV': 'Baixar todos os conjuntos de dados de gráficos de linha do painel como CSV',
  'Zipping data... Do not close or refresh the page.': 'Compactando dados... Não feche ou atualize a página.',
  'You are about to download a zip file containing': 'Você está prestes a baixar um arquivo zip contendo',
  'line chart datasets.': 'conjuntos de dados de gráficos de linha.',
  'This may take a while depending on the number of datasets.': 'Isso pode demorar um pouco dependendo do número de conjuntos de dados.',
  'Download': 'Baixar',
  'Layout': 'Layout',
  'Select layout': 'Selecionar layout',
  'Layout tools': 'Ferramentas de layout',
  'Location layouts': 'Layouts de localização',
  'Measurement layouts': 'Layouts de medição',
  'Reset to': 'Redefinir para',
  'default layout': 'layout padrão',
  'Create layout from current': 'Criar layout a partir do atual',
  'Clear current layout': 'Limpar layout atual',
  'Real-time': 'Tempo real',
  'Average Value (Last 24 Hours - Hourly': 'Valor médio (Últimas 24 horas - Por hora)',
  'Dataset Line (Last 24 Hours - Hourly': 'Linha de conjunto de dados (Últimas 24 horas - Por hora)',
  'Dataset Line (Last 7 Days - Daily': 'Linha de conjunto de dados (Últimos 7 dias - Diariamente)',
  'Dataset Line (Last 7 Days - Hourly': 'Linha de conjunto de dados (Últimos 7 dias - Por hora)',
  'is above': 'está acima',
  'is below': 'está abaixo',
  'Exceeded on': 'Excedido em',
  'threshold is': 'o limite é',
  'Snooze Alert': 'Adiar alerta',
  'Select Sensor': 'Selecionar sensor',
  'someSelectedSensorsSnoozed ? Cancel : Snooze': 'algunsSensoresSelecionadosAdiados ? Cancelar : Adiar',
  'is not sending data for:': 'não está enviando dados para:',
  'Delete Notification': 'Excluir notificação',
  'Above on': 'Acima em',
  'Below on': 'Abaixo em',
  'Delete notification for': 'Excluir notificação para',
  'This action cannot be undone.': 'Esta ação não pode ser desfeita.',
  'is below threshold': 'está abaixo do limite',
  'is above threshold': 'está acima do limite',
  'for': 'para',
  'notification': 'notificação',
  'caution': 'atenção',
  'warning': 'aviso',
  'Snooze': 'Adiar',
  'Snoozed': 'Adiado',
  'min': 'min',
  'mins': 'mins',
  'Alert in': 'Alerta em',
  'Select a widget': 'Selecione um widget',
  'Miscellaneous': 'Diversos',
  'Plan': 'Plano',
  'Harvest': 'Colheita',
  'Live': 'Ao vivo',
  'Total Dissolved Solids': 'Sólidos dissolvidos totais',
  'Soil Phosphorus Content': 'Conteúdo de fósforo do solo',
  'Soil Potassium Content': 'Conteúdo de potássio do solo',
  'Soil Nitrogen Content': 'Conteúdo de nitrogênio do solo',
  'Battery SoC': 'Estado de carga da bateria',
  'Select a time format': 'Selecione um formato de hora',
  '24h': '24h',
  '12h': '12h',
  'Not applicable': 'Não aplicável',
  'No crops': 'Sem cultivos',
  'Crops': 'Cultivos',
  'Loading produces': 'Carregando produtos',
  'Select a query is required': 'É necessário selecionar uma consulta',
  'Select a chart is required': 'É necessário selecionar um gráfico',
  'Select a range is required': 'É necessário selecionar um intervalo',
  'Select a produce is required': 'É necessário selecionar um produto',
  'Select an interval is required': 'É necessário selecionar um intervalo',
  'Select a value': 'Selecione um valor',
  'Kg': 'Kg',
  'Produce': 'Produto agrícola',
  'Total Harvested Produce': 'Total de produtos colhidos',
  'Total Produce': 'Total de produtos',
  'Top 10 Harvested Varieties': 'As 10 principais variedades colhidas',
  'Top 10 Varieties': 'As 10 principais variedades',
  'Planned vs harvested varieties': 'Variedades planejadas vs. colhidas',
  'Planned vs Harvested': 'Planejado vs. Colhido',
  'Current Crops': 'Culturas Atuais',
  'Daily Energy Consumption': 'Consumo diário de energia',
  'Energy KW': 'Energia KW',
  'No data': 'Sem dados',
  'Subtitle': 'Subtítulo',
  '+1 hour': '+1 hora',
  'Average': 'Média',
  'Dataset': 'Conjunto de dados',
  'Value': 'Valor',
  'Line': 'Linha',
  'Scatterplot': 'Gráfico de dispersão',
  'Pie': 'Gráfico de pizza',
  'Bar': 'Barra',
  'Matrix': 'Matriz',
  'Last hour': 'Última hora',
  'Today': 'Hoje',
  'Last 24 hours': 'Últimas 24 horas',
  'Last 7 days': 'Últimos 7 dias',
  'Last 30 days': 'Últimos 30 dias',
  'Last 3 months': 'Últimos 3 meses',
  'Current year': 'Ano atual',
  'Last year': 'Ano passado',
  'Use range interval': 'Usar intervalo de alcance',
  'Minutely': 'A cada minuto',
  'Hourly': 'A cada hora',
  'Daily': 'Diariamente',
  'Weekly': 'Semanalmente',
  'Monthly': 'Mensalmente',
  'Edit Widget': 'Editar Widget',
  'Configure the widget settings.': 'Configure as configurações do widget.',
  'Edit the widget settings': 'Editar as configurações do widget',
  'Select a query': 'Selecione uma consulta',
  'Select a chart': 'Selecione um gráfico',
  'Select a range': 'Selecione um intervalo',
  'Select an interval': 'Selecione um intervalo',
  'Select a produce': 'Selecione um produto',
  'depthLabels[index]': '',
  'Moisture': 'Umidade',
  'ºF': 'ºF',
  'Next 7 days': 'Próximos 7 dias',
  'The device you are looking for does not exist.': 'O dispositivo que você está procurando não existe.',
  'The': 'O/A',
  'Toolbox': 'Caixa de ferramentas',
  'Choose a toolbox item': 'Escolha um item da caixa de ferramentas',
  'Add New Widget': 'Adicionar novo widget',
  'Select a device': 'Selecione um dispositivo',
  'Now': 'Agora',
  'Live data': 'Dados em tempo real',
  'No data available': 'Nenhum dado disponível',
  'Weather forecast': 'Previsão do tempo',
  'This week': 'Esta semana',
  'Select a location': 'Selecione uma localização',
  '24 hours': '24 horas',
  '7 days': '7 dias',
  '30 days': '30 dias',
  'Fetching graph data': 'Buscando dados do gráfico',
  'No data for:': 'Sem dados para:',
  'Current': 'Atual',
  'Sensors': 'Sensores',
  'Select': 'Selecionar',
  'sensor': 'sensor',
  'Long soil sensor': 'Sensor de solo longo',
  '10cm': '10cm',
  '20cm': '20cm',
  '30cm': '30cm',
  '40cm': '40cm',
  '50cm': '50cm',
  '60cm': '60cm',
  '70cm': '70cm',
  '80cm': '80cm',
  '90cm': '90cm',
  'Simple': 'Simples',
  'Custom': 'Personalizado',
  'Data API keys': 'Chaves da API de dados',
  'API key': 'Chave da API',
  'Organization name': 'Nome da organização',
  'Organization ID': 'ID da organização',
  'Please contact support': 'Entre em contato com o suporte',
  'Loading...': 'Carregando...',
  'Data API Reference': 'Referência da API de dados',
  'delete': 'excluir',
  'Regenerate': 'Regenerar',
  'Regenerate API key': 'Regenerar chave da API',
  'To proceed, enter': 'Para prosseguir, digite',
  'below, then click': 'abaixo, então clique em',
  'Generate new API key': 'Gerar nova chave da API',
  'Create a new API key for': 'Criar uma nova chave da API para',
  'Type the word': 'Digite a palavra',
  'Warning': 'Aviso',
  'Regenerating the API key will invalidate the old key. Any applications currently using the old key will break. This action cannot be undone.': 'Regenerar a chave da API invalidará a chave antiga. Quaisquer aplicativos atualmente usando a chave antiga serão interrompidos. Esta ação não pode ser desfeita.',
  'Check the API Reference for more information on how to use the Data API': 'Verifique a Referência da API para mais informações sobre como usar a API de dados',
  'Something went wrong. Please try again.': 'Algo deu errado. Por favor, tente novamente.',
  'Export Chart': 'Exportar gráfico',
  'Customize your graph export dimensions': 'Personalize as dimensões de exportação do seu gráfico',
  'Exporting chart... Do not close or refresh the page.': 'Exportando gráfico... Não feche ou atualize a página.',
  'Width': 'Largura',
  'Height': 'Altura',
  'Export': 'Exportar',
  'Width must be at least 512px': 'A largura deve ser de pelo menos 512px',
  'Width must be less than 1920px': 'A largura deve ser menor que 1920px',
  'Height must be at least 288px': 'A altura deve ser de pelo menos 288px',
  'Height must be less than 1080px': 'A altura deve ser menor que 1080px',
  'Date Range': 'Intervalo de datas',
  'Add Date Filter': 'Adicionar filtro de data',
  'Add Location Filter': 'Adicionar filtro de localização',
  'Duplicate Last Location Filter': 'Duplicar Último Filtro de Localização',
  'Preset Time Range': 'Intervalo de tempo predefinido',
  'Start Date': 'Data de início',
  'End Date': 'Data de término',
  'Graph Interval': 'Intervalo do gráfico',
  'Select Organization': 'Selecionar organização',
  'Time Range & Interval': 'Intervalo e faixa de tempo',
  'Location of Interest': 'Localização de interesse',
  'Configure Databoard': 'Configurar painel de dados',
  'Select Measurements': 'Selecionar medições',
  'The date fields time values are set in the time zone specified above.': 'Os valores de hora dos campos de data são definidos no fuso horário especificado acima.',
  'Databoard': 'Painel de dados',
  'Please select a time range, measurements and locations to view the associated data.': 'Selecione um intervalo de tempo, medições e localizações para visualizar os dados associados.',
  'Please wait while we fetch the requested data': 'Aguarde enquanto buscamos os dados solicitados',
  'Select Measurement(s': 'Selecionar medição(ões',
  '1-4': '1-4',
  'Select measurements': 'Selecione medições',
  'Select a zone': 'Selecione uma zona',
  'Download Data': 'Baixar dados',
  'Download graph': 'Baixar gráfico',
  'Fetch Data': 'Buscar dados',
  'Date range': 'Intervalo de datas',
  'Historical Data': 'Dados históricos',
  'Custom Range': 'Intervalo personalizado',
  'Yesterday': 'Ontem',
  'Last month': 'Mês passado',
  'This year': 'Este ano',
  'Last 12 months': 'Últimos 12 meses',
  'Too much data to display': 'Muitos dados para exibir',
  'Compare data by:': 'Comparar dados por:',
  'Date Ranges': 'Intervalos de data',
  'Locations': 'Localizações',
  'Add new date range': 'Adicionar novo intervalo de data',
  'You cannot compare a location to itself, please remove the duplicate locations.': 'Você não pode comparar uma localização consigo mesma, por favor, remova as localizações duplicadas.',
  'You cannot compare a date range to itself, please remove the duplicate date ranges.': 'Você não pode comparar um intervalo de data consigo mesmo, por favor, remova os intervalos de data duplicados.',
  'The selected locations do not have any common measurements.': 'As localizações selecionadas não têm nenhuma medição em comum.',
  'Minute': 'Minuto',
  'Hour': 'Hora',
  'Day': 'Dia',
  'Week': 'Semana',
  'Month': 'Mês',
  'Device with the provided ID does not exist or you do not have permission to view it. Please contact support.': 'O dispositivo com o ID fornecido não existe ou você não tem permissão para visualizá-lo. Entre em contato com o suporte.',
  'is enrolled': 'está registrado',
  'The device must be connected and powered on for you to see data.': 'O dispositivo deve estar conectado e ligado para que você veja os dados.',
  'Go to device dashboard': 'Ir para o painel do dispositivo',
  'Update coordinates': 'Atualizar coordenadas',
  'Made a mistake when enrolling the device? Unenroll it and start the process again.': 'Cometeu um erro ao registrar o dispositivo? Cancele o registro e inicie o processo novamente.',
  'Unenroll device': 'Cancelar registro do dispositivo',
  'Create a new': 'Criar um novo',
  'Choose a': 'Escolha um',
  'This is the physical location of your operation, pinpointed by a unique geographic coordinate. This coordinate may represent an expansive area that includes a variety of areas and sub-areas.': 'Esta é a localização física da sua operação, identificada por uma coordenada geográfica única. Esta coordenada pode representar uma área extensa que inclui uma variedade de áreas e sub-áreas.',
  'Select an existing': 'Selecione um existente',
  'You do not have permission to create': 'Você não tem permissão para criar',
  'Please talk to your administrator.': 'Por favor, fale com seu administrador.',
  'Device ID': 'ID do dispositivo',
  'Display name': 'Nome de exibição',
  'Back to room': 'Voltar para sala',
  'Enroll your device': 'Registrar seu dispositivo',
  'Confirm the details for your device below. Note that the device ID can not be edited. You can go back to the previous steps to edit the location details.': 'Confirme os detalhes do seu dispositivo abaixo. Observe que o ID do dispositivo não pode ser editado. Você pode voltar aos passos anteriores para editar os detalhes da localização.',
  'Your current coordinates': 'Suas coordenadas atuais',
  'Use current coordinates': 'Usar coordenadas atuais',
  'Saved coordinates': 'Coordenadas salvas',
  'Update device coordinates': 'Atualizar coordenadas do dispositivo',
  'Use your current location to set updated coordinates for ': 'Use sua localização atual para definir coordenadas atualizadas para ',
  'Stand as close to the device as you can for the most accurate results.': 'Fique o mais próximo possível do dispositivo para obter os resultados mais precisos.',
  'This is a designated area inside of a': 'Esta é uma área designada dentro de um/uma',
  'You do not have permission to create zones. Please talk to your administrator.': 'Você não tem permissão para criar zonas. Por favor, fale com seu administrador.',
  'Back to': 'Voltar para',
  'Device ID is not valid. Try scanning the device again or contact your supplier.': 'ID do dispositivo não é válido. Tente escanear o dispositivo novamente ou entre em contato com seu fornecedor.',
  'Device not found': 'Dispositivo não encontrado',
  'Location not found': 'Localização não encontrada',
  'Please enter a valid ID': 'Por favor insira um ID válido',
  'Find Dashboard': 'Encontrar Painel',
  'Select either Device or Location and enter its ID': 'Selecione Dispositivo ou Localização e digite seu ID',
  'Search Type': 'Tipo de Pesquisa',
  'Missing data': 'Dados ausentes',
  'Not in use': 'Não está em uso',
  'Sorry, we couldnt find what you were looking for!': 'Desculpe, não encontramos o que você estava procurando!',
  'A name is required.': 'Um nome é necessário.',
  'All measurements are required.': 'Todas as medições são necessárias.',
  'All bounds are required.': 'Todos os limites são necessários.',
  'Manage Produce Threshold': 'Gerenciar Limite de Produção',
  'Save Produce Threshold': 'Salvar Limite de Produção',
  'Add Measurement': 'Adicionar Medição',
  'No measurements': 'Sem medições',
  'Lower Bounds': 'Limites Inferiores',
  'Upper Bounds': 'Limites Superiores',
  'Stress': 'Estresse',
  'Bad': 'Ruim',
  'Good': 'Bom',
  'Produce threshold created successfully.': 'Limite de produção criado com sucesso.',
  'Produce threshold updated successfully.': 'Limite de produção atualizado com sucesso.',
  'Please note that temperatures are stored in Celsius and may change when converted.': 'Observe que as temperaturas são armazenadas em Celsius e podem mudar quando convertidas.',
  'Add Produce Threshold': 'Adicionar Limite de Produção',
  'No entries': 'Sem entradas',
  'Priority is invalid.': 'A prioridade é inválida.',
  'At least one condition is required.': 'Pelo menos uma condição é necessária.',
  'All condition fields are required.': 'Todos os campos de condição são necessários.',
  'A message is required.': 'Uma mensagem é necessária.',
  'Manage Produce Threshold Message': 'Gerenciar Mensagem de Limite de Produção',
  'Save Produce Threshold Message': 'Salvar Mensagem de Limite de Produção',
  'Priority': 'Prioridade',
  'Conditions': 'Condições',
  'Add Condition': 'Adicionar Condição',
  'No conditions have been set': 'Nenhuma condição foi definida',
  'Condition': 'Condição',
  'Expression': 'Expressão',
  'Bound': 'Limite',
  'Please select': 'Selecione',
  'Produce threshold message created successfully.': 'Mensagem de limite de produção criada com sucesso.',
  'Produce threshold message updated successfully.': 'Mensagem de limite de produção atualizada com sucesso.',
  'Produce threshold message deleted successfully.': 'Mensagem de limite de produção excluída com sucesso.',
  'Add Produce Threshold Message': 'Adicionar Mensagem de Limite de Produção',
  'No messages': 'Sem mensagens',
  'More than or equal to': 'Maior ou igual a',
  'Less than or equal to': 'Menor ou igual a',
  'Stress Lower': 'Estresse Inferior',
  'Bad Lower': 'Ruim Inferior',
  'Good Lower': 'Bom Inferior',
  'Good Upper': 'Bom Superior',
  'Bad Upper': 'Ruim Superior',
  'Stress Upper': 'Estresse Superior',
  'High': 'Alto',
  'Medium': 'Médio',
  'Low': 'Baixo',
  'Produce Thresholds': 'Limites de Produção',
  'Produce Threshold Messages': 'Mensagens de Limite de Produção',
  'An organization is required.': 'Uma organização é obrigatória.',
  'Name is required.': 'Nome é obrigatório.',
  'A country must be selected.': 'Um país deve ser selecionado.',
  'A region must be selected.': 'Uma região deve ser selecionada.',
  'A city must be selected.': 'Uma cidade deve ser selecionada.',
  'Manage Area': 'Gerenciar área',
  'Save Area': 'Salvar área',
  'Country': 'País',
  'Region': 'Região',
  'City': 'Cidade',
  'Manage Areas': 'Gerenciar áreas',
  'Add Area': 'Adicionar área',
  'No regions.': 'Nenhuma região.',
  'Area': 'Área',
  'Manage City': 'Gerenciar cidade',
  'Save City': 'Salvar cidade',
  'Manage Cities': 'Gerenciar cidades',
  'Add City': 'Adicionar cidade',
  'No cities.': 'Nenhuma cidade.',
  'Areas': 'Áreas',
  'ISO Code a required field.': 'Código ISO é um campo obrigatório.',
  'Dialing Code a required field.': 'Código de discagem é um campo obrigatório.',
  'A Currency must be selected.': 'Uma moeda deve ser selecionada.',
  'Manage Country': 'Gerenciar país',
  'Save Country': 'Salvar país',
  'Currency': 'Moeda',
  'Dialing Code': 'Código de discagem',
  'ISO Code': 'Código ISO',
  'Manage Countries': 'Gerenciar países',
  'Add Country': 'Adicionar país',
  'Regions': 'Regiões',
  'Code is required.': 'Código é obrigatório.',
  'USD Rate is required.': 'Taxa USD é obrigatória.',
  'Manage Currency': 'Gerenciar moeda',
  'Save Currency': 'Salvar moeda',
  'USD Rate': 'Taxa USD',
  'USD': 'USD',
  'Manage Currencies': 'Gerenciar moedas',
  'Add Currency': 'Adicionar moeda',
  'Manage Grades': 'Gerenciar classificações',
  'Save Grade': 'Salvar classificação',
  'Add Grade': 'Adicionar classificação',
  'Grade': 'Classificação',
  'A category is required.': 'Uma categoria é obrigatória.',
  'A status is required.': 'Um status é obrigatório.',
  'Manage Package Unit': 'Gerenciar unidade de embalagem',
  'Save Package Unit': 'Salvar unidade de embalagem',
  'Manage Package Units': 'Gerenciar unidades de embalagem',
  'Add Package Unit': 'Adicionar unidade de embalagem',
  'No package units': 'Nenhuma unidade de embalagem',
  'Category': 'Categoria',
  'Please Select': 'Selecione',
  'g': 'g',
  'Available': 'Disponível',
  'Box': 'Caixa',
  'Bag': 'Saco',
  'Felin': 'Felin',
  'Clamshell': 'Embalagem plástica',
  'Cup': 'Copo',
  'Tray': 'Bandeja',
  'Lid': 'Tampa',
  'Pack': 'Pacote',
  'Carton': 'Papelão',
  'Sleeve': 'Manga',
  'Sticker': 'Adesivo',
  'All Categories': 'Todas as categorias',
  'Any Status': 'Qualquer status',
  'Manage Payment Method': 'Gerenciar método de pagamento',
  'Save Payment Method': 'Salvar método de pagamento',
  'Manage Payment Methods': 'Gerenciar métodos de pagamento',
  'Add Payment Method': 'Adicionar método de pagamento',
  'Manage Payment Terms': 'Gerenciar termos de pagamento',
  'Save Payment Terms': 'Salvar termos de pagamento',
  'Termsheet': 'Folha de termos',
  'Add Payment Terms': 'Adicionar termos de pagamento',
  'Term Sheet': 'Folha de termos',
  'No term sheet': 'Sem folha de termos',
  'Please provide a code.': 'Forneça um código.',
  'Manage Produce': 'Gerenciar produto',
  'Save Produce': 'Salvar produto',
  'Default Threshold Template': 'Modelo de Limite Padrão',
  'Add Produce': 'Adicionar produto',
  'Varieties': 'Variedades',
  'A Country must be selected.': 'Um país deve ser selecionado.',
  'Manage Region': 'Gerenciar região',
  'Save Region': 'Salvar região',
  'Manage Regions': 'Gerenciar regiões',
  'Add Region': 'Adicionar região',
  'Cities': 'Cidades',
  'An organization is required field.': 'O campo organização é obrigatório',
  'A storage facility must be selected.': 'Uma instalação de armazenamento deve ser selecionada.',
  'Manage Shipping Method': 'Gerenciar método de envio',
  'Save Shipping Method': 'Salvar método de envio',
  'Storage Facility': 'Instalação de armazenamento',
  'Manage Shipping Methods': 'Gerenciar métodos de envio',
  'Add Shipping Method': 'Adicionar método de envio',
  'At least one store type is required.': 'Pelo menos um tipo de loja é obrigatório.',
  'Manage Storage Facility': 'Gerenciar instalação de armazenamento',
  'Save Storage Facility': 'Salvar instalação de armazenamento',
  'Store Types': 'Tipos de lojas',
  'Cold Storage (Produce': 'Armazenamento refrigerado (Produto',
  'General Store (Packaging': 'Loja geral (Embalagem',
  'Chemical Store': 'Loja de produtos químicos',
  'Fertilizer Store': 'Loja de fertilizantes',
  'Refrigerated Seed Store': 'Loja de sementes refrigeradas',
  'Maintenance Supplies': 'Suprimentos de manutenção',
  'Flammable Caged Storage': 'Armazenamento em gaiola para inflamáveis',
  'Manage Storage Facilities': 'Gerenciar instalações de armazenamento',
  'Add Storage Facility': 'Adicionar instalação de armazenamento',
  'Shipping Methods': 'Métodos de envio',
  'A Produce must be selected.': 'Um produto deve ser selecionado.',
  'Manage Variety': 'Gerenciar variedade',
  'Save Variety': 'Salvar variedade',
  'Manage Varieties': 'Gerenciar variedades',
  'Add Variety': 'Adicionar variedade',
  'Variety': 'Variedade',
  'Grades': 'Classificações',
  'Countries': 'Países',
  'Currencies': 'Moedas',
  'The onboarding date cannot be set in the future.': 'A data de integração não pode ser definida no futuro.',
  'An onboarding date is required.': 'Uma data de integração é obrigatória.',
  'Contact name is required.': 'Nome do contato é obrigatório.',
  'Contact number is required.': 'Número de contato é obrigatório.',
  'Contact email is required.': 'Email de contato é obrigatório.',
  'A customer must be selected.': 'Um cliente deve ser selecionado.',
  'An area must be selected.': 'Uma área deve ser selecionada.',
  'A currency must be selected.': 'Uma moeda deve ser selecionada.',
  'A payment method must be selected.': 'Um método de pagamento deve ser selecionado.',
  'A payment term must be selected.': 'Um termo de pagamento deve ser selecionado.',
  'Manage Branches': 'Gerenciar filiais',
  'Create Branch': 'Criar filial',
  'Create New Branch': 'Criar nova filial',
  'Update Existing Branch': 'Atualizar filial existente',
  'Save Branch': 'Salvar filial',
  'Company': 'Empresa',
  'Contact': 'Contato',
  'No branches': 'Sem filiais',
  'Closed': 'Fechado',
  'Banned': 'Banido',
  'Branch': 'Filial',
  'Customer': 'Cliente',
  'Onboarding Date': 'Data de integração',
  'Contact Name': 'Nome do contato',
  'Contact Number': 'Número do contato',
  'Contact Email': 'Email do contato',
  'Payment': 'Pagamento',
  'Payment Method': 'Método de pagamento',
  'Payment Terms': 'Termos de pagamento',
  'Repeated': 'Repetido',
  'All Statuses': 'Todos os status',
  'Fetch': 'Buscar',
  'GMT': 'GMT',
  'Produce is required.': 'Produto é obrigatório.',
  'A variety is required.': 'Uma variedade é obrigatória.',
  'Planting area must be at least 1m2.': 'A área de plantio deve ter pelo menos 1m².',
  'Seed quantity cannot be less than 1.': 'A quantidade de sementes não pode ser menor que 1.',
  'Manage crops': 'Gerenciar cultivos',
  'Create crop': 'Criar cultivo',
  'Create New Crop': 'Criar novo cultivo',
  'Update Existing Crop': 'Atualizar cultivo existente',
  'Save crop': 'Salvar cultivo',
  'Crop': 'Cultivo',
  'Date Created': 'Data de criação',
  'Date Updated': 'Data de atualização',
  'Recommendations Template': 'Modelo de Recomendações',
  'Volume': 'Volume',
  'Planting Area': 'Área de plantio',
  'Planting Area (m2': 'Área de plantio (m²',
  'Growth Medium': 'Meio de cultivo',
  'Hydration Method': 'Método de hidratação',
  'Quantity of Seeds': 'Quantidade de sementes',
  'seeds': 'sementes',
  'Estimated Sow Date': 'Data estimada de semeadura',
  'Date Sowed': 'Data de semeadura',
  'Estimated Transplant Date': 'Data estimada de transplante',
  'Date Transplanted': 'Data de transplante',
  'Estimated Harvest Start Date': 'Data estimada de início da colheita',
  'Harvest Start Date': 'Data de início da colheita',
  'Estimated Harvest End Date': 'Data estimada de fim da colheita',
  'Harvest End Date': 'Data de fim da colheita',
  'Schedule': 'Cronograma',
  'Planned': 'Planejado',
  'Sowed': 'Semeado',
  'Transplanted': 'Transplantado',
  'Harvesting': 'Colhendo',
  'Harvested': 'Colhido',
  'Failed': 'Falhou',
  'Canceled': 'Cancelado',
  'Perlite': 'Perlita',
  'Vermiculite': 'Vermiculita',
  'Volcanic Rock': 'Rocha vulcânica',
  'Rockwool/Stonewool': 'Lã de rocha',
  'Soil': 'Solo',
  'Coconut Coir': 'Fibra de coco',
  'Clay Pellets': 'Pellets de argila',
  'Sand/Gravel/Sawdust': 'Areia/Cascalho/Serragem',
  'Peat Moss': 'Musgo de turfa',
  'Deep Water Culture': 'Cultura em água profunda',
  'Dutch Bucket': 'Balde holandês',
  'Gutter System': 'Sistema de calhas',
  'Nutrient Film Technique': 'Técnica de filme de nutrientes',
  'Aeroponics': 'Aeroponia',
  'Drip System': 'Sistema de gotejamento',
  'Wicking': 'Sistema de pavio',
  'Irrigation': 'Irrigação',
  'm2': 'm²',
  'Query data': 'Consultar dados',
  'Roof Cover': 'Cobertura de telhado',
  'SecondSky': 'SecondSky',
  'Standard Plastic': 'Plástico Padrão',
  'Open Field': 'Campo Aberto',
  'SecondSky Variant': 'Variante SecondSky',
  'SecondSky PolyFilm': 'PolyFilm SecondSky',
  'SecondSky PolyCarbonate': 'PolyCarbonato SecondSky',
  'SecondSky Net': 'Rede SecondSky',
  'Blocking Ratio': 'Proporção de bloqueio',
  'Net Type': 'Tipo de rede',
  '10/20': '10/20',
  '10/16': '10/16',
  'Tricot': 'Tricot',
  'Rashel': 'Rashel',
  'SecondSky Additional Information': 'Informações Adicionais do SecondSky',
  'Not Applicable': 'Não Aplicável',
  'An organization must be selected.': 'Uma organização deve ser selecionada.',
  'Contact Name is required.': 'Nome do contato é obrigatório.',
  'Contact Number is required.': 'Número do contato é obrigatório.',
  'Contact Email is required.': 'Email do contato é obrigatório.',
  'Manage Customers': 'Gerenciar clientes',
  'Create Customer': 'Criar cliente',
  'Create New Customer': 'Criar novo cliente',
  'Update Existing Customer': 'Atualizar cliente existente',
  'Save Customer': 'Salvar cliente',
  'No customers': 'Sem clientes',
  'Contract': 'Contrato',
  'Contract Document': 'Documento de contrato',
  'Registration': 'Registro',
  'Registration Number': 'Número de registro',
  'Registration Document': 'Documento de registro',
  'Add Registration': 'Adicionar registro',
  'Delete Registration': 'Excluir registro',
  'Tax Information': 'Informação fiscal',
  'Tax Number': 'Número fiscal',
  'Tax Document': 'Documento fiscal',
  'Add Tax Information': 'Adicionar informação fiscal',
  'Delete Tax Information': 'Excluir informação fiscal',
  'National Address': 'Endereço nacional',
  'National Address Document': 'Documento de endereço nacional',
  'Street Address': 'Endereço',
  'Street Address 2': 'Endereço 2',
  'Zip Code': 'Código postal',
  'Add National Address': 'Adicionar endereço nacional',
  'Delete National Address': 'Excluir endereço nacional',
  'Bank Details': 'Dados bancários',
  'Bank Details Document': 'Documento de dados bancários',
  'Bank Name': 'Nome do banco',
  'Branch Name': 'Nome da agência',
  'Branch Code': 'Código da agência',
  'Branch Address': 'Endereço da agência',
  'Branch Address 2': 'Endereço da agência 2',
  'Swift/Bic': 'Swift/Bic',
  'IBAN': 'IBAN',
  'Add Bank Details': 'Adicionar dados bancários',
  'Delete Bank Details': 'Excluir dados bancários',
  'Retail': 'Varejo',
  'Wholesale': 'Atacado',
  'Charity': 'Caridade',
  'Hospitality': 'Hospitalidade',
  'Open Market': 'Mercado aberto',
  'Manage Fertilizer Inventory': 'Gerenciar inventário de fertilizantes',
  'No fertilizer inventory': 'Sem inventário de fertilizantes',
  'Transfers': 'Transferências',
  'Purchases': 'Compras',
  'Fertilizer': 'Fertilizante',
  'Total Weight': 'Peso total',
  'kg': 'kg',
  'Fertilizer inventory is required.': 'Inventário de fertilizantes é obrigatório.',
  'New total weight is required.': 'Novo peso total é obrigatório.',
  'New total weight cannot be less than zero.': 'O novo peso total não pode ser menor que zero.',
  'A reason is required.': 'Um motivo é obrigatório.',
  'Manage Fertilizer Inventory Calibrations': 'Gerenciar calibrações de inventário de fertilizantes',
  'Create New Calibration': 'Criar nova calibração',
  'No fertilizer inventory calibrations': 'Sem calibrações de inventário de fertilizantes',
  'Save Calibration': 'Salvar calibração',
  'Inventory Calibration': 'Calibração de inventário',
  'Inventory': 'Inventário',
  '': '',
  'New Total Weight': 'Novo peso total',
  'Old Total Weight': 'Peso total antigo',
  'Current Total Weight': 'Peso total atual',
  'Change': 'Alteração',
  'Please select the origin inventory.': 'Selecione o inventário de origem.',
  'Please select a fertilizer.': 'Selecione um fertilizante.',
  'Please select an origin shipping method.': 'Selecione um método de envio de origem.',
  'Please select a destination storage facility.': 'Selecione uma instalação de armazenamento de destino.',
  'Please select a status.': 'Selecione um status.',
  'Total Weight is required.': 'Peso total é obrigatório.',
  'Total Weight cannot be less than one.': 'O peso total não pode ser menor que um.',
  'At least one weight is required.': 'Pelo menos um peso é obrigatório.',
  'Shipped date is invalid.': 'Data de envio é inválida.',
  'The shipped date cannot be set in the future.': 'A data de envio não pode ser definida no futuro.',
  'Delivered date is invalid.': 'Data de entrega é inválida.',
  'The delivered date cannot be set in the future.': 'A data de entrega não pode ser definida no futuro.',
  'Manage Fertilizer Inventory Transfers': 'Gerenciar transferências de inventário de fertilizantes',
  'Transfer': 'Transferência',
  'Create New Transfer': 'Criar nova transferência',
  'Update Existing Transfer': 'Atualizar transferência existente',
  'No fertilizer inventory transfers': 'Sem transferências de inventário de fertilizantes',
  'Save Transfer': 'Salvar transferência',
  'Inventory Transfer': 'Transferência de inventário',
  'Origin': 'Origem',
  'Shipping Method': 'Método de envio',
  'Date Shipped': 'Data de envio',
  'Date Delivered': 'Data de entrega',
  'Shipped': 'Enviado',
  'Delivered': 'Entregue',
  'Add Weight': 'Adicionar peso',
  'Units': 'Unidades',
  'A storage facility is required.': 'Uma instalação de armazenamento é obrigatória.',
  'A fertilizer unit is required.': 'Uma unidade de fertilizante é obrigatória.',
  'A supplier is required.': 'Um fornecedor é obrigatório.',
  'A currency is required.': 'Uma moeda é obrigatória.',
  'A unit price is required.': 'Um preço unitário é obrigatório.',
  'The unit price cannot be less than one.': 'O preço unitário não pode ser menor que um.',
  'A unit weight is required.': 'Um peso unitário é obrigatório.',
  'The unit weight cannot be less than 0.1.': 'O peso unitário não pode ser menor que 0,1.',
  'The tax cannot be less than zero.': 'O imposto não pode ser menor que zero.',
  'A partial quantity is not allowed.': 'Uma quantidade parcial não é permitida.',
  'A purchase quantity is required.': 'Uma quantidade de compra é obrigatória.',
  'The quantity purchased cannot be less than one.': 'A quantidade comprada não pode ser menor que um.',
  'An purchase quantity is required.': 'Uma quantidade de compra é obrigatória.',
  'Order date is required.': 'Data do pedido é obrigatória.',
  'Order date cannot be set in the future.': 'A data do pedido não pode ser definida no futuro.',
  'Date received is invalid.': 'Data de recebimento é inválida.',
  'Date received cannot be set in the future.': 'A data de recebimento não pode ser definida no futuro.',
  'Status is required': 'Status é obrigatório',
  'Manage Fertilizer Purchases': 'Gerenciar compras de fertilizantes',
  'Add Purchase': 'Adicionar compra',
  'Create Purchase': 'Criar compra',
  'Update Existing Purchase': 'Atualizar compra existente',
  'Save Purchase': 'Salvar compra',
  'No purchases': 'Sem compras',
  'Purchase': 'Compra',
  'Supplier': 'Fornecedor',
  'Quantity': 'Quantidade',
  'Unit Price': 'Preço unitário',
  'Unit Weight': 'Peso unitário',
  'Price': 'Preço',
  'Order': 'Pedido',
  'Delivery': 'Entrega',
  'Tax': 'Imposto',
  'tax': 'imposto',
  'Date Ordered': 'Data do pedido',
  'Date Received': 'Data de recebimento',
  'Ordered': 'Pedido',
  'Received': 'Recebido',
  'Results for date range': 'Resultados para intervalo de datas',
  'A location must be selected.': 'Uma localização deve ser selecionada.',
  'Variety is required.': 'Variedade é obrigatória.',
  'The harvest date cannot be set in the future.': 'A data de colheita não pode ser definida no futuro.',
  'A harvest date is required.': 'Uma data de colheita é obrigatória.',
  'Manage harvests': 'Gerenciar colheitas',
  'Create harvest': 'Criar colheita',
  'Plans': 'Planos',
  'Create New Harvest': 'Criar nova colheita',
  'Update Existing Harvest': 'Atualizar colheita existente',
  'Save Harvest': 'Salvar colheita',
  'Harvest Date': 'Data de colheita',
  'Yield': 'Rendimento',
  'Weights': 'Pesos',
  'Weight': 'Peso',
  'Total Weight (kg': 'Peso total (kg',
  'Photos': 'Fotos',
  'No harvests': 'Sem colheitas',
  'Are you sure?': 'Tem certeza?',
  'Click continue to confirm.': 'Clique em continuar para confirmar.',
  'Deleting a harvest cannot be undone.': 'A exclusão de uma colheita não pode ser desfeita.',
  'Manage Inventory': 'Gerenciar inventário',
  'No inventory': 'Sem inventário',
  'Please select the inventory.': 'Selecione o inventário.',
  'New weight is required.': 'Novo peso é obrigatório.',
  'New weight cannot be less than zero.': 'O novo peso não pode ser menor que zero.',
  'Manage Inventory Calibrations': 'Gerenciar calibrações de inventário',
  'No inventory calibrations': 'Sem calibrações de inventário',
  'New Weight': 'Novo peso',
  'Old Weight': 'Peso antigo',
  'Current Weight': 'Peso atual',
  'Total weight cannot be less than 1kg.': 'O peso total não pode ser menor que 1kg.',
  'Total weight is required.': 'Peso total é obrigatório.',
  'Manage Inventory Transfers': 'Gerenciar transferências de inventário',
  'No inventory transfers': 'Sem transferências de inventário',
  'Manage Packaging Inventory': 'Gerenciar inventário de embalagens',
  'No packaging inventory': 'Sem inventário de embalagens',
  'Package Unit': 'Unidade de embalagem',
  'Package unit inventory is required.': 'Inventário de unidade de embalagem é obrigatório.',
  'New quantity is required.': 'Nova quantidade é obrigatória.',
  'New quantity cannot be less than zero.': 'A nova quantidade não pode ser menor que zero.',
  'Manage Packaging Inventory Calibrations': 'Gerenciar calibrações de inventário de embalagens',
  'No packaging inventory calibrations': 'Sem calibrações de inventário de embalagens',
  'New Quantity': 'Nova quantidade',
  'Old Quantity': 'Quantidade antiga',
  'Current Quantity': 'Quantidade atual',
  'Quantity is required.': 'Quantidade é obrigatória.',
  'Quantity cannot be less than one.': 'A quantidade não pode ser menor que um.',
  'Manage Packaging Inventory Transfers': 'Gerenciar transferências de inventário de embalagens',
  'No packaging inventory transfers': 'Sem transferências de inventário de embalagens',
  'Packaging': 'Embalagem',
  'A package unit is required.': 'Uma unidade de embalagem é obrigatória.',
  'Manage Packaging Purchases': 'Gerenciar compras de embalagens',
  'An estimated harvest start date is required.': 'Uma data estimada de início da colheita é obrigatória.',
  'An estimated harvest end date is required.': 'Uma data estimada de fim da colheita é obrigatória.',
  'At least one estimated weight is required.': 'Pelo menos um peso estimado é obrigatório.',
  'Currency is required.': 'Moeda é obrigatória.',
  'The target price must be more than zero.': 'O preço alvo deve ser maior que zero.',
  'A target price is required.': 'Um preço alvo é obrigatório.',
  'Manage Plans': 'Gerenciar planos',
  'Create plan': 'Criar plano',
  'No plans': 'Sem planos',
  'Create New Plan': 'Criar novo plano',
  'Update Existing Plan': 'Atualizar plano existente',
  'Save Plan': 'Salvar plano',
  'Target Price': 'Preço alvo',
  'Estimated Weights': 'Pesos estimados',
  'A code is required.': 'Um código é obrigatório.',
  'Weight must be at least 1g.': 'O peso deve ser de pelo menos 1g.',
  'Weight is required.': 'Peso é obrigatório.',
  'A grade is required.': 'Uma classificação é obrigatória.',
  'At least one package unit is required.': 'Pelo menos uma unidade de embalagem é obrigatória.',
  'Manage Products': 'Gerenciar produtos',
  'Create Product': 'Criar produto',
  'No products': 'Sem produtos',
  'Create New Product': 'Criar novo produto',
  'Update Existing Product': 'Atualizar produto existente',
  'Save Product': 'Salvar produto',
  'Product': 'Produto',
  'Discontinued': 'Descontinuado',
  'A product must be selected.': 'Um produto deve ser selecionado.',
  'A returned quantity is required.': 'Uma quantidade devolvida é obrigatória.',
  'Returned quantity cannot be less than 1': 'A quantidade devolvida não pode ser menor que 1',
  'A quantity returned must be filled': 'Uma quantidade devolvida deve ser preenchida',
  'A return date must be selected.': 'Uma data de devolução deve ser selecionada.',
  'A branch must be selected': 'Uma filial deve ser selecionada',
  'A customer must be selected': 'Um cliente deve ser selecionado',
  'At least one return item is required': 'Pelo menos um item de devolução é obrigatório',
  'Manage Returns': 'Gerenciar devoluções',
  'Create Return': 'Criar devolução',
  'Create New Return': 'Criar nova devolução',
  'Update Existing Return': 'Atualizar devolução existente',
  'Return': 'Devolução',
  'Return Date': 'Data de devolução',
  'Reference Number': 'Número de referência',
  'No returns': 'Sem devoluções',
  'No items': 'Sem itens',
  'Save Return': 'Salvar devolução',
  'Add Return Item': 'Adicionar item de devolução',
  'Items': 'Itens',
  'Return Item': 'Item de devolução',
  'Amount': 'Valor',
  'Discard': 'Descartar',
  'Quantity Returned': 'Quantidade devolvida',
  'Unordered': 'Não pedido',
  'Unsold': 'Não vendido',
  'Damaged': 'Danificado',
  'Low Quality': 'Baixa qualidade',
  'No payments': 'Sem pagamentos',
  'The received date cannot be older than the order date.': 'A data de recebimento não pode ser anterior à data do pedido.',
  'The received date is required.': 'A data de recebimento é obrigatória.',
  'The amount cannot be less than one.': 'O valor não pode ser menor que um.',
  'An amount is required.': 'Um valor é obrigatório.',
  'Add Item': 'Adicionar item',
  'Add New Item': 'Adicionar novo item',
  'Update Item': 'Atualizar item',
  'Recommended': 'Recomendado',
  'Apply': 'Aplicar',
  'Discount': 'Desconto',
  'Quantity Ordered': 'Quantidade pedida',
  'Save Item': 'Salvar item',
  'Unable to remove item referenced by shipment.': 'Não é possível remover o item referenciado por envio.',
  'Please select a product.': 'Selecione um produto.',
  'An order quantity is required.': 'Uma quantidade de pedido é obrigatória.',
  'The quantity ordered cannot be less than one.': 'A quantidade pedida não pode ser menor que um.',
  'The discount cannot be less than zero.': 'O desconto não pode ser menor que zero.',
  'No Items': 'Sem itens',
  'Sale Item': 'Item de venda',
  'Please select a sale item.': 'Selecione um item de venda.',
  'The quantity cannot be less than one.': 'A quantidade não pode ser menor que um.',
  'An quantity is required.': 'Uma quantidade é obrigatória.',
  'Add Shipment': 'Adicionar envio',
  'Add New Shipment': 'Adicionar novo envio',
  'Update Shipment': 'Atualizar envio',
  'Due Date': 'Data de vencimento',
  'No shipments': 'Sem envios',
  'Due': 'Vencimento',
  'Shipping From': 'Enviando de',
  'Save Shipment': 'Salvar envio',
  'Delayed': 'Atrasado',
  'Please select a storage facility.': 'Selecione uma instalação de armazenamento.',
  'Please select a shipping method.': 'Selecione um método de envio.',
  'The price cannot be less than zero.': 'O preço não pode ser menor que zero.',
  'The due date cannot be older than the order date.': 'A data de vencimento não pode ser anterior à data do pedido.',
  'A due date must be selected.': 'Uma data de vencimento deve ser selecionada.',
  'The delivery date cannot be older than the order date.': 'A data de entrega não pode ser anterior à data do pedido.',
  'You must include at least one item.': 'Você deve incluir pelo menos um item.',
  'An order date must be selected.': 'Uma data de pedido deve ser selecionada.',
  'A customer PO is required.': 'Um PO de cliente é obrigatório.',
  'Please select a customer.': 'Selecione um cliente.',
  'Please select a branch.': 'Selecione uma filial.',
  'Please select a currency.': 'Selecione uma moeda.',
  'Please select a payment method.': 'Selecione um método de pagamento.',
  'Please select the payment terms.': 'Selecione os termos de pagamento.',
  'At least one item is required.': 'Pelo menos um item é obrigatório.',
  'Manage Sales': 'Gerenciar vendas',
  'Create Sale': 'Criar venda',
  'Create New Sale': 'Criar nova venda',
  'Update Existing Sale': 'Atualizar venda existente',
  'Save Sale': 'Salvar venda',
  'Purchase Order': 'Ordem de compra',
  'Invoice': 'Fatura',
  'Sale': 'Venda',
  'Payments': 'Pagamentos',
  'Shipments': 'Envios',
  'Order Date': 'Data do pedido',
  'Customer Purchase Order': 'Ordem de compra do cliente',
  'Invoice Status': 'Status da fatura',
  'Invoice Number': 'Número da fatura',
  'Add Payment': 'Adicionar pagamento',
  'No sales': 'Sem vendas',
  'In Processing': 'Em processamento',
  'In Production': 'Em produção',
  'In Transit': 'Em trânsito',
  'Partially Delivered': 'Parcialmente entregue',
  'Issued': 'Emitido',
  'Submitted': 'Enviado',
  'Awaiting Approval': 'Aguardando aprovação',
  'Awaiting Payment': 'Aguardando pagamento',
  'Paid': 'Pago',
  'inc.': 'inc.',
  'Traceability': 'Rastreabilidade',
  'Changing the organization will delete all items and shipments. This cannot be undone.': 'Alterar a organização excluirá todos os itens e envios. Isso não pode ser desfeito.',
  'At least one supply type is required.': 'Pelo menos um tipo de fornecimento é obrigatório.',
  'Website is required.': 'Website é obrigatório.',
  'Manage Suppliers': 'Gerenciar fornecedores',
  'Create Supplier': 'Criar fornecedor',
  'Create New Supplier': 'Criar novo fornecedor',
  'Update Existing Supplier': 'Atualizar fornecedor existente',
  'Save Supplier': 'Salvar fornecedor',
  'No suppliers': 'Sem fornecedores',
  'Supply Types': 'Tipos de fornecimento',
  'Contact Person': 'Pessoa de contato',
  'Email Address': 'Endereço de email',
  'Website': 'Website',
  'All Regions': 'Todas as regiões',
  'Add Address': 'Adicionar endereço',
  'Delete Address': 'Excluir endereço',
  'Branding': 'Branding',
  'Seeds': 'Sementes',
  'Substrate': 'Substrato',
  'Technology': 'Tecnologia',
  'Construction': 'Construção',
  'Telecommunications': 'Telecomunicações',
  'Package Date': 'Data de embalagem',
  'Generate Code': 'Gerar código',
  'Batch#': 'Lote#',
  'Harvest is required.': 'Colheita é obrigatória.',
  'The package date cannot be set in the future.': 'A data de embalagem não pode ser definida no futuro.',
  'A package date is required.': 'Uma data de embalagem é obrigatória.',
  'Customer is required.': 'Cliente é obrigatório.',
  'Please select customer.': 'Selecione o cliente.',
  'The selling price cannot be less than zero.': 'O preço de venda não pode ser menor que zero.',
  'Manage Unit Prices': 'Gerenciar preços unitários',
  'Create Unit Price': 'Criar preço unitário',
  'Create New Unit Price': 'Criar novo preço unitário',
  'Update Existing Unit Price': 'Atualizar preço unitário existente',
  'No Unit Prices': 'Sem preços unitários',
  'Save Unit Price': 'Salvar preço unitário',
  'Article Code': 'Código do artigo',
  'Alternate Description': 'Descrição alternativa',
  '%': '%',
  'discount': 'desconto',
  'Selling Price': 'Preço de venda',
  'Article': 'Artigo',
  'A produce must be selected.': 'Um produto deve ser selecionado.',
  'A variety must be selected.': 'Uma variedade deve ser selecionada.',
  'The waste date cannot be set in the future.': 'A data de descarte não pode ser definida no futuro.',
  'A waste date is required.': 'Uma data de descarte é obrigatória.',
  'Weights field must have at least 1 item.': 'O campo de pesos deve ter pelo menos 1 item.',
  'Manage Waste': 'Gerenciar descarte',
  'Create Waste': 'Criar descarte',
  'Waste': 'Descarte',
  'Create New Waste': 'Criar novo descarte',
  'Update Existing Waste': 'Atualizar descarte existente',
  'Save Waste': 'Salvar descarte',
  'No waste': 'Sem descarte',
  'Expired': 'Expirado',
  'Customize your chart export dimensions': 'Personalize as dimensões de exportação do seu gráfico',
  'Width (px': 'Largura (px',
  'Enter a width': 'Digite uma largura',
  'Crop status changes over time by location': 'Mudanças de status do cultivo ao longo do tempo por localização',
  'Width must be no more than 1920px': 'A largura não deve ser maior que 1920px',
  'Not yet planned': 'Ainda não planejado',
  'Harvest started': 'Colheita iniciada',
  'Harvest ended': 'Colheita finalizada',
  'Not planned': 'Não planejado',
  'Total weight': 'Peso total',
  'Harvest date': 'Data de colheita',
  'Harvest data': 'Dados de colheita',
  'No data to display': 'Não há dados para exibir',
  'Search for harvests': 'Pesquisar colheitas',
  'View weight by grade': 'Ver peso por classificação',
  'No data to show for': 'Nenhum dado para mostrar para',
  'Crops data': 'Dados de cultivos',
  'Search for crops': 'Pesquisar cultivos',
  'Planting area m': 'Área de plantio m',
  'Seed quantity': 'Quantidade de sementes',
  'Produce weight by grade and location': 'Peso do produto por classificação e localização',
  'Produce weight by location': 'Peso do produto por localização',
  'Bar chart of produce by location': 'Gráfico de barras do produto por localização',
  'Table of harvest entries': 'Tabela de entradas de colheita',
  'Crops status changes over time by location': 'Mudanças de status dos cultivos ao longo do tempo por localização',
  'Crops timelines': 'Cronogramas de cultivos',
  'Table of crops entries': 'Tabela de entradas de cultivos',
  'Unplanned': 'Não planejado',
  'All Cities': 'Todas as cidades',
  'All Countries': 'Todos os países',
  'All Customers': 'Todos os clientes',
  'site': 'site',
  'defaultLabel': 'etiquetaPadrão',
  'All Organizations': 'Todas as organizações',
  'All Package Units': 'Todas as unidades de embalagem',
  'All Produce': 'Todos os produtos',
  'All Products': 'Todos os produtos',
  'All Sites': 'Todos os locais',
  'All Varieties': 'Todas as variedades',
  'No weights': 'Sem pesos',
  'Weight must be at least 0.1kg.': 'O peso deve ser de pelo menos 0,1kg.',
  'Quantity must be at least 1.': 'A quantidade deve ser de pelo menos 1.',
  'Add Photo': 'Adicionar foto',
  'New Photo': 'Nova foto',
  'Subject': 'Assunto',
  'Retake': 'Refazer',
  'Photo': 'Foto',
  'Take Photo': 'Tirar foto',
  'Please allow use of camera': 'Permita o uso da câmera',
  'Select Subject': 'Selecionar assunto',
  'None': 'Nenhum',
  'Plant': 'Planta',
  'Leaf': 'Folha',
  'Fruit': 'Fruta',
  'Healthy': 'Saudável',
  'Diseased': 'Doente',
  'Deformed': 'Deformado',
  'Weight (kg': 'Peso (kg',
  'Select Grade': 'Selecionar classificação',
  'Weight must be at least 1kg.': 'O peso deve ser de pelo menos 1kg.',
  'Package Units': 'Unidades de embalagem',
  'Storage Facilities': 'Instalações de armazenamento',
  'Returns': 'Devoluções',
  'Payment Methods': 'Métodos de pagamento',
  'Sales': 'Vendas',
  'Products': 'Produtos',
  'Customers': 'Clientes',
  'Admin': 'Admin',
  'Last Updated:': 'Última atualização:',
  'Welcome to SecondSky Data, the agricultural data visualization platform. Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information. This policy is designed to be compliant with the General Data Protection Regulation (GDPR) of the EU, relevant US federal and state privacy laws, and the Personal Data Protection Law of Saudi Arabia.': 'Bem-vindo ao SecondSky Data, a plataforma de visualização de dados agrícolas. Sua privacidade é importante para nós. Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e protegemos suas informações. Esta política foi projetada para estar em conformidade com o Regulamento Geral de Proteção de Dados (GDPR) da UE, leis de privacidade federais e estaduais relevantes dos EUA e a Lei de Proteção de Dados Pessoais da Arábia Saudita.',
  '1. Information Collection and Use': '1. Coleta e uso de informações',
  'Personal Data: We collect personal data such as name, email address, and location when you register on SecondSky Data. This information is used to provide and improve our services.': 'Dados Pessoais: Coletamos dados pessoais como nome, endereço de e-mail e localização quando você se registra no SecondSky Data. Essas informações são usadas para fornecer e melhorar nossos serviços.',
  'Agricultural Data: We collect agricultural data for visualization and analysis. This data may include geographic, environmental, crop, yield, and other operational information.': 'Dados agrícolas: Coletamos dados agrícolas para visualização e análise. Esses dados podem incluir informações geográficas, ambientais, de cultivo, rendimento e outras informações operacionais.',
  '2. Consent': '2. Consentimento',
  'By using SecondSky Data, you consent to the collection and use of your information as outlined in this policy. For users in the EU, we comply with GDPR requirements for explicit consent.': 'Ao usar o SecondSky Data, você consente com a coleta e uso de suas informações conforme descrito nesta política. Para usuários na UE, cumprimos os requisitos do GDPR para consentimento explícito.',
  '3. Data Sharing and Disclosure': '3. Compartilhamento e divulgação de dados',
  'We do not sell your personal data. We may share your information with third parties for the purposes of improving our services, complying with the law, or in the event of a business transfer.': 'Não vendemos seus dados pessoais. Podemos compartilhar suas informações com terceiros para fins de melhorar nossos serviços, cumprir a lei ou em caso de transferência de negócios.',
  'Cross-Border Data Transfers: Your data may be stored and processed in any country where we have operations or where we engage service providers.': 'Transferências transfronteiriças de dados: Seus dados podem ser armazenados e processados em qualquer país onde temos operações ou onde contratamos prestadores de serviços.',
  '4. Data Security': '4. Segurança de dados',
  'We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, and destruction.': 'Implementamos medidas técnicas e organizacionais apropriadas para proteger seus dados contra acesso não autorizado, divulgação, alteração e destruição.',
  '5. Your Rights': '5. Seus direitos',
  'EU Users: Under the GDPR, you have the right to access, correct, delete, or restrict the processing of your personal data.': 'Usuários da UE: Sob o GDPR, você tem o direito de acessar, corrigir, excluir ou restringir o processamento de seus dados pessoais.',
  'US Users: Depending on your state, you may have specific rights regarding access to and control of your personal data.': 'Usuários dos EUA: Dependendo do seu estado, você pode ter direitos específicos em relação ao acesso e controle de seus dados pessoais.',
  'Saudi Arabian Users: In accordance with the Personal Data Protection Law, you have rights regarding the processing of your personal data.': 'Usuários da Arábia Saudita: De acordo com a Lei de Proteção de Dados Pessoais, você tem direitos em relação ao processamento de seus dados pessoais.',
  '6. Data Retention': '6. Retenção de dados',
  'We retain your personal data only as long as necessary to provide you with our services and for legitimate and essential business purposes.': 'Retemos seus dados pessoais apenas pelo tempo necessário para fornecer nossos serviços e para fins comerciais legítimos e essenciais.',
  '7. Changes to This Policy': '7. Alterações nesta política',
  'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.': 'Podemos atualizar nossa Política de Privacidade periodicamente. Notificaremos você sobre quaisquer alterações publicando a nova política nesta página.',
  '8. Contact Us': '8. Entre em contato conosco',
  'If you have any questions about this Privacy Policy, please contact us at support@secondsky.com': 'Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco pelo e-mail support@secondsky.com',
  'WhatsApp number': 'Número de WhatsApp',
  'verified': 'verificado',
  'Message our chatbot at this number': 'Envie mensagem para nosso chatbot neste número',
  'WhatsApp number not': 'Número de WhatsApp não',
  'yet.': 'ainda.',
  '\'Verify me\'': '',
  'to our number': 'para nosso número',
  'Profile': 'Perfil',
  'Enter phone number': 'Digite o número de telefone',
  'Include country code': 'Inclua o código do país',
  'WhatsApp Number': 'Número de WhatsApp',
  'Phone number updated successfully.': 'Número de telefone atualizado com sucesso.',
  'An error occurred while updating your phone number. Please try again.': 'Ocorreu um erro ao atualizar seu número de telefone. Por favor, tente novamente.',
  'Update phone number': 'Atualizar número de telefone',
  'Update WhatsApp number': 'Atualizar número de WhatsApp',
  'WhatsApp number updated successfully.': 'Número de WhatsApp atualizado com sucesso.',
  'An error occurred while updating your WhatsApp number. Please try again.': 'Ocorreu um erro ao atualizar seu número de WhatsApp. Por favor, tente novamente.',
  'WhatsApp number deleted successfully.': 'Número de WhatsApp excluído com sucesso.',
  'An error occurred while deleting your WhatsApp number. Please try again.': 'Ocorreu um erro ao excluir seu número de WhatsApp. Por favor tente novamente.',
  'Preferred Language': 'Idioma preferido',
  'Update language': 'Atualizar idioma',
  'Preferred language updated successfully.': 'Idioma preferido atualizado com sucesso.',
  'An error occurred while updating your preferred language. Please try again.': 'Ocorreu um erro ao atualizar seu idioma preferido. Por favor, tente novamente.',
  'Multi-factor Authentication': 'Autenticação multifator',
  'Setup': 'Configuração',
  'Disable': 'Desativar',
  'Submit code': 'Enviar código',
  'Open your Authenticator app and scan the QR code above. Enter the code from the app to complete the setup.': 'Abra seu aplicativo Authenticator e escaneie o código QR acima. Digite o código do aplicativo para concluir a configuração.',
  'Enter code': 'Digite o código',
  'TOTP': 'TOTP',
  'Multi-factor authentication enabled successfully.': 'Autenticação multifator ativada com sucesso.',
  'Multi-factor authentication disabled successfully.': 'Autenticação multifator desativada com sucesso.',
  'Code mismatch.': 'Código não corresponde.',
  'An error occurred while enabling multi-factor authentication. Please try again.': 'Ocorreu um erro ao ativar a autenticação multifator. Por favor, tente novamente.',
  'Reset dashboards': 'Redefinir painéis',
  'Reset your dashboards to defaults': 'Redefinir seus painéis para os padrões',
  'Set up MFA': 'Configurar MFA',
  'Update your profile settings': 'Atualizar suas configurações de perfil',
  'By verifying your WhatsApp number you agree to receive automated notifications.': 'Ao verificar seu número de WhatsApp você concorda em receber notificações automatizadas.',
  'You have agreed to receive automated notifications on this number.': 'Você concordou em receber notificações automatizadas neste número.',
  'Light': 'Luz',
  'Dark': 'Escuro',
  'Preferred Color Scheme': 'Esquema de Cores Preferido',
  'Preferred color scheme updated successfully': 'Esquema de cores preferido atualizado com sucesso',
  'An error occured while trying to set preferred color scheme. Please try again.': 'Ocorreu um erro ao tentar definir o esquema de cores preferido. Por favor tente novamente.',
  'Reports': 'Relatórios',
  'Generate new report': 'Gerar novo relatório',
  'Download report': 'Baixar relatório',
  '30 days (daily': '30 dias (diário',
  '7 days (daily': '7 dias (diário',
  'Report ID': 'ID do relatório',
  'Report date': 'Data do relatório',
  'From date': 'Data de início',
  'To date': 'Data de término',
  'No reports exist for the selected filters': 'Não existem relatórios para os filtros selecionados',
  'Please select a report start date': 'Selecione uma data de início do relatório',
  'Fetching reports for selected start date...': 'Buscando relatórios para a data de início selecionada...',
  'Error fetching reports': 'Erro ao buscar relatórios',
  'Report generation in progress': 'Geração de relatório em andamento',
  'The report generation process can take some time. You can now leave this page. An email will be sent to you once your report is available for download.': 'O processo de geração de relatório pode levar algum tempo. Você pode sair desta página agora. Um email será enviado para você assim que seu relatório estiver disponível para download.',
  'Reset zoom': 'Redefinir zoom',
  'Select a': 'Selecione um',
  'OK': 'OK',
  'Founding Partners': 'Sócios fundadores',
  'Retrieving data failed with error:': 'A recuperação de dados falhou com erro:',
  'has': 'tem',
  'alert': 'alerta',
  'alerts': 'alertas',
  'above': 'acima',
  'below': 'abaixo',
  'threshold': 'limite',
  'No Thresholds set': 'Nenhum limite definido',
  'PH': 'PH',
  'UV A': 'UV A',
  'Envirosense PV Power': 'Energia FV Envirosense',
  'PV Current': 'Corrente FV',
  'PV Voltage': 'Tensão FV',
  'PV Load Voltage': 'Tensão de carga FV',
  'PV Load Current': 'Corrente de carga FV',
  'PV VMP': 'VMP FV',
  'PV Max Output Power': 'Potência máxima de saída FV',
  'Photo Fan Output Power': 'Potência de saída do ventilador foto',
  'Photo Fan Motor Speed': 'Velocidade do motor do ventilador foto',
  'Photo Fan VFD Output Freq': 'Frequência de saída VFD do ventilador foto',
  'A new version is available.': 'Uma nova versão está disponível.',
  'Click here to update.': 'Clique aqui para atualizar.',
  'See the latest updates on SecondSky Data': 'Veja as últimas atualizações no SecondSky Data',
  'No results': 'Sem resultados',
  'View alerts': 'Ver alertas',
  'View overview': 'Ver visão geral',
  'Alerts': 'Alertas',
  'Search by sensor name': 'Pesquisar por nome do sensor',
  'Cellular Envirosense': 'Envirosense Celular',
  'Cellular Watersense': 'Watersense Celular',
  'Cellular Soilsense': 'Soilsense Celular',
  'Notification': 'Notificação',
  'Caution': 'Cuidado',
  'Offline': 'Offline',
  'Is in use': 'Está em uso',
  'Threshold': 'Limite',
  'Expand all': 'Expandir tudo',
  'Collapse all': 'Recolher tudo',
  'Expand': 'Expandir',
  'Collapse': 'Recolher',
  'Export CSV': 'Exportar CSV',
  'Reset all': 'Redefinir tudo',
  'Zone ID': 'ID da zona',
  'Sensor ID': 'ID do Sensor',
  'Sensor type': 'Tipo de sensor',
  'Hardware version': 'Versão do hardware',
  'Threshold value': 'Valor limite',
  'Last value': 'Último valor',
  'Sending data': 'Enviando dados',
  'Threshold trigger': 'Gatilho de limite',
  'Last sending': 'Último envio',
  'Last checked': 'Última verificação',
  'Alert': 'Alerta',
  'Go to sensor dashboard': 'Ir para o painel do sensor',
  'Select a device type': 'Selecione um tipo de dispositivo',
  'Please enter an email': 'Por favor, digite um email',
  'View factory suppliers': 'Ver fornecedores de fábrica',
  'No suppliers found': 'Nenhum fornecedor encontrado',
  'Add Supplier': 'Adicionar fornecedor',
  'Admin Users': 'Usuários administradores',
  'See Users': 'Ver usuários',
  'Supplier created': 'Fornecedor criado',
  'User created': 'Usuário criado',
  'User deleted': 'Usuário excluído',
  'Trial Notebook': 'Caderno de ensaios',
  'notes': 'notas',
  'Add Note': 'Adicionar nota',
  'Adding...': 'Adicionando...',
  'Add a new observation or note...': 'Adicionar uma nova observação ou nota...',
  'No notes have been added yet. Add your first observation above.': 'Nenhuma nota foi adicionada ainda. Adicione sua primeira observação acima.',
  'Delete note': 'Excluir nota',
  'Error loading notes': 'Erro ao carregar notas',
  'Error adding note': 'Erro ao adicionar nota',
  'Error deleting note': 'Erro ao excluir nota',
  'Advanced View': 'Visualização avançada',
  'Basic View': 'Visualização básica',
  'Edit Trial Info': 'Editar informações do ensaio',
  'Generate PDF Report': 'Gerar relatório PDF',
  'days': 'dias',
  'No crops data available': 'Não há dados de cultivos disponíveis',
  'Crop details': 'Detalhes do cultivo',
  'In Progress': 'Em andamento',
  'Completed': 'Concluído',
  'Cancelled': 'Cancelado',
  'Started': 'Iniciado',
  'Duration': 'Duração',
  'Seed Quantity': 'Quantidade de sementes',
  'Sow Date': 'Data de semeadura',
  'Transplant Date': 'Data de transplante',
  'Date & Time': 'Data e hora',
  'Additional Information': 'Informações adicionais',
  'Environmental': 'Ambiental',
  'Metrics': 'Métricas',
  'Live Data': 'Dados em tempo real',
  'Historical': 'Histórico',
  'Show Sections': 'Mostrar seções',
  'Show Delta': 'Mostrar delta',
  'Section A': 'Seção A',
  'Section B': 'Seção B',
  'Delta': 'Delta',
  'Clear Filter': 'Limpar filtro',
  'Sensor Filter': 'Filtro de sensor',
  'Select sensors...': 'Selecionar sensores...',
  'Business Insights': 'Insights de negócios',
  'Experimental Feature': 'Recurso experimental',
  'Water': 'Água',
  'Cumulative values since the start of the trial for the entire site ': 'Valores acumulados desde o início do ensaio para todo o site',
  'Total Cost Savings': 'Economia total de custos',
  'Iyris': 'Iyris',
  'Control': 'Controle',
  'Savings (B-A': 'Economia (B-A',
  'Enviromental Metrics': 'Métricas Ambientais',
  'Soil Metrics': 'Métricas do Solo',
  'No trial data available': 'Nenhum dado de ensaio disponível',
  'Error loading trial': 'Erro ao carregar ensaio',
  'error?.message || Unknown error': '',
  'crop.status': '',
  'crop.hydrationMethod': '',
  'User profile': 'Perfil do usuário',
  'Support request successfully sent.': 'Solicitação de suporte enviada com sucesso.',
  'Email could not be sent.': 'Não foi possível enviar o email.',
  '\'Cant find what youre looking for? Visit the\'': '',
  'Help Centre': 'Centro de Ajuda',
  'or': 'ou',
  'Send us a Message': 'Envie-nos uma mensagem',
  'Recommended Articles': 'Artigos recomendados',
  'See': 'Ver',
  'more articles': 'mais artigos',
  'Running into problems?': 'Está tendo problemas?',
  'Visit our': 'Visite nosso',
  'helpdesk': 'central de ajuda',
  'or fill out the form to contact support.': 'ou preencha o formulário para contatar o suporte.',
  'Enter your full name here': 'Digite seu nome completo aqui',
  'Enter your email address here': 'Digite seu endereço de email aqui',
  'Support Details': 'Detalhes do suporte',
  'Support Type': 'Tipo de suporte',
  'Select support type': 'Selecione o tipo de suporte',
  'Title/Subject': 'Título/Assunto',
  'Provide a title or subject': 'Forneça um título ou assunto',
  'Describe your problem': 'Descreva seu problema',
  'Please describe your problem or difficulty here': 'Por favor, descreva seu problema ou dificuldade aqui',
  'Submit Request': 'Enviar solicitação',
  'Please provide a name': 'Por favor, forneça um nome',
  'Name cannot be empty': 'O nome não pode estar vazio',
  'Name cannot consist of only spaces': 'O nome não pode consistir apenas de espaços',
  'Please provide a valid email address': 'Por favor, forneça um endereço de email válido',
  'Please provide a subject': 'Por favor, forneça um assunto',
  'Description must be no more than 255 characters': 'A descrição não deve ter mais de 255 caracteres',
  'Please provide a description': 'Por favor, forneça uma descrição',
  'Select location': 'Selecionar localização',
  'Select option': 'Selecionar opção',
  'Models': 'Modelos',
  'Recommendations': 'Recomendações',
  'Threshold Messages': 'Mensagens de Limite',
  'Back to menu': 'Voltar ao menu',
  'Update Location': 'Atualizar localização',
  'About': 'Sobre',
  'Trial Mode': 'Modo de Ensaio',
  'Dashboard': 'Painel',
  'Map': 'Mapa',
  'Video Feeds': 'Feeds de vídeo',
  'Blueprint': 'Blueprint',
  'Packaging Units': 'Unidades de embalagem',
  'Branches': 'Filiais',
  'Unit Prices': 'Preços unitários',
  'Enroll a device': 'Registrar um dispositivo',
  'User management': 'Gerenciamento de usuários',
  'User roles': 'Papéis de usuário',
  'User options': 'Opções de usuário',
  'System thresholds': 'Limites do sistema',
  'Notifications': 'Notificações',
  'Sign in': 'Entrar',
  'Sign out': 'Sair',
  'is': 'está',
  'status': 'status',
  'Set Target Temperature': 'Definir temperatura alvo',
  'Calibrate dissolved oxygen': 'Calibrar oxigênio dissolvido',
  'Calibrate electrical conductivity': 'Calibrar condutividade elétrica',
  'Calibrate Ph': 'Calibrar Ph',
  'Calibrate PAR': 'Calibrar PAR',
  'Calibrate Humidity': 'Calibrar umidade',
  'Calibrate Temperature': 'Calibrar temperatura',
  'No Signal': 'Sem sinal',
  'Start stream': 'Iniciar transmissão',
  'Stop stream': 'Parar transmissão',
  'Or': 'Ou',
  'Watch Archive footage': 'Assistir filmagem de arquivo',
  'Hide Archive footage': 'Ocultar filmagem de arquivo',
  'View Video Feed': 'Ver feed de vídeo',
  'Available Video Feeds': 'Feeds de vídeo disponíveis',
  'No available feeds': 'Sem feeds disponíveis',
  'Search footage': 'Pesquisar filmagem',
  'Heatmap': 'Mapa de calor',
  'Select a {siteType} below to view its dashboard': 'Selecione um {siteType} abaixo para visualizar seu painel',
  'Filter': 'Filtro',
  'Could not create user. Please try again.': 'Não foi possível criar o usuário. Por favor, tente novamente.',
  'Are you sure you want to delete this item? This action cannot be undone.': 'Tem certeza que deseja excluir este item? Esta ação não pode ser desfeita.',
  'Toggle': 'Alternar',
  'dark mode': 'modo escuro',
  'light mode': 'modo claro',
  'system mode': 'modo do sistema',
  'The document must be a pdf file.': 'O documento deve ser um arquivo pdf.',
  'Drop the file here': 'Solte o arquivo aqui',
  '\'Click to chose a file or dragndrop it here\'': '\'Clique para escolher um arquivo ou arraste-o para aqui\'',
  'go to': 'ir para',
  'next': 'próximo',
  'last': 'último',
  'previous': 'anterior',
  'first': 'primeiro',
  'Pagination Navigation': 'Navegação de paginação',
  'of': 'de',
  'items': 'itens',
  'pages': 'páginas',
  'page': 'página',
  'You do not have permission to view this page.': 'Você não tem permissão para visualizar esta página.',
  'Start creation of default deployment successfully.': 'Início da criação da implantação padrão bem-sucedido.',
  'Started core device updates successfully': 'Atualizações do dispositivo central iniciadas com sucesso',
  'Error fetching core device provision installer': 'Erro ao buscar o instalador de provisionamento do dispositivo central',
  'Area created successfully.': 'Área criada com sucesso.',
  'Area updated successfully.': 'Área atualizada com sucesso.',
  'Plan created successfully.': 'Plano criado com sucesso.',
  'Plan updated successfully.': 'Plano atualizado com sucesso.',
  'Harvest created successfully.': 'Colheita criada com sucesso.',
  'Harvest updated successfully.': 'Colheita atualizada com sucesso.',
  'Harvest deleted successfully.': 'Colheita excluída com sucesso.',
  'Sale created successfully.': 'Venda criada com sucesso.',
  'Sale updated successfully.': 'Venda atualizada com sucesso.',
  'Sale item created successfully.': 'Item de venda criado com sucesso.',
  'Sale item updated successfully.': 'Item de venda atualizado com sucesso.',
  'Unable to remove item referenced by existing return.': 'Não é possível remover o item referenciado por devolução existente.',
  'Sale Shipment created successfully.': 'Envio de venda criado com sucesso.',
  'Sale Shipment successfully.': 'Envio de venda com sucesso.',
  'Unable to remove shipment referenced by existing return.': 'Não é possível remover o envio referenciado por devolução existente.',
  'Waste created successfully.': 'Descarte criado com sucesso.',
  'Waste updated successfully.': 'Descarte atualizado com sucesso.',
  'Return created successfully.': 'Devolução criada com sucesso.',
  'Return updated successfully.': 'Devolução atualizada com sucesso.',
  'Customer created successfully.': 'Cliente criado com sucesso.',
  'Customer updated successfully.': 'Cliente atualizado com sucesso.',
  'Branch created successfully.': 'Filial criada com sucesso.',
  'Branch updated successfully.': 'Filial atualizada com sucesso.',
  'Unit price created successfully.': 'Preço unitário criado com sucesso.',
  'Unit price updated successfully.': 'Preço unitário atualizado com sucesso.',
  'Unit price deleted successfully.': 'Preço unitário excluído com sucesso.',
  'Unable to create duplicate unit price.': 'Não é possível criar um preço unitário duplicado.',
  'Unable to save duplicate unit price.': 'Não é possível salvar um preço unitário duplicado.',
  'City created successfully.': 'Cidade criada com sucesso.',
  'City updated successfully.': 'Cidade atualizada com sucesso.',
  'Country created successfully.': 'País criado com sucesso.',
  'Country updated successfully.': 'País atualizado com sucesso.',
  'Currency created successfully.': 'Moeda criada com sucesso.',
  'Currency updated successfully.': 'Moeda atualizada com sucesso.',
  'Grade created successfully.': 'Classificação criada com sucesso.',
  'Grade updated successfully.': 'Classificação atualizada com sucesso.',
  'Package unit created successfully.': 'Unidade de embalagem criada com sucesso.',
  'Package unit updated successfully.': 'Unidade de embalagem atualizada com sucesso.',
  'Payment method created successfully.': 'Método de pagamento criado com sucesso.',
  'Payment method updated successfully.': 'Método de pagamento atualizado com sucesso.',
  'Payment terms created successfully.': 'Termos de pagamento criados com sucesso.',
  'Payment terms updated successfully.': 'Termos de pagamento atualizados com sucesso.',
  'Produce created successfully.': 'Produto criado com sucesso.',
  'Produce updated successfully.': 'Produto atualizado com sucesso.',
  'Region created successfully.': 'Região criada com sucesso.',
  'Region updated successfully.': 'Região atualizada com sucesso.',
  'Shipping method created successfully.': 'Método de envio criado com sucesso.',
  'Shipping method updated successfully.': 'Método de envio atualizado com sucesso.',
  'Storage facility created successfully.': 'Instalação de armazenamento criada com sucesso.',
  'Storage facility updated successfully.': 'Instalação de armazenamento atualizada com sucesso.',
  'Variety created successfully.': 'Variedade criada com sucesso.',
  'Variety updated successfully.': 'Variedade atualizada com sucesso.',
  'Inventory calibration created successfully.': 'Calibração de inventário criada com sucesso.',
  'Inventory transfer created successfully.': 'Transferência de inventário criada com sucesso.',
  'Inventory transfer updated successfully.': 'Transferência de inventário atualizada com sucesso.',
  'Package unit purchase created successfully.': 'Compra de unidade de embalagem criada com sucesso.',
  'Package unit purchase updated successfully.': 'Compra de unidade de embalagem atualizada com sucesso.',
  'Packaging inventory calibration created successfully.': 'Calibração de inventário de embalagens criada com sucesso.',
  'Packaging inventory transfer created successfully.': 'Transferência de inventário de embalagens criada com sucesso.',
  'Packaging inventory transfer updated successfully.': 'Transferência de inventário de embalagens atualizada com sucesso.',
  'Product created successfully.': 'Produto criado com sucesso.',
  'Product updated successfully.': 'Produto atualizado com sucesso.',
  'Crop created successfully.': 'Cultivo criado com sucesso.',
  'Crop updated successfully.': 'Cultivo atualizado com sucesso.',
  'Supplier created successfully.': 'Fornecedor criado com sucesso.',
  'Supplier updated successfully.': 'Fornecedor atualizado com sucesso.',
  'Fertilizer purchase created successfully.': 'Compra de fertilizante criada com sucesso.',
  'Fertilizer purchase updated successfully.': 'Compra de fertilizante atualizada com sucesso.',
  'Fertilizer inventory calibration created successfully.': 'Calibração de inventário de fertilizantes criada com sucesso.',
  'Fertilizer inventory transfer created successfully.': 'Transferência de inventário de fertilizantes criada com sucesso.',
  'Fertilizer inventory transfer updated successfully.': 'Transferência de inventário de fertilizantes atualizada com sucesso.',
  'Harvest data not fetched': 'Dados da colheita não buscados',
  'Crops data not fetched': 'Dados dos cultivos não buscados',
  'Error fetching control device config': 'Erro ao buscar a configuração do dispositivo de controle',
  'Control device config saved successfully': 'Configuração do dispositivo de controle salva com sucesso',
  'Control device config updated successfully': 'Configuração do dispositivo de controle atualizada com sucesso',
  'Control device config deleted successfully': 'Configuração do dispositivo de controle excluída com sucesso',
  'Control device spec saved successfully': 'Especificação do dispositivo de controle salva com sucesso',
  'Control device spec updated successfully': 'Especificação do dispositivo de controle atualizada com sucesso',
  'Diagnostics request sent succesfully. You will receive an email with the results.': 'Solicitação de diagnóstico enviada com sucesso. Você receberá um e-mail com os resultados.',
  'Sensor restart has been scheduled for the next wake up cycle.': 'O reinício do sensor foi agendado para o próximo ciclo de ativação.',
  'SIM status updated successfully.': 'Status do SIM atualizado com sucesso.',
  'Could not update the SIM status. Our team has been notified and will investigate.': 'Não foi possível atualizar o status do SIM. Nossa equipe foi notificada e irá investigar.',
  'Some of the SIM pings failed. Our team has been notified and will investigate.': 'Alguns pings do SIM falharam. Nossa equipe foi notificada e irá investigar.',
  'Could not fetch help text entries': 'Não foi possível buscar entradas de texto de ajuda',
  'Translations requested': 'Traduções solicitadas',
  'Could not generate translations': 'Não foi possível gerar traduções',
  'Help text entry created': 'Entrada de texto de ajuda criada',
  'Could not create help text entry': 'Não foi possível criar entrada de texto de ajuda',
  'Help text updated': 'Texto de ajuda atualizado',
  'Could not update help text': 'Não foi possível atualizar texto de ajuda',
  'Text could not be generated from prompt': 'Não foi possível gerar texto a partir do prompt',
  'Release note created': 'Nota de lançamento criada',
  'Could not create release note': 'Não foi possível criar nota de lançamento',
  'Release note updated': 'Nota de lançamento atualizada',
  'Could not update release note': 'Não foi possível atualizar nota de lançamento',
  'Release note section created': 'Seção de nota de lançamento criada',
  'Could not create release note section': 'Não foi possível criar seção de nota de lançamento',
  'Release note section updated': 'Seção de nota de lançamento atualizada',
  'Could not update release note section': 'Não foi possível atualizar seção de nota de lançamento',
  'Could not fetch all release notes data': 'Não foi possível buscar todos os dados das notas de lançamento',
  'Could not fetch release notes': 'Não foi possível buscar notas de lançamento',
  'Translations are being generated': 'As traduções estão sendo geradas',
  'Translations could not be generated': 'Não foi possível gerar traduções',
  'Could not fetch tags': 'Não foi possível buscar etiquetas',
  'Tag created': 'Etiqueta criada',
  'Could not create tag': 'Não foi possível criar etiqueta',
  'Tag updated': 'Etiqueta atualizada',
  'Could not update tag': 'Não foi possível atualizar etiqueta',
  'Regeneration of device firmware started successfully.': 'Regeneração do firmware do dispositivo iniciada com sucesso.',
  'Organization label created successfully.': 'Etiqueta da organização criada com sucesso.',
  'Error creating the label. Please try again later.': 'Erro ao criar a etiqueta. Por favor, tente novamente mais tarde.',
  'Organization label updated successfully.': 'Etiqueta da organização atualizada com sucesso.',
  'Error updating the label. Please try again later.': 'Erro ao atualizar a etiqueta. Por favor, tente novamente mais tarde.',
  'Organization label translations updated successfully.': 'Traduções da etiqueta da organização atualizadas com sucesso.',
  'Error updating the label translations. Please try again later.': 'Erro ao atualizar as traduções da etiqueta. Por favor, tente novamente mais tarde.',
  'Error fetching trials': 'Erro ao buscar ensaios',
  'Error fetching trial': 'Erro ao buscar ensaio',
  'created successfully': 'criado com sucesso',
  'Error creating': 'Erro ao criar',
  'sucessfully updated!': 'atualizado com sucesso!',
  'Error updating': 'Erro ao atualizar',
  'Error updating zone maintenance mode': 'Erro ao atualizar o modo de manutenção da zona',
  'Error updating zone trial mode view': 'Erro ao atualizar a visualização do modo de ensaio da zona',
  'Device statuses fetched': 'Status dos dispositivos buscados',
  'Snooze not fetched': 'Adiamento não buscado',
  'Snoozes not fetched': 'Adiamentos não buscados',
  'Snooze not created': 'Adiamento não criado',
  'Snooze not deleted': 'Adiamento não excluído',
  'Username/email is required': 'Nome de usuário/email é obrigatório',
  'Password is required': 'Senha é obrigatória',
  'Code is required': 'Código é obrigatório',
  'Confirm password is required': 'Confirmação de senha é obrigatória',
  'Organization name is required': 'Nome da organização é obrigatório',
  'Email format is incorrect': 'Formato de email está incorreto',
  'Email is required': 'Email é obrigatório',
  'First name is required': 'Nome é obrigatório',
  'Last name is required': 'Sobrenome é obrigatório',
  'Username is required': 'Nome de usuário é obrigatório'
}}

export default words