import React from 'react'
import Card from '@/primitives/Card'
import FlexV2 from '@/primitives/FlexV2'
import Box from '@/primitives/Box'
import Slot from '@/primitives/Slot'
import Text from '@/primitives/Text'
import StatusBadge from '../StatusBadge'
import { calculateDuration } from './utils'
import Strings from '../../Strings'
import { formatDate } from '../../utils'
import './index.scss'

const TrialModeViewHeader = ({
  title,
  startDate = new Date(),
  status,
  isMobile,
  isTablet
}) => {
  const strings = Strings()

  const MetricItem = ({ label, children }) => (
    <Box className='trial-card__metric'>
      <FlexV2 direction='column' alignCrossAxis='center' axisGap={200}>
        <Text variant='neutral' tone={600}>
          {label}
        </Text>
        {children}
      </FlexV2>
    </Box>
  )
  const duration = calculateDuration(startDate)

  const titleSize = isMobile ? 200 : isTablet ? 300 : 400

  return (
    <Card>
      <Slot name='header'>
        <Box className='trial-card__header'>
          <FlexV2
            alignMainAxis='space-between'
            alignCrossAxis='center'
            axisGap={400}
          >
            <FlexV2 alignCrossAxis='center' axisGap={300}>
              <Text size={400} fontWeight={700}>
                {title}
              </Text>
            </FlexV2>
          </FlexV2>
        </Box>
      </Slot>
      <Slot name='body'>
        <Box className='trial-card__body'>
          <FlexV2 alignMainAxis='space-around' axisGap={400}>
            <MetricItem label={strings.started}>
              <Text size={titleSize} fontWeight={600}>
                {formatDate(startDate)}
              </Text>
            </MetricItem>

            <MetricItem label={strings.duration}>
              <FlexV2 alignCrossAxis='center' axisGap={200}>
                <Text size={titleSize} fontWeight={600}>
                  {duration}
                </Text>
                <Text variant='neutral' tone={600}>
                  {strings.days}
                </Text>
              </FlexV2>
            </MetricItem>

            <MetricItem label={strings.status}>
              <StatusBadge status={status} />
            </MetricItem>
          </FlexV2>
        </Box>
      </Slot>
    </Card>
  )
}

export default TrialModeViewHeader
