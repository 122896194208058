import React, { memo } from 'react'
import Card from '@/primitives/Card'
import Slot from '@/primitives/Slot'
import FlexV2 from '@/primitives/FlexV2'
import Box from '@/primitives/Box'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import InsightMetricValue from './InsightMetricValue'
import Strings from '@/components/TrialModeView/Strings'
import Button from '@/primitives/Button'

const InsightPanel = memo(
  ({
    title,
    icon,
    data,
    isSmallScreen,
    sectionAName = Strings().iyris,
    sectionBName = Strings().control
  }) => {
    const strings = Strings()
    const {
      sectionA = { value: 0, price: 0 },
      sectionB = { value: 0, price: 0 },
      variation = 0,
      variationPercentage = 0,
      isResourceSaving,
      price,
      unit,
      priceDifference = 0
    } = data || {}

    const isNegativeVariation = variationPercentage < 0
    const badgeClassName = `percentage-badge ${
      isNegativeVariation && isResourceSaving ? 'success' : 'danger'
    }`
    const arrowIcon =
      variationPercentage > 0 ? 'arrow_upward' : 'arrow_downward'
    const textVariant = variation > 0 ? 'success' : 'danger'

    const renderSavingsBox = () => (
      <Box>
        <Text size={100} variant='page' tone={500}>
          {strings.savingsLabel}
        </Text>
        <FlexV2 alignCrossAxis='center' axisGap={100}>
          <Text size={400} fontWeight={700} variant={textVariant} tone={700}>
            {variation} {unit}
          </Text>
        </FlexV2>
        <Text size={100} variant='page' tone={500}>
          {priceDifference}
        </Text>
      </Box>
    )

    const renderMetricBox = (label, data, width) => (
      <Box style={{ width }}>
        <InsightMetricValue
          label={label}
          value={data.value}
          unit={unit}
          price={data.price}
        />
      </Box>
    )

    return (
      <Card>
        <Slot name='body'>
          <FlexV2
            alignCrossAxis='center'
            alignMainAxis='space-between'
            axisGap={100}
          >
            <FlexV2
              alignCrossAxis='center'
              alignMainAxis='space-between'
              axisGap={500}
            >
              <FlexV2 alignCrossAxis='center' axisGap={100}>
                <Icon name={icon} size={200} variant='neutral' tone={600} />
                <Text size={200} fontWeight={600} variant='neutral' tone={600}>
                  {title}
                </Text>
              </FlexV2>
              <Box className={badgeClassName}>
                <Icon
                  name={arrowIcon}
                  size={100}
                  variant='neutral'
                  tone={100}
                />
                <Text size={100} fontWeight={600} variant='neutral' tone={100}>
                  {Math.abs(variationPercentage)}%
                </Text>
              </Box>
            </FlexV2>
            <Button size='small' variant='outline' iconBefore='edit'>
              {price}/{unit}
            </Button>
          </FlexV2>

          {isSmallScreen ? (
            <FlexV2 direction='column' axisGap={500}>
              <FlexV2 alignMainAxis='space-between' axisGap={200}>
                {renderMetricBox(sectionAName, sectionA, '50%')}
                {renderMetricBox(sectionBName, sectionB, '50%')}
              </FlexV2>
              {renderSavingsBox()}
            </FlexV2>
          ) : (
            <FlexV2 alignMainAxis='space-between' direction='row' axisGap={500}>
              {renderMetricBox(sectionAName, sectionA, '33%')}
              {renderMetricBox(sectionBName, sectionB, '33%')}
              <Box style={{ width: '33%' }}>{renderSavingsBox()}</Box>
            </FlexV2>
          )}
        </Slot>
      </Card>
    )
  }
)
export default InsightPanel
