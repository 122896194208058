import React from 'react'
import FlexV2 from '@/primitives/FlexV2'
import Button from '@/primitives/Button'
import { Tooltip } from 'react-tooltip'
import Strings from '../../Strings'

const DeviceFilterButton = ({
  hasActiveFilters,
  onClick,
  onClearFilters,
  sensorType
}) => {
  const strings = Strings()
  return (
    <FlexV2 alignCrossAxis='center' axisGap={100}>
      <Button
        variant={hasActiveFilters ? 'neutral' : ''}
        iconBefore='filter_list'
        onClick={onClick}
      >
        {strings[sensorType]} {strings.devices}
      </Button>
      {hasActiveFilters && (
        <>
          <Button
            variant='neutral'
            iconBefore='close'
            size='small'
            onClick={onClearFilters}
            data-tooltip-id='clear-filter-tooltip'
            aria-label={strings.clearFilter}
          />
          <Tooltip
            id='clear-filter-tooltip'
            content={strings.clearFilter}
            place='top'
          />
        </>
      )}
    </FlexV2>
  )
}

export default DeviceFilterButton
