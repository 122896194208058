import React from 'react'
import Card from '@/primitives/Card'
import Slot from '@/primitives/Slot'
import FlexV2 from '@/primitives/FlexV2'
import Box from '@/primitives/Box'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import Collapse from '@/primitives/Collapse'
import InsightPanel from './InsightPanel'
import './index.scss'
import { useBusinessInsights } from './hooks/useBusinessInsights'
import Strings from '@/components/TrialModeView/Strings'
import Tooltip from '@/elements/Tooltip'

const BusinessInsights = ({ isSmallScreen }) => {
  const strings = Strings()

  const ExperimentalBadge = () => (
    <Box className='experimental-badge'>
      <Icon name='science' size={200} variant='warning' />
      <Text size={200}>{strings.experimentalFeature}</Text>
    </Box>
  )

  const { energyData, waterData, fertigationData, yieldData, totalSavings } =
    useBusinessInsights()
  const titleSize = isSmallScreen ? 300 : 400

  const getDefaultData = () => ({
    sectionA: { value: 0, price: 0 },
    sectionB: { value: 0, price: 0 },
    variation: 0,
    variationPercentage: 0,
    priceDifference: 0,
    price: '$0',
    unit: ''
  })

  const insightData = {
    energy: energyData || getDefaultData(),
    water: waterData || getDefaultData(),
    fertigation: fertigationData || getDefaultData(),
    yield: yieldData || getDefaultData()
  }

  const insightConfig = [
    { key: 'energy', title: strings.energy, icon: 'bolt' },
    { key: 'water', title: strings.water, icon: 'water_drop' },
    { key: 'fertigation', title: strings.fertigation, icon: 'spa' },
    { key: 'yield', title: strings.yield, icon: 'scale' }
  ]

  const renderHeader = () => (
    <>
      <FlexV2 alignCrossAxis='center' alignMainAxis='space-between'>
        <FlexV2 alignCrossAxis='center' axisGap={200}>
          <Icon
            name='trending_up'
            size={titleSize}
            variant='primary'
            tone={600}
          />
          <Text size={titleSize} fontWeight={600}>
            {strings.businessInsights}
          </Text>
          <Tooltip tip={strings.bussisnessInsightInfo}>
            <Icon name='info' size={titleSize} variant='neutral' tone={600} />
          </Tooltip>
        </FlexV2>
      </FlexV2>
      <ExperimentalBadge />
    </>
  )

  const renderInsightPanels = () => (
    <FlexV2 direction='column' axisGap={300}>
      {insightConfig.map(({ key, title, icon }) => (
        <InsightPanel
          key={key}
          title={title}
          icon={icon}
          data={insightData[key]}
          unit={insightData[key].unit}
          isSmallScreen={isSmallScreen}
        />
      ))}

      <FlexV2
        alignCrossAxis='center'
        className='total-savings-container'
        alignMainAxis='space-between'
        direction={isSmallScreen ? 'column' : 'row'}
      >
        <FlexV2 alignCrossAxis='center' axisGap={200}>
          <Icon name='savings' size={400} />
          <Text size={400} fontWeight={600}>
            {strings.totalCostSavings}
          </Text>
        </FlexV2>
        <Text size={500} fontWeight={700} variant='success' tone={700}>
          {totalSavings.formatted}
        </Text>
      </FlexV2>
    </FlexV2>
  )

  if (isSmallScreen) {
    return (
      <Card>
        <Slot name='body'>
          <Collapse defaultOpen={false}>
            <Slot name='trigger'>{renderHeader()}</Slot>
            <Slot name='content'>{renderInsightPanels()}</Slot>
          </Collapse>
        </Slot>
      </Card>
    )
  }

  return (
    <Card>
      <Slot name='header'>{renderHeader()}</Slot>
      <Slot name='body'>{renderInsightPanels()}</Slot>
    </Card>
  )
}

export default BusinessInsights
